import {
  OTHER_COST_LIST_REQUEST,
  OTHER_COST_LIST_SUCCESS,
  OTHER_COST_LIST_FAIL,
  OTHER_COST_DETAILS_REQUEST,
  OTHER_COST_DETAILS_SUCCESS,
  OTHER_COST_DETAILS_FAIL,
  OTHER_COST_REGISTER_REQUEST,
  OTHER_COST_REGISTER_SUCCESS,
  OTHER_COST_REGISTER_FAIL,
  OTHER_COST_DELETE_REQUEST,
  OTHER_COST_DELETE_SUCCESS,
  OTHER_COST_DELETE_FAIL,
  OTHER_COST_UPDATE_REQUEST,
  OTHER_COST_UPDATE_SUCCESS,
  OTHER_COST_UPDATE_FAIL,
} from "../constants/otherCostConstants";
import * as api from "../api/index";

export const listOtherCost = (project) => async (dispatch) => {
  try {
    dispatch({ type: OTHER_COST_LIST_REQUEST });

    const { data } = await api.listOtherCostByProjectAPI(project);

    dispatch({
      type: OTHER_COST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_COST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOtherCostById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: OTHER_COST_DETAILS_REQUEST });
    const { data } = await api.OtherCostByIdAPI(id);
    dispatch({
      type: OTHER_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_COST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const otherCostRegister = (othercostscategory) => async (dispatch) => {
  try {
    dispatch({
      type: OTHER_COST_REGISTER_REQUEST,
    });
    const { data } = await api.OtherCostRegisterAPI(othercostscategory);
    dispatch({
      type: OTHER_COST_REGISTER_SUCCESS,
      payload: data,
    });
    console.log(data);
    dispatch({
      type: OTHER_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_COST_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteOtherCost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: OTHER_COST_DELETE_REQUEST,
    });

    await api.OtherCostDeleteAPI(id);

    dispatch({
      type: OTHER_COST_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: OTHER_COST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateOtherCost = (project) => async (dispatch) => {
  try {
    dispatch({
      type: OTHER_COST_UPDATE_REQUEST,
    });

    const { data } = await api.OtherCostUpdateAPI(project);

    dispatch({
      type: OTHER_COST_UPDATE_SUCCESS,
    });

    dispatch({
      type: OTHER_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_COST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchOtherCost = (projectName) => async (dispatch) => {
  try {
    dispatch({ type: OTHER_COST_LIST_REQUEST });

    const { data } = await api.OtherCostSearchAPI(projectName);

    dispatch({
      type: OTHER_COST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_COST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
