import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";
import { jpDevelopmentRegister } from "../../redux/actions/jpDevelopmentActons";
import { SetRoute } from "../../redux/actions/routeActions";

function JpDevelopmentAddScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [jpDevelopmentName, setJpDevelopmentName] = useState("");
  const [message, setMessage] = useState(null);

  const Register = useSelector((state) => state.jpDevelopmentRegister);
  const { loading: regLoading, error: regError } = Register;

  const submitHandler = (e) => {
    e.preventDefault();
    if (jpDevelopmentName === "") {
      setMessage("JpDevelopmentName Is Empty");
    } else {
      dispatch(jpDevelopmentRegister(jpDevelopmentName, navigate));
    }
  };
  useEffect(() => {
    dispatch(SetRoute("New Jp Development"));
  }, [dispatch]);
  return (
    <>
      <Container maxWidth="sm">
        {message && (
          <Message variant="outlined" severity="error">
            {message}
          </Message>
        )}
        {regError && <Message variant="danger">{regError}</Message>}

        <form
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 5 },
          }}
          onSubmit={submitHandler}
        >
          <FormGroup>
            <TextField
              margin="normal"
              size="small"
              label="JpDevelopment Name"
              name="JpDevelopment name"
              value={jpDevelopmentName}
              onChange={(e) => setJpDevelopmentName(e.target.value)}
            />

            {regLoading && <Loader />}
            <Button type="submit" size="small" variant="outlined">
              Submit
            </Button>
          </FormGroup>
        </form>
      </Container>
    </>
  );
}

export default JpDevelopmentAddScreen;
