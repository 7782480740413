export const SL_OUTSOURCE_COMPANY_LIST_REQUEST = "SL_OUTSOURCE_COMPANY_LIST_REQUEST";
export const SL_OUTSOURCE_COMPANY_LIST_SUCCESS = "SL_OUTSOURCE_COMPANY_LIST_SUCCESS";
export const SL_OUTSOURCE_COMPANY_LIST_FAIL = "SL_OUTSOURCE_COMPANY_LIST_FAIL";

export const SL_OUTSOURCE_COMPANY_DETAILS_REQUEST = "SL_OUTSOURCE_COMPANY_DETAILS_REQUEST";
export const SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS = "SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS";
export const SL_OUTSOURCE_COMPANY_DETAILS_FAIL = "SL_OUTSOURCE_COMPANY_DETAILS_FAIL";

export const SL_OUTSOURCE_COMPANY_REGISTER_REQUEST = "SL_OUTSOURCE_COMPANY_REGISTER_REQUEST";
export const SL_OUTSOURCE_COMPANY_REGISTER_SUCCESS = "SL_OUTSOURCE_COMPANY_REGISTER_SUCCESS";
export const SL_OUTSOURCE_COMPANY_REGISTER_FAIL = "SL_OUTSOURCE_COMPANY_REGISTER_FAIL";

export const SL_OUTSOURCE_COMPANY_DELETE_REQUEST = "SL_OUTSOURCE_COMPANY_DELETE_REQUEST";
export const SL_OUTSOURCE_COMPANY_DELETE_SUCCESS = "SL_OUTSOURCE_COMPANY_DELETE_SUCCESS";
export const SL_OUTSOURCE_COMPANY_DELETE_FAIL = "SL_OUTSOURCE_COMPANY_DELETE_FAIL";

export const SL_OUTSOURCE_COMPANY_UPDATE_REQUEST = "SL_OUTSOURCE_COMPANY_UPDATE_REQUEST";
export const SL_OUTSOURCE_COMPANY_UPDATE_SUCCESS = "SL_OUTSOURCE_COMPANY_UPDATE_SUCCESS";
export const SL_OUTSOURCE_COMPANY_UPDATE_FAIL = "SL_OUTSOURCE_COMPANY_UPDATE_FAIL";
export const SL_OUTSOURCE_COMPANY_UPDATE_RESET = "SL_OUTSOURCE_COMPANY_UPDATE_RESET";
