import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSlOutSourceCompanyById,
  updateSlOutSourceCompany,
} from "../../redux/actions/slOutSourceCompanyActions";
import { SL_OUTSOURCE_COMPANY_UPDATE_RESET } from "../../redux/constants/slOutSourceCompanyConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function CostCategoryEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slOutSourceCompanyId = useParams();

  const [id, setId] = useState("");
  const [slOutSourceCompanyName, setSlOutSourceCompanyName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.slOutSourceCompanyById);
  const { loading, error, slOutSourceCompanyById } = Details;

  const update = useSelector((state) => state.slOutSourceCompanyUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Sl OutSource Company Edit"));
    if (successUpdate) {
      dispatch({ type: SL_OUTSOURCE_COMPANY_UPDATE_RESET });
    } else {
      if (
        !slOutSourceCompanyById.SlOutsourceCompanyName ||
        slOutSourceCompanyById._id !== slOutSourceCompanyId.id
      ) {
        dispatch(getSlOutSourceCompanyById(slOutSourceCompanyId.id));
      } else {
        setId(slOutSourceCompanyById._id);
        setSlOutSourceCompanyName(
          slOutSourceCompanyById.SlOutsourceCompanyName
        );
      }
    }
  }, [dispatch, successUpdate, slOutSourceCompanyById, slOutSourceCompanyId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (slOutSourceCompanyName === "") {
      setMessage("Cost Category Name Is Empty");
    } else {
      dispatch(
        updateSlOutSourceCompany(
          {
            _id: id,
            name: slOutSourceCompanyName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="CostCategoryName"
                name=" Sl OutSource Company Name"
                value={slOutSourceCompanyName}
                onChange={(e) => setSlOutSourceCompanyName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default CostCategoryEditScreen;
