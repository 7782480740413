import {
  LABOUR_COST_LIST_REQUEST,
  LABOUR_COST_LIST_SUCCESS,
  LABOUR_COST_LIST_FAIL,
  LABOUR_COST_DETAILS_REQUEST,
  LABOUR_COST_DETAILS_SUCCESS,
  LABOUR_COST_DETAILS_FAIL,
  LABOUR_COST_REGISTER_REQUEST,
  LABOUR_COST_REGISTER_SUCCESS,
  LABOUR_COST_REGISTER_FAIL,
  LABOUR_COST_DELETE_REQUEST,
  LABOUR_COST_DELETE_SUCCESS,
  LABOUR_COST_DELETE_FAIL,
  LABOUR_COST_UPDATE_REQUEST,
  LABOUR_COST_UPDATE_SUCCESS,
  LABOUR_COST_UPDATE_FAIL,
} from "../constants/labourCostConstants";
import * as api from "../api/index";

export const listLabourCosts = (project) => async (dispatch) => {
  try {
    dispatch({ type: LABOUR_COST_LIST_REQUEST });

    const { data } = await api.listLabourCostByProjectAPI(project);

    dispatch({
      type: LABOUR_COST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_COST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLabourCostById = (id) => async (dispatch) => {
  try {
    dispatch({ type: LABOUR_COST_DETAILS_REQUEST });

    const { data } = await api.LabourCostByIdAPI(id);
    dispatch({
      type: LABOUR_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_COST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const LabourCostRegister = (labourCategory) => async (dispatch) => {
  try {
    dispatch({
      type: LABOUR_COST_REGISTER_REQUEST,
    });

    const { data } = await api.LabourCostRegisterAPI(labourCategory);

    dispatch({
      type: LABOUR_COST_REGISTER_SUCCESS,
      payload: data,
    });
    console.log(data);
    dispatch({
      type: LABOUR_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_COST_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteLabourCost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LABOUR_COST_DELETE_REQUEST,
    });

    await api.LabourCostDeleteAPI(id);

    dispatch({
      type: LABOUR_COST_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_COST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateLabourCost = (Labour) => async (dispatch) => {
  try {
    dispatch({
      type: LABOUR_COST_UPDATE_REQUEST,
    });

    const { data } = await api.LabourCostUpdateAPI(Labour);

    dispatch({
      type: LABOUR_COST_UPDATE_SUCCESS,
    });

    dispatch({
      type: LABOUR_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_COST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchLabourCost = (LabourName) => async (dispatch) => {
  try {
    dispatch({ type: LABOUR_COST_LIST_REQUEST });

    const { data } = await api.LabourCostSearchAPI(LabourName);

    dispatch({
      type: LABOUR_COST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_COST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
