import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/userActions";
import { listMaster } from "../redux/actions/masterActions";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorMasterDD, setAnchorMasterDD] = useState(null);
  const openMasterDD = Boolean(anchorMasterDD);
  const handleClickMasterDD = (event) => {
    setAnchorMasterDD(event.currentTarget);
  };
  const handleCloseMasterDD = () => {
    setAnchorMasterDD(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const List = useSelector((state) => state.masterlist);
  const { masters } = List;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const r = useSelector((state) => state.route);
  const { routeInfo } = r;
  const logoutHandler = () => {
    dispatch(logout(navigate));
  };

  useEffect(() => {
    dispatch(listMaster());
    if (!userInfo) {
      navigate("/");
    } 
  }, [userInfo, navigate, dispatch]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          bgcolor: "white",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "black", display: { xs: "none", sm: "block" } }}
          >
            <Link
              to="/home"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <b>Cost Management</b>{" "}
              <i>{routeInfo ? " - " + routeInfo : " "}</i>
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <div>
            <Button
              id="fade-button"
              aria-controls={openMasterDD ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMasterDD ? "true" : undefined}
              onClick={handleClickMasterDD}
            >
              Masters
            </Button>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorMasterDD}
              open={openMasterDD}
              onClose={handleCloseMasterDD}
              TransitionComponent={Fade}
            >
              {userInfo.userLevel === "Director"
                ? masters.map((master) => (
                    <Link
                      key={master.MasterURL}
                      to={`${master.MasterURL}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <MenuItem
                        onClick={handleCloseMasterDD}
                        value={master.MasterName}
                      >
                        {master.MasterName}
                      </MenuItem>
                    </Link>
                  ))
                : userInfo.masters.map((master) => (
                    <Link
                      key={master.MasterURL}
                      to={`${master.MasterURL}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <MenuItem
                        onClick={handleCloseMasterDD}
                        value={master.MasterName}
                      >
                        {master.MasterName}
                      </MenuItem>
                    </Link>
                  ))}
            </Menu>
            <Link
              to={`/Project`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button id="fade-button">Add New Project</Button>
            </Link>
          </div>
          {userInfo ? (
            <Box sx={{ color: "black", display: { xs: "none", md: "flex" } }}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar sx={{ width: 32, height: 32 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >          
                  {userInfo.userLevel === "Director" && (
                    <MenuItem>
                      <Link
                        to={`/settings`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <ListItemIcon>
                          <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem onClick={logoutHandler}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
