import {
    SL_DEVELOPER_LIST_REQUEST,
    SL_DEVELOPER_LIST_SUCCESS,
    SL_DEVELOPER_LIST_FAIL,
    SL_DEVELOPER_DETAILS_REQUEST,
    SL_DEVELOPER_DETAILS_SUCCESS,
    SL_DEVELOPER_DETAILS_FAIL,
    SL_DEVELOPER_REGISTER_REQUEST,
    SL_DEVELOPER_REGISTER_SUCCESS,
    SL_DEVELOPER_REGISTER_FAIL,
    SL_DEVELOPER_DELETE_REQUEST,
    SL_DEVELOPER_DELETE_SUCCESS,
    SL_DEVELOPER_DELETE_FAIL,
    SL_DEVELOPER_UPDATE_REQUEST,
    SL_DEVELOPER_UPDATE_SUCCESS,
    SL_DEVELOPER_UPDATE_FAIL,
    SL_DEVELOPER_UPDATE_RESET,
  } from "../constants/slDeveloperConstants";
  
  export const slDeveloperListReducer = (state = { slDevelopers: [] }, action) => {
    switch (action.type) {
      case SL_DEVELOPER_LIST_REQUEST:
        return { loading: true, slDevelopers: [] };
      case SL_DEVELOPER_LIST_SUCCESS:
        return { loading: false, slDevelopers: action.payload };
      case SL_DEVELOPER_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slDeveloperByIdReducer = (state = { slDeveloper: [] }, action) => {
    switch (action.type) {
      case SL_DEVELOPER_DETAILS_REQUEST:
        return { loading: true, ...state };
      case SL_DEVELOPER_DETAILS_SUCCESS:
        return { loading: false, slDeveloper: action.payload };
      case SL_DEVELOPER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slDeveloperRegisterReducer = (state = { slDeveloper: [] }, action) => {
    switch (action.type) {
      case SL_DEVELOPER_REGISTER_REQUEST:
        return { loading: true, slDeveloper: [] };
      case SL_DEVELOPER_REGISTER_SUCCESS:
        return { loading: false, slDeveloperInfo: action.payload };
      case SL_DEVELOPER_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slDeveloperDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SL_DEVELOPER_DELETE_REQUEST:
        return { loading: true, slDeveloper: [] };
      case SL_DEVELOPER_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SL_DEVELOPER_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slDeveloperUpdateReducer = (state = { slDeveloper: {} }, action) => {
    switch (action.type) {
      case SL_DEVELOPER_UPDATE_REQUEST:
        return { loading: true };
      case SL_DEVELOPER_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case SL_DEVELOPER_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case SL_DEVELOPER_UPDATE_RESET:
        return { slDeveloper: {} };
      default:
        return state;
    }
  };
  