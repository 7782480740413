import {
    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,
    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,
    PAYMENT_REGISTER_REQUEST,
    PAYMENT_REGISTER_SUCCESS,
    PAYMENT_REGISTER_FAIL,
    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_DELETE_FAIL,
    PAYMENT_UPDATE_REQUEST,
    PAYMENT_UPDATE_SUCCESS,
    PAYMENT_UPDATE_FAIL,
  } from "../constants/paymentConstants";
  import * as api from "../api/index";
  
  export const listPayment = () => async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_LIST_REQUEST });
  
      const { data } = await api.listPaymentAPI();
  
      dispatch({
        type: PAYMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getPaymentById = (id) => async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_DETAILS_REQUEST });
  
      const { data } = await api.PaymentByIdAPI(id);
      dispatch({
        type: PAYMENT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const paymentRegister = (paymentName,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: PAYMENT_REGISTER_REQUEST,
      });
      const { data } = await api.PaymentRegisterAPI(paymentName);
  
      dispatch({
        type: PAYMENT_REGISTER_SUCCESS,
        payload: data,
      });
  
      dispatch({
        type: PAYMENT_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/payments")
    } catch (error) {
      dispatch({
        type: PAYMENT_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deletePayment = (id) => async (dispatch) => {
    try {
      dispatch({
        type: PAYMENT_DELETE_REQUEST,
      });
  
      await api.PaymentDeleteAPI(id);
  
      dispatch({
        type: PAYMENT_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updatePayment = (payment,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: PAYMENT_UPDATE_REQUEST,
      });
  
      const { data } = await api.PaymentUpdateAPI(payment);
  
      dispatch({
        type: PAYMENT_UPDATE_SUCCESS,
      });
  
      dispatch({
        type: PAYMENT_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/payments")
    } catch (error) {
      dispatch({
        type: PAYMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const searchPayment = (paymentName) => async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_LIST_REQUEST });
  
      const { data } = await api.PaymentSearchAPI(paymentName);
  
      dispatch({
        type: PAYMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  