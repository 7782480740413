export const USER_LEVEL_LIST_REQUEST = "USER_LEVEL_LIST_REQUEST";
export const USER_LEVEL_LIST_SUCCESS = "USER_LEVEL_LIST_SUCCESS";
export const USER_LEVEL_LIST_FAIL = "USER_LEVEL_LIST_FAIL";

export const USER_LEVEL_DETAILS_REQUEST = "USER_LEVEL_DETAILS_REQUEST";
export const USER_LEVEL_DETAILS_SUCCESS = "USER_LEVEL_DETAILS_SUCCESS";
export const USER_LEVEL_DETAILS_FAIL = "USER_LEVEL_DETAILS_FAIL";

export const USER_LEVEL_REGISTER_REQUEST = "USER_LEVEL_REGISTER_REQUEST";
export const USER_LEVEL_REGISTER_SUCCESS = "USER_LEVEL_REGISTER_SUCCESS";
export const USER_LEVEL_REGISTER_FAIL = "USER_LEVEL_REGISTER_FAIL";

export const USER_LEVEL_DELETE_REQUEST = "USER_LEVEL_DELETE_REQUEST";
export const USER_LEVEL_DELETE_SUCCESS = "USER_LEVEL_DELETE_SUCCESS";
export const USER_LEVEL_DELETE_FAIL = "USER_LEVEL_DELETE_FAIL";

export const USER_LEVEL_UPDATE_REQUEST = "USER_LEVEL_UPDATE_REQUEST";
export const USER_LEVEL_UPDATE_SUCCESS = "USER_LEVEL_UPDATE_SUCCESS";
export const USER_LEVEL_UPDATE_FAIL = "USER_LEVEL_UPDATE_FAIL";
export const USER_LEVEL_UPDATE_RESET = "USER_LEVEL_UPDATE_RESET";
