import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  updateUserProfile,
} from "../../redux/actions/userActions";
import { SetRoute } from "../../redux/actions/routeActions";
import { listUserLevel } from "../../redux/actions/userLevelActions";
import { USER_UPDATE_RESET } from "../../redux/constants/userConstants";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function UserEditScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const [id, setId] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [userLevel, setUserLevel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const handleChange = (event) => {
    setUserLevel(event.target.value);
  };

  const Details = useSelector((state) => state.userDetails);
  const { loading, error, user } = Details;

  const ulvl = useSelector((state) => state.userLevelList);
  const { userlevels } = ulvl;

  const update = useSelector((state) => state.userUpdateProfile);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(listUserLevel());
    dispatch(SetRoute("User Edit"));
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
    } else {
      if (!user.userName || user._id !== params.id) {
        dispatch(getUserDetails(params.id));
      } else {
        console.log(user)
        setId(user._id);
        setUserName(user.userName);
        setUserLevel(user.userLevel);
        setEmail(user.email);
      }
    }
  }, [dispatch, user, successUpdate, params]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password === "") {
      if (userName === "") {
        setMessage("Name Is Empty");
      } else if (email === "") {
        setMessage("Email Is Empty");
      } else if (userLevel === "") {
        setMessage("Level Is Empty");
      } else {
        dispatch(
          updateUserProfile(
            {
              _id: id,
              name: userName,
              email: email,
              userLevel: userLevel,
            },
            navigate
          )
        );
      }
    } else {
      if (password !== confirmPassword) {
        setMessage("Password do not Match");
      } else if (userName === "") {
        setMessage("Name Is Empty");
      } else if (email === "") {
        setMessage("Email Is Empty");
      } else if (userLevel === "") {
        setMessage("User Level Is Empty");
      } else {
        dispatch(
          updateUserProfile(
            {
              _id: id,
              name: userName,
              email: email,
              userLevel: userLevel,
              password: password,
            },
            navigate
          )
        );
      }
    }
  };
  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="UserName"
                name="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                margin="normal"
                size="small"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                size="small"
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                size="small"
                label="Confirm-Password"
                name="password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  User Level
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  value={userLevel}
                  onChange={handleChange}
                  label="UserLevels"
                  size="small"
                >
                  {userlevels.map((lvl) => (
                    <MenuItem value={lvl._id} key={lvl._id}>
                      {lvl.userLevel}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default UserEditScreen;
