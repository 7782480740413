import {
  COST_CATEGORY_LIST_REQUEST,
  COST_CATEGORY_LIST_SUCCESS,
  COST_CATEGORY_LIST_FAIL,
  COST_CATEGORY_DETAILS_REQUEST,
  COST_CATEGORY_DETAILS_SUCCESS,
  COST_CATEGORY_DETAILS_FAIL,
  COST_CATEGORY_REGISTER_REQUEST,
  COST_CATEGORY_REGISTER_SUCCESS,
  COST_CATEGORY_REGISTER_FAIL,
  COST_CATEGORY_DELETE_REQUEST,
  COST_CATEGORY_DELETE_SUCCESS,
  COST_CATEGORY_DELETE_FAIL,
  COST_CATEGORY_UPDATE_REQUEST,
  COST_CATEGORY_UPDATE_SUCCESS,
  COST_CATEGORY_UPDATE_FAIL,
} from "../constants/costCategoryConstants";
import * as api from "../api/index";

export const listCostCategory = () => async (dispatch) => {
  try {
    dispatch({ type: COST_CATEGORY_LIST_REQUEST });

    const { data } = await api.listCostCategoryAPI();

    dispatch({
      type: COST_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COST_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCostCategoryById = (costCategorys) => async (dispatch) => {
  try {
    dispatch({ type: COST_CATEGORY_DETAILS_REQUEST });

    const { data } = await api.CostCategoryByIdAPI(costCategorys);
    dispatch({
      type: COST_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COST_CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const costCategoryRegister = (costCategoryName,navigate) => async (dispatch) => {
  try {
    dispatch({
      type: COST_CATEGORY_REGISTER_REQUEST,
    });
    const { data } = await api.CostCategoryRegisterAPI(costCategoryName);

    dispatch({
      type: COST_CATEGORY_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: COST_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/costcategory");
  } catch (error) {
    dispatch({
      type: COST_CATEGORY_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCostCategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COST_CATEGORY_DELETE_REQUEST,
    });

    await api.CostCategoryDeleteAPI(id);

    dispatch({
      type: COST_CATEGORY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: COST_CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCostCategory = (costCategory, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: COST_CATEGORY_UPDATE_REQUEST,
    });

    const { data } = await api.CostCategoryUpdateAPI(costCategory);

    dispatch({
      type: COST_CATEGORY_UPDATE_SUCCESS,
    });

    dispatch({
      type: COST_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/costcategory");
  } catch (error) {
    dispatch({
      type: COST_CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchCostCategory = (costCategoryName) => async (dispatch) => {
  try {
    dispatch({ type: COST_CATEGORY_LIST_REQUEST });

    const { data } = await api.CostCategorySearchAPI(costCategoryName);

    dispatch({
      type: COST_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COST_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
