export const JP_OUT_SOURCE_COST_LIST_REQUEST = "JP_OUT_SOURCE_COST_LIST_REQUEST";
export const JP_OUT_SOURCE_COST_LIST_SUCCESS = "JP_OUT_SOURCE_COST_LIST_SUCCESS";
export const JP_OUT_SOURCE_COST_LIST_FAIL = "JP_OUT_SOURCE_COST_LIST_FAIL";

export const JP_OUT_SOURCE_COST_DETAILS_REQUEST = "JP_OUT_SOURCE_COST_DETAILS_REQUEST";
export const JP_OUT_SOURCE_COST_DETAILS_SUCCESS = "JP_OUT_SOURCE_COST_DETAILS_SUCCESS";
export const JP_OUT_SOURCE_COST_DETAILS_FAIL = "JP_OUT_SOURCE_COST_DETAILS_FAIL";

export const JP_OUT_SOURCE_COST_REGISTER_REQUEST = "JP_OUT_SOURCE_COST_REGISTER_REQUEST";
export const JP_OUT_SOURCE_COST_REGISTER_SUCCESS = "JP_OUT_SOURCE_COST_REGISTER_SUCCESS";
export const JP_OUT_SOURCE_COST_REGISTER_FAIL = "JP_OUT_SOURCE_COST_REGISTER_FAIL";

export const JP_OUT_SOURCE_COST_DELETE_REQUEST = "JP_OUT_SOURCE_COST_DELETE_REQUEST";
export const JP_OUT_SOURCE_COST_DELETE_SUCCESS = "JP_OUT_SOURCE_COST_DELETE_SUCCESS";
export const JP_OUT_SOURCE_COST_DELETE_FAIL = "JP_OUT_SOURCE_COST_DELETE_FAIL";

export const JP_OUT_SOURCE_COST_UPDATE_REQUEST = "JP_OUT_SOURCE_COST_UPDATE_REQUEST";
export const JP_OUT_SOURCE_COST_UPDATE_SUCCESS = "JP_OUT_SOURCE_COST_UPDATE_SUCCESS";
export const JP_OUT_SOURCE_COST_UPDATE_FAIL = "JP_OUT_SOURCE_COST_UPDATE_FAIL";
export const JP_OUT_SOURCE_COST_UPDATE_RESET = "JP_OUT_SOURCE_COST_UPDATE_RESET";
