import {
    LABOUR_COST_LIST_REQUEST,
    LABOUR_COST_LIST_SUCCESS,
    LABOUR_COST_LIST_FAIL,
    LABOUR_COST_DETAILS_REQUEST,
    LABOUR_COST_DETAILS_SUCCESS,
    LABOUR_COST_DETAILS_FAIL,
    LABOUR_COST_REGISTER_REQUEST,
    LABOUR_COST_REGISTER_SUCCESS,
    LABOUR_COST_REGISTER_FAIL,
    LABOUR_COST_DELETE_REQUEST,
    LABOUR_COST_DELETE_SUCCESS,
    LABOUR_COST_DELETE_FAIL,
    LABOUR_COST_UPDATE_REQUEST,
    LABOUR_COST_UPDATE_SUCCESS,
    LABOUR_COST_UPDATE_FAIL,
    LABOUR_COST_UPDATE_RESET,
  } from "../constants/labourCostConstants";
  
  export const labourCostListReducer = (state = { labourCosts: [] }, action) => {
    switch (action.type) {
      case LABOUR_COST_LIST_REQUEST:
        return { loading: true, labourCosts: [] };
      case LABOUR_COST_LIST_SUCCESS:
        return { loading: false, labourCosts: action.payload };
      case LABOUR_COST_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCostByIdReducer = (state = { labourCost: [] }, action) => {
    switch (action.type) {
      case LABOUR_COST_DETAILS_REQUEST:
        return { loading: true, ...state };
      case LABOUR_COST_DETAILS_SUCCESS:
        return { loading: false, labourCost: action.payload };
      case LABOUR_COST_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCostRegisterReducer = (state = { labourCost: [] }, action) => {
    switch (action.type) {
      case LABOUR_COST_REGISTER_REQUEST:
        return { loading: true, labourCost: [] };
      case LABOUR_COST_REGISTER_SUCCESS:
        return { loading: false, labourCost: action.payload };
      case LABOUR_COST_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCostDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LABOUR_COST_DELETE_REQUEST:
        return { loading: true, labourCost: [] };
      case LABOUR_COST_DELETE_SUCCESS:
        return { loading: false, success: true };
      case LABOUR_COST_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCostUpdateReducer = (state = { labourCost: {} }, action) => {
    switch (action.type) {
      case LABOUR_COST_UPDATE_REQUEST:
        return { loading: true };
      case LABOUR_COST_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case LABOUR_COST_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case LABOUR_COST_UPDATE_RESET:
        return { labourCost: {} };
      default:
        return state;
    }
  };
  