import {
  JP_OUT_SOURCE_LIST_REQUEST,
  JP_OUT_SOURCE_LIST_SUCCESS,
  JP_OUT_SOURCE_LIST_FAIL,
  JP_OUT_SOURCE_DETAILS_REQUEST,
  JP_OUT_SOURCE_DETAILS_SUCCESS,
  JP_OUT_SOURCE_DETAILS_FAIL,
  JP_OUT_SOURCE_REGISTER_REQUEST,
  JP_OUT_SOURCE_REGISTER_SUCCESS,
  JP_OUT_SOURCE_REGISTER_FAIL,
  JP_OUT_SOURCE_DELETE_REQUEST,
  JP_OUT_SOURCE_DELETE_SUCCESS,
  JP_OUT_SOURCE_DELETE_FAIL,
  JP_OUT_SOURCE_UPDATE_REQUEST,
  JP_OUT_SOURCE_UPDATE_SUCCESS,
  JP_OUT_SOURCE_UPDATE_FAIL,
} from "../constants/jpOutsourceConstants";
import * as api from "../api/index";

export const listJpOutSources = () => async (dispatch) => {
  try {
    dispatch({ type: JP_OUT_SOURCE_LIST_REQUEST });

    const { data } = await api.listJpOutSourceAPI();

    dispatch({
      type: JP_OUT_SOURCE_LIST_SUCCESS,
      payload: data,
    });   
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getJpOutSourceById = (id) => async (dispatch) => {
  try {
    dispatch({ type: JP_OUT_SOURCE_DETAILS_REQUEST });

    const { data } = await api.JpOutSourceByIdAPI(id);
    dispatch({
      type: JP_OUT_SOURCE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const JpOutSourceRegister = (jpOutsourceName,navigate) => async (dispatch) => {
  try {
    dispatch({
      type: JP_OUT_SOURCE_REGISTER_REQUEST,
    });
    const { data } = await api.JpOutSourceRegisterAPI(jpOutsourceName);

    dispatch({
      type: JP_OUT_SOURCE_REGISTER_SUCCESS,
      payload: data,
    });
console.log(jpOutsourceName)
    dispatch({
      type: JP_OUT_SOURCE_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/jpoutsourse")
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteJpOutSource = (id) => async (dispatch) => {
  try {
    dispatch({
      type: JP_OUT_SOURCE_DELETE_REQUEST,
    });

    await api.JpOutSourceDeleteAPI(id);

    dispatch({
      type: JP_OUT_SOURCE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateJpOutSource = (jpOutSource,navigate) => async (dispatch) => {
  try {
    dispatch({
      type: JP_OUT_SOURCE_UPDATE_REQUEST,
    });

    const { data } = await api.JpOutSourceUpdateAPI(jpOutSource);

    dispatch({
      type: JP_OUT_SOURCE_UPDATE_SUCCESS,
    });

    dispatch({
      type: JP_OUT_SOURCE_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/jpoutsourse")
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchJpOutSource = (jpOutSourceName) => async (dispatch) => {
  try {
    dispatch({ type: JP_OUT_SOURCE_LIST_REQUEST });

    const { data } = await api.JpOutSourceSearchAPI(jpOutSourceName);

    dispatch({
      type: JP_OUT_SOURCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
