import {
    SL_OUTSOURCE_COST_LIST_REQUEST,
    SL_OUTSOURCE_COST_LIST_SUCCESS,
    SL_OUTSOURCE_COST_LIST_FAIL,
    SL_OUTSOURCE_COST_DETAILS_REQUEST,
    SL_OUTSOURCE_COST_DETAILS_SUCCESS,
    SL_OUTSOURCE_COST_DETAILS_FAIL,
    SL_OUTSOURCE_COST_REGISTER_REQUEST,
    SL_OUTSOURCE_COST_REGISTER_SUCCESS,
    SL_OUTSOURCE_COST_REGISTER_FAIL,
    SL_OUTSOURCE_COST_DELETE_REQUEST,
    SL_OUTSOURCE_COST_DELETE_SUCCESS,
    SL_OUTSOURCE_COST_DELETE_FAIL,
    SL_OUTSOURCE_COST_UPDATE_REQUEST,
    SL_OUTSOURCE_COST_UPDATE_SUCCESS,
    SL_OUTSOURCE_COST_UPDATE_FAIL,
    SL_OUTSOURCE_COST_UPDATE_RESET,
  } from "../constants/slOutsourceCostConstants";
  
  export const sloutsourceCostListReducer = (state = { sloutsourcecosts: [] }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_COST_LIST_REQUEST:
        return { loading: true, sloutsourcecosts: [] };
      case SL_OUTSOURCE_COST_LIST_SUCCESS:
        return { loading: false, sloutsourcecosts: action.payload };
      case SL_OUTSOURCE_COST_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    } 
  };
  
  export const sloutsourcecostByIdReducer = (state = { sloutsourcecostById: [] }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_COST_DETAILS_REQUEST:
        return { loading: true, ...state };
      case SL_OUTSOURCE_COST_DETAILS_SUCCESS:
        return { loading: false, sloutsourcecostById: action.payload };
      case SL_OUTSOURCE_COST_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const sloutsourcecostRegisterReducer = (state = { sloutsourcecost: [] }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_COST_REGISTER_REQUEST:
        return { loading: true, sloutsourcecost: [] };
      case SL_OUTSOURCE_COST_REGISTER_SUCCESS:
        return { loading: false, sloutsourcecostInfo: action.payload };
      case SL_OUTSOURCE_COST_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const sloutsourcecostDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_COST_DELETE_REQUEST:
        return { loading: true, sloutsourcecost: [] };
      case SL_OUTSOURCE_COST_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SL_OUTSOURCE_COST_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const sloutsourcecostUpdateReducer = (state = { sloutsourcecost: {} }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_COST_UPDATE_REQUEST:
        return { loading: true };
      case SL_OUTSOURCE_COST_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case SL_OUTSOURCE_COST_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case SL_OUTSOURCE_COST_UPDATE_RESET:
        return { sloutsourcecost: {} };
      default:
        return state;
    }
  };
  