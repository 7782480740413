import {
  OTHER_CATEGORY_LIST_REQUEST,
  OTHER_CATEGORY_LIST_SUCCESS,
  OTHER_CATEGORY_LIST_FAIL,
  OTHER_CATEGORY_DETAILS_REQUEST,
  OTHER_CATEGORY_DETAILS_SUCCESS,
  OTHER_CATEGORY_DETAILS_FAIL,
  OTHER_CATEGORY_REGISTER_REQUEST,
  OTHER_CATEGORY_REGISTER_SUCCESS,
  OTHER_CATEGORY_REGISTER_FAIL,
  OTHER_CATEGORY_DELETE_REQUEST,
  OTHER_CATEGORY_DELETE_SUCCESS,
  OTHER_CATEGORY_DELETE_FAIL,
  OTHER_CATEGORY_UPDATE_REQUEST,
  OTHER_CATEGORY_UPDATE_SUCCESS,
  OTHER_CATEGORY_UPDATE_FAIL,
} from "../constants/otherCategoryConstants";
import * as api from "../api/index";

export const listOtherCategory = () => async (dispatch) => {
  try {
    dispatch({ type: OTHER_CATEGORY_LIST_REQUEST });

    const { data } = await api.listOtherCategoryAPI();

    dispatch({
      type: OTHER_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOtherCategoryById = (id) => async (dispatch) => {
  try {
    dispatch({ type: OTHER_CATEGORY_DETAILS_REQUEST });

    const { data } = await api.OtherCategoryByIdAPI(id);
    dispatch({
      type: OTHER_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const OtherCategoryRegister =
  (othercategory, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: OTHER_CATEGORY_REGISTER_REQUEST,
      });
      const { data } = await api.OtherCategoryRegisterAPI(othercategory);

      dispatch({
        type: OTHER_CATEGORY_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: OTHER_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/othercategory");
    } catch (error) {
      dispatch({
        type: OTHER_CATEGORY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteOtherCategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: OTHER_CATEGORY_DELETE_REQUEST,
    });

    await api.OtherCategoryDeleteAPI(id);

    dispatch({
      type: OTHER_CATEGORY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: OTHER_CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateOtherCategory =
  (othercategory, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: OTHER_CATEGORY_UPDATE_REQUEST,
      });

      const { data } = await api.OtherCategoryUpdateAPI(othercategory);

      dispatch({
        type: OTHER_CATEGORY_UPDATE_SUCCESS,
      });

      dispatch({
        type: OTHER_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/othercategory");
    } catch (error) {
      dispatch({
        type: OTHER_CATEGORY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchOtherCategory = (categoryName) => async (dispatch) => {
  try {
    dispatch({ type: OTHER_CATEGORY_LIST_REQUEST });

    const { data } = await api.OtherCategorySearchAPI(categoryName);

    dispatch({
      type: OTHER_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OTHER_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
