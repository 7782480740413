export const LABOUR_CATEGORY_LIST_REQUEST = "LABOUR_CATEGORY_LIST_REQUEST";
export const LABOUR_CATEGORY_LIST_SUCCESS = "LABOUR_CATEGORY_LIST_SUCCESS";
export const LABOUR_CATEGORY_LIST_FAIL = "LABOUR_CATEGORY_LIST_FAIL";

export const LABOUR_CATEGORY_DETAILS_REQUEST = "LABOUR_CATEGORY_DETAILS_REQUEST";
export const LABOUR_CATEGORY_DETAILS_SUCCESS = "LABOUR_CATEGORY_DETAILS_SUCCESS";
export const LABOUR_CATEGORY_DETAILS_FAIL = "LABOUR_CATEGORY_DETAILS_FAIL";

export const LABOUR_CATEGORY_REGISTER_REQUEST = "LABOUR_CATEGORY_REGISTER_REQUEST";
export const LABOUR_CATEGORY_REGISTER_SUCCESS = "LABOUR_CATEGORY_REGISTER_SUCCESS";
export const LABOUR_CATEGORY_REGISTER_FAIL = "LABOUR_CATEGORY_REGISTER_FAIL";

export const LABOUR_CATEGORY_DELETE_REQUEST = "LABOUR_CATEGORY_DELETE_REQUEST";
export const LABOUR_CATEGORY_DELETE_SUCCESS = "LABOUR_CATEGORY_DELETE_SUCCESS";
export const LABOUR_CATEGORY_DELETE_FAIL = "LABOUR_CATEGORY_DELETE_FAIL";

export const LABOUR_CATEGORY_UPDATE_REQUEST = "LABOUR_CATEGORY_UPDATE_REQUEST";
export const LABOUR_CATEGORY_UPDATE_SUCCESS = "LABOUR_CATEGORY_UPDATE_SUCCESS";
export const LABOUR_CATEGORY_UPDATE_FAIL = "LABOUR_CATEGORY_UPDATE_FAIL";
export const LABOUR_CATEGORY_UPDATE_RESET = "LABOUR_CATEGORY_UPDATE_RESET";
