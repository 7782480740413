import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../redux/actions/userActions";
import Loader from "../Components/Loader";
import Message from "../Components/Message";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./LoginScreen.css";
import { Grid, Paper, Typography } from "@mui/material";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password, navigate));
  };

  useEffect(() => {
    if (userInfo) {
      navigate("/home");
    }
  }, [userInfo, navigate]);

  return (
    <div>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Grid
        container
        spacing={0}
        justify="center"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            className="login-form"
          >
            <Paper
              variant="elevation"
              elevation={2}
              className="login-background"
            >
              <Grid item justifyContent="center" display="flex">
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Grid>
              <Grid item>
                <form onSubmit={submitHandler}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <TextField
                        margin="normal"
                        label="Email"
                        name="Email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        margin="normal"
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item justifyContent="center" display="flex">
                      <Button type="submit" variant="contained">
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginScreen;
