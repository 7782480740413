import {
  JP_STAFF_LIST_REQUEST,
  JP_STAFF_LIST_SUCCESS,
  JP_STAFF_LIST_FAIL,
  JP_STAFF_DETAILS_REQUEST,
  JP_STAFF_DETAILS_SUCCESS,
  JP_STAFF_DETAILS_FAIL,
  JP_STAFF_REGISTER_REQUEST,
  JP_STAFF_REGISTER_SUCCESS,
  JP_STAFF_REGISTER_FAIL,
  JP_STAFF_DELETE_REQUEST,
  JP_STAFF_DELETE_SUCCESS,
  JP_STAFF_DELETE_FAIL,
  JP_STAFF_UPDATE_REQUEST,
  JP_STAFF_UPDATE_SUCCESS,
  JP_STAFF_UPDATE_FAIL,
} from "../constants/jpStaffConstants";
import * as api from "../api/index";

export const listJpStaff = () => async (dispatch) => {
  try {
    dispatch({ type: JP_STAFF_LIST_REQUEST });

    const { data } = await api.listJpStaffAPI();

    dispatch({
      type: JP_STAFF_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_STAFF_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getJpStaffById = (id) => async (dispatch) => {
  try {
    dispatch({ type: JP_STAFF_DETAILS_REQUEST });

    const { data } = await api.JpStaffByIdAPI(id);
    dispatch({
      type: JP_STAFF_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_STAFF_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const JpStaffRegister = (jpStaff,navigate) => async (dispatch) => {
  try {
    dispatch({
      type: JP_STAFF_REGISTER_REQUEST,
    });
    const { data } = await api.JpStaffRegisterAPI(jpStaff);

    dispatch({
      type: JP_STAFF_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: JP_STAFF_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/jpstaff");
  } catch (error) {
    dispatch({
      type: JP_STAFF_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteJpStaff = (id) => async (dispatch) => {
  try {
    dispatch({
      type: JP_STAFF_DELETE_REQUEST,
    });

    await api.JpStaffDeleteAPI(id);

    dispatch({
      type: JP_STAFF_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: JP_STAFF_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateJpStaff = (jpstaff, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: JP_STAFF_UPDATE_REQUEST,
    });

    const { data } = await api.JpStaffUpdateAPI(jpstaff);

    dispatch({
      type: JP_STAFF_UPDATE_SUCCESS,
    });

    dispatch({
      type: JP_STAFF_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/jpstaff");
  } catch (error) {
    dispatch({
      type: JP_STAFF_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchJpStaff = (jpstaffName) => async (dispatch) => {
  try {
    dispatch({ type: JP_STAFF_LIST_REQUEST });

    const { data } = await api.JpStaffSearchAPI(jpstaffName);

    dispatch({
      type: JP_STAFF_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_STAFF_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
