export const OTHER_COST_LIST_REQUEST = "OTHER_COST_LIST_REQUEST";
export const OTHER_COST_LIST_SUCCESS = "OTHER_COST_LIST_SUCCESS";
export const OTHER_COST_LIST_FAIL = "OTHER_COST_LIST_FAIL";

export const OTHER_COST_DETAILS_REQUEST = "OTHER_COST_DETAILS_REQUEST";
export const OTHER_COST_DETAILS_SUCCESS = "OTHER_COST_DETAILS_SUCCESS";
export const OTHER_COST_DETAILS_FAIL = "OTHER_COST_DETAILS_FAIL";

export const OTHER_COST_REGISTER_REQUEST = "OTHER_COST_REGISTER_REQUEST";
export const OTHER_COST_REGISTER_SUCCESS = "OTHER_COST_REGISTER_SUCCESS";
export const OTHER_COST_REGISTER_FAIL = "OTHER_COST_REGISTER_FAIL";

export const OTHER_COST_DELETE_REQUEST = "OTHER_COST_DELETE_REQUEST";
export const OTHER_COST_DELETE_SUCCESS = "OTHER_COST_DELETE_SUCCESS";
export const OTHER_COST_DELETE_FAIL = "OTHER_COST_DELETE_FAIL";

export const OTHER_COST_UPDATE_REQUEST = "OTHER_COST_UPDATE_REQUEST";
export const OTHER_COST_UPDATE_SUCCESS = "OTHER_COST_UPDATE_SUCCESS";
export const OTHER_COST_UPDATE_FAIL = "OTHER_COST_UPDATE_FAIL";
export const OTHER_COST_UPDATE_RESET = "OTHER_COST_UPDATE_RESET";