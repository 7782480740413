import {
  JP_OUT_SOURCE_COST_LIST_REQUEST,
  JP_OUT_SOURCE_COST_LIST_SUCCESS,
  JP_OUT_SOURCE_COST_LIST_FAIL,
  JP_OUT_SOURCE_COST_DETAILS_REQUEST,
  JP_OUT_SOURCE_COST_DETAILS_SUCCESS,
  JP_OUT_SOURCE_COST_DETAILS_FAIL,
  JP_OUT_SOURCE_COST_REGISTER_REQUEST,
  JP_OUT_SOURCE_COST_REGISTER_SUCCESS,
  JP_OUT_SOURCE_COST_REGISTER_FAIL,
  JP_OUT_SOURCE_COST_DELETE_REQUEST,
  JP_OUT_SOURCE_COST_DELETE_SUCCESS,
  JP_OUT_SOURCE_COST_DELETE_FAIL,
  JP_OUT_SOURCE_COST_UPDATE_REQUEST,
  JP_OUT_SOURCE_COST_UPDATE_SUCCESS,
  JP_OUT_SOURCE_COST_UPDATE_FAIL,
} from "../constants/jpOutsourceCostConstants";
import * as api from "../api/index";

export const listJpOutSourceCosts = (project) => async (dispatch) => {
  try {
    dispatch({ type: JP_OUT_SOURCE_COST_LIST_REQUEST });

    const { data } = await api.listJpOutsourceCostByProjectAPI(project);

    dispatch({
      type: JP_OUT_SOURCE_COST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_COST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getJpOutSourceCostById = (id) => async (dispatch) => {
  try {
    dispatch({ type: JP_OUT_SOURCE_COST_DETAILS_REQUEST });

    const { data } = await api.JpOutsourceCostByIdAPI(id);
    dispatch({
      type: JP_OUT_SOURCE_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_COST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const JpOutSourceCostRegister =
  (JpOutSourceCost) => async (dispatch) => {
    console.log(JpOutSourceCost)
    try {
      dispatch({
        type: JP_OUT_SOURCE_COST_REGISTER_REQUEST,
      });

      const { data } = await api.JpOutsourceCostRegisterAPI(JpOutSourceCost);

      dispatch({
        type: JP_OUT_SOURCE_COST_REGISTER_SUCCESS,
        payload: data,
      });    
      dispatch({
        type: JP_OUT_SOURCE_COST_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JP_OUT_SOURCE_COST_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteJpOutSourceCost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: JP_OUT_SOURCE_COST_DELETE_REQUEST,
    });

    await api.JpOutsourceCostDeleteAPI(id);

    dispatch({
      type: JP_OUT_SOURCE_COST_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_COST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateJpOutSourceCost = (jpOutSourcecost) => async (dispatch) => {
  try {
    dispatch({
      type: JP_OUT_SOURCE_COST_UPDATE_REQUEST,
    });

    const { data } = await api.JpOutsourceCostUpdateAPI(jpOutSourcecost);

    dispatch({
      type: JP_OUT_SOURCE_COST_UPDATE_SUCCESS,
    });

    dispatch({
      type: JP_OUT_SOURCE_COST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_COST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchJpOutSourceCost = (jpOutSourceName) => async (dispatch) => {
  try {
    dispatch({ type: JP_OUT_SOURCE_COST_LIST_REQUEST });

    const { data } = await api.JpOutsourceCostSearchAPI(jpOutSourceName);

    dispatch({
      type: JP_OUT_SOURCE_COST_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_OUT_SOURCE_COST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
