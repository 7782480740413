import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetRoute } from "../redux/actions/routeActions";
import { listMaster,listTabs } from "../redux/actions/masterActions";
import { listUserLevel } from "../redux/actions/userLevelActions";
import {
  listUserRole,
  userRoleRegister,
  updateUserRole,
} from "../redux/actions/userRoleActions";
import MuiGrid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [level, setLevel] = useState("");
  const [allowedUrl, setAllowedUrl] = useState([]);
  const [tab, setTab] = useState([]);

  const List = useSelector((state) => state.userLevelList);
  const { userlevels } = List;

  const { masters } = useSelector((state) => state.masterlist);

  const {tabs} = useSelector((state) => state.tablist);

  const handleChangeUserLevel = (event) => {
    setLevel(event.target.value);
  };

  const handleChangeAllowedMasters = (event) => {
    setAllowedUrl(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeTabs = (event) => {
    setTab(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const submitUpdate = () => {
    if (level === "") {
      setMessage("Select A UserLevel");
    } else {
      console.log("submit");
      dispatch(
        userRoleRegister({
          level,
          allowedUrl,
          tab
        })
      );
    }
  };

  useEffect(() => {
    dispatch(listUserRole());
    dispatch(listMaster());
    dispatch(listTabs());
    dispatch(listUserLevel());
    dispatch(SetRoute("Settings"));
  }, [dispatch]);
  return (
    <>
      <form onSubmit={submitUpdate}>
        <MuiGrid container spacing={1} sx={{ p: 1 }}>
          <MuiGrid item xs={4}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select User Level
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  value={level}
                  onChange={handleChangeUserLevel}
                  label="Payment Method"
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {userlevels?.map((userlevel) => (
                    <MenuItem value={userlevel._id} key={userlevel._id}>
                      {" "}
                      {userlevel.userLevel}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select Allowed Masters
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={allowedUrl}
                  onChange={handleChangeAllowedMasters}
                  label=" SL OutSource Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {masters.map((m) => (
                    <MenuItem value={m._id} key={m._id}>
                      {" "}
                      {m.MasterName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select Allowed Tabs
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={tab}
                  onChange={handleChangeTabs}
                  label=" SL OutSource Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {tabs.map((t) => (
                    <MenuItem value={t._id} key={t._id}>
                      {" "}
                      {t.TabName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="outlined" sx={{ m: 1, p: 1 }} type="submit">
                Save
              </Button>
            </FormGroup>
          </MuiGrid>
        </MuiGrid>
      </form>
    </>
  );
};

export default Setting;
