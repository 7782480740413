export const SL_DEVELOPER_LIST_REQUEST = "SL_DEVELOPER_LIST_REQUEST";
export const SL_DEVELOPER_LIST_SUCCESS = "SL_DEVELOPER_LIST_SUCCESS";
export const SL_DEVELOPER_LIST_FAIL = "SL_DEVELOPER_LIST_FAIL";

export const SL_DEVELOPER_DETAILS_REQUEST = "SL_DEVELOPER_DETAILS_REQUEST";
export const SL_DEVELOPER_DETAILS_SUCCESS = "SL_DEVELOPER_DETAILS_SUCCESS";
export const SL_DEVELOPER_DETAILS_FAIL = "SL_DEVELOPER_DETAILS_FAIL";

export const SL_DEVELOPER_REGISTER_REQUEST = "SL_DEVELOPER_REGISTER_REQUEST";
export const SL_DEVELOPER_REGISTER_SUCCESS = "SL_DEVELOPER_REGISTER_SUCCESS";
export const SL_DEVELOPER_REGISTER_FAIL = "SL_DEVELOPER_REGISTER_FAIL";

export const SL_DEVELOPER_DELETE_REQUEST = "SL_DEVELOPER_DELETE_REQUEST";
export const SL_DEVELOPER_DELETE_SUCCESS = "SL_DEVELOPER_DELETE_SUCCESS";
export const SL_DEVELOPER_DELETE_FAIL = "SL_DEVELOPER_DELETE_FAIL";

export const SL_DEVELOPER_UPDATE_REQUEST = "SL_DEVELOPER_UPDATE_REQUEST";
export const SL_DEVELOPER_UPDATE_SUCCESS = "SL_DEVELOPER_UPDATE_SUCCESS";
export const SL_DEVELOPER_UPDATE_FAIL = "SL_DEVELOPER_UPDATE_FAIL";
export const SL_DEVELOPER_UPDATE_RESET = "SL_DEVELOPER_UPDATE_RESET";
