import {
    SL_OUTSOURCE_COST_LIST_REQUEST,
    SL_OUTSOURCE_COST_LIST_SUCCESS,
    SL_OUTSOURCE_COST_LIST_FAIL,
    SL_OUTSOURCE_COST_DETAILS_REQUEST,
    SL_OUTSOURCE_COST_DETAILS_SUCCESS,
    SL_OUTSOURCE_COST_DETAILS_FAIL,
    SL_OUTSOURCE_COST_REGISTER_REQUEST,
    SL_OUTSOURCE_COST_REGISTER_SUCCESS,
    SL_OUTSOURCE_COST_REGISTER_FAIL,
    SL_OUTSOURCE_COST_DELETE_REQUEST,
    SL_OUTSOURCE_COST_DELETE_SUCCESS,
    SL_OUTSOURCE_COST_DELETE_FAIL,
    SL_OUTSOURCE_COST_UPDATE_REQUEST,
    SL_OUTSOURCE_COST_UPDATE_SUCCESS,
    SL_OUTSOURCE_COST_UPDATE_FAIL,
  } from "../constants/slOutsourceCostConstants";
  import * as api from "../api/index";
  
  export const listSlOutsourceCost = (project) => async (dispatch) => {
    try {
      dispatch({ type: SL_OUTSOURCE_COST_LIST_REQUEST });
  
      const { data } = await api.listSlOutsourceCostByProjectAPI(project);
  
      dispatch({
        type: SL_OUTSOURCE_COST_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getSlOutsourceCostById = (id) => async (dispatch) => {
    try {
      dispatch({ type: SL_OUTSOURCE_COST_DETAILS_REQUEST });
  
      const { data } = await api.SlOutsourceCostByIdAPI(id);
      dispatch({
        type: SL_OUTSOURCE_COST_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COST_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const SlOutsourceCostRegister = (sloutsourcecategory) => async (dispatch) => {
    
    try {
      dispatch({
        type: SL_OUTSOURCE_COST_REGISTER_REQUEST,
      });
      const { data } = await api.SlOutsourceCostRegisterAPI(sloutsourcecategory);
  
      dispatch({
        type: SL_OUTSOURCE_COST_REGISTER_SUCCESS,
        payload: data,
      });
  
      dispatch({
        type: SL_OUTSOURCE_COST_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COST_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deleteSlOutsourceCost = (id) => async (dispatch) => {
    try {
      dispatch({
        type: SL_OUTSOURCE_COST_DELETE_REQUEST,
      });
  
      await api.SlOutsourceCostDeleteAPI(id);
  
      dispatch({
        type: SL_OUTSOURCE_COST_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COST_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateSlOutsourceCost = (sloutsourcecost) => async (dispatch) => {
    try {
      dispatch({
        type: SL_OUTSOURCE_COST_UPDATE_REQUEST,
      });
 
      const { data } = await api.SlOutsourceCostUpdateAPI(sloutsourcecost);
  
      dispatch({
        type: SL_OUTSOURCE_COST_UPDATE_SUCCESS,
      });
  
      dispatch({
        type: SL_OUTSOURCE_COST_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COST_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const searchSlOutsourceCost = (categoryName) => async (dispatch) => {
    try {
      dispatch({ type: SL_OUTSOURCE_COST_LIST_REQUEST });
  
      const { data } = await api.SlOutsourceCostSearchAPI(categoryName);
  
      dispatch({
        type: SL_OUTSOURCE_COST_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  