import {
    PROJECT_CATEGORY_LIST_REQUEST,
    PROJECT_CATEGORY_LIST_SUCCESS,
    PROJECT_CATEGORY_LIST_FAIL,
    PROJECT_CATEGORY_DETAILS_REQUEST,
    PROJECT_CATEGORY_DETAILS_SUCCESS,
    PROJECT_CATEGORY_DETAILS_FAIL,
    PROJECT_CATEGORY_REGISTER_REQUEST,
    PROJECT_CATEGORY_REGISTER_SUCCESS,
    PROJECT_CATEGORY_REGISTER_FAIL,
    PROJECT_CATEGORY_DELETE_REQUEST,
    PROJECT_CATEGORY_DELETE_SUCCESS,
    PROJECT_CATEGORY_DELETE_FAIL,
    PROJECT_CATEGORY_UPDATE_REQUEST,
    PROJECT_CATEGORY_UPDATE_SUCCESS,
    PROJECT_CATEGORY_UPDATE_FAIL,
  } from "../constants/projectCategoryConstants";
  import * as api from "../api/index";
  
  export const listProjectCategory = () => async (dispatch) => {
    try {
      dispatch({ type: PROJECT_CATEGORY_LIST_REQUEST });
  
      const { data } = await api.listProjectCategoryAPI();
  
      dispatch({
        type: PROJECT_CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getProjectCategoryById = (id) => async (dispatch) => {
    try {
      dispatch({ type: PROJECT_CATEGORY_DETAILS_REQUEST });
  
      const { data } = await api.ProjectCategoryByIdAPI(id);
      dispatch({
        type: PROJECT_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const projectCategoryRegister = (projectCategory,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_CATEGORY_REGISTER_REQUEST,
      });
      const { data } = await api.ProjectCategoryRegisterAPI(projectCategory);
  
      dispatch({
        type: PROJECT_CATEGORY_REGISTER_SUCCESS,
        payload: data,
      });
  
      dispatch({
        type: PROJECT_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/projectcategory")
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deleteProjectCategory = (id) => async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_CATEGORY_DELETE_REQUEST,
      });
  
      await api.ProjectCategoryDeleteAPI(id);
  
      dispatch({
        type: PROJECT_CATEGORY_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateProjectCategory = (projectCategory,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: PROJECT_CATEGORY_UPDATE_REQUEST,
      });
  
      const { data } = await api.ProjectCategoryUpdateAPI(projectCategory);
  
      dispatch({
        type: PROJECT_CATEGORY_UPDATE_SUCCESS,
      });
  
      dispatch({
        type: PROJECT_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
      
      navigate("/projectcategory")
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const searchProjectCategory = (projectCategoryName) => async (dispatch) => {
    try {
      dispatch({ type: PROJECT_CATEGORY_LIST_REQUEST });
  
      const { data } = await api.ProjectCategorySearchAPI(projectCategoryName);
  
      dispatch({
        type: PROJECT_CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  