import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/actions/userActions";
import { SetRoute } from "../../redux/actions/routeActions";
import { listMaster } from "../../redux/actions/masterActions";
import { listUserLevel } from "../../redux/actions/userLevelActions";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

const UserAddScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [userLevel, setUserLevel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [allowedMaster, setAllowedMaster] = useState([]);

  const updateMaster = (e) => {
    const array = e.target.value.split(",");
    const exist = allowedMaster.filter((item) => {
      return item[1] === array[1];
    });

    if (exist.length > 0) {
      const filterd = allowedMaster.filter((item) => {
        return item[1] !== array[1];
      });

      setAllowedMaster(filterd);
    } else {
      setAllowedMaster([...allowedMaster, array]);
    }
  };

  const handleChange = (event) => {
    setUserLevel(event.target.value);
  };

  const userRegister = useSelector((state) => state.userRegister);
  const { loading: regLoading, error: regError } = userRegister;

  const ulvl = useSelector((state) => state.userLevelList);
  const { userlevels } = ulvl;

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password do not Match");
    } else if (userName === "") {
      setMessage("Name Is Empty");
    } else if (email === "") {
      setMessage("Level Is Empty");
    } else if (email === "") {
      setMessage("Email Is Empty");
    } else {
      dispatch(registerUser(userName, email, password, userLevel, navigate));
    }
  };

  useEffect(() => {
    dispatch(listUserLevel());
    dispatch(listMaster());
    dispatch(SetRoute("New User"));
  }, [dispatch]);

  return (
    <>
      <Container maxWidth="sm">
        {message && (
          <Message variant="outlined" severity="error">
            {message}
          </Message>
        )}
        {regError && <Message variant="danger">{regError}</Message>}

        <form
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 5 },
          }}
          onSubmit={submitHandler}
        >
          <FormGroup>
            <TextField
              margin="normal"
              size="small"
              label="UserName"
              name="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <FormControl>
              <TextField
                margin="normal"
                size="small"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                margin="normal"
                size="small"
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                margin="normal"
                size="small"
                label="Confirm-Password"
                name="password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <InputLabel id="demo-simple-select-autowidth-label">
                User Level
              </InputLabel>
              <Select
                sx={{ m: 1 }}
                value={userLevel}
                size="small"
                onChange={handleChange}
                label="UserLevels"
              >
                {userlevels.map((lvl) => (
                  <MenuItem value={lvl._id} key={lvl._id}>
                    {lvl.userLevel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {regLoading && <Loader />}
            <Button type="submit" size="small" variant="outlined">
              Submit
            </Button>
          </FormGroup>
        </form>
      </Container>
    </>
  );
};
export default UserAddScreen;
