import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordian from "../Components/Accordian";
import JpOutsourseTable from "../Components/JpOutsourseTable";
import LabourCostTable from "../Components/LabourCostTable";
import OtherCostTable from "../Components/OtherCostTable";
import SlOutsourceCostTable from "../Components/SlOutsourceCostTable";

export default function Costs() {
  const params = useParams();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <Box sx={{ width: "98%", typography: "body1", m: 2 }}>
      <Accordian />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", m: 2 }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            centered
          >
            {userInfo.tabs?.map((tab) => (
              <Tab key={tab.TabName} label={tab.TabName} value={tab.TabValue} />
            ))}
          </TabList>
        </Box>
        <TabPanel value="1">
          <JpOutsourseTable project={params.id} />
        </TabPanel>
        <TabPanel value="2">
          <OtherCostTable project={params.id} />
        </TabPanel>
        <TabPanel value="3">
          <SlOutsourceCostTable project={params.id} />
        </TabPanel>
        <TabPanel value="4">
          <LabourCostTable project={params.id} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
