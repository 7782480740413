import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getJpOutSourceCategoryById,
  updateJpOutSourceCategory,
} from "../../redux/actions/jpOutsourceCategoryActions";
import { SetRoute } from "../../redux/actions/routeActions";
import { JP_OUTSOURCE_CATEGORY_UPDATE_RESET } from "../../redux/constants/jpOutsourceCategoryConstants";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function JpOutsourceCategoryEditScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jpoutsourcecategoryId = useParams();

  const [id, setId] = useState("");
  const [jpoutsourcecategoryName, setJpOutsourceCategoryName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.jpOutSourceCategoryById);
  const { loading, error, jpOutSource } = Details;

  const update = useSelector((state) => state.jpOutSourceCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("JpOutsource Category Edit"));
    if (successUpdate) {
      dispatch({ type: JP_OUTSOURCE_CATEGORY_UPDATE_RESET });
    } else {
      if (
        !jpOutSource.JpOutsourceCategoryName ||
        jpOutSource._id !== jpoutsourcecategoryId.id
      ) {
        dispatch(getJpOutSourceCategoryById(jpoutsourcecategoryId.id));
      } else {
        setId(jpOutSource._id);
        setJpOutsourceCategoryName(jpOutSource.JpOutsourceCategoryName);
      }
    }
  }, [dispatch, jpOutSource, successUpdate, jpoutsourcecategoryId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (jpoutsourcecategoryName === "") {
      if (jpoutsourcecategoryName === "") {
        setMessage("Name Is Empty");
      } else {
        dispatch(
          updateJpOutSourceCategory(
            {
              _id: id,
              name: jpoutsourcecategoryName,
            },
            navigate
          )
        );
      }
    } else {
      if (jpoutsourcecategoryName === "") {
        setMessage("Name Is Empty");
      } else {
        dispatch(
          updateJpOutSourceCategory(
            {
              _id: id,
              name: jpoutsourcecategoryName,
            },
            navigate
          )
        );
      }
    }
  };
  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="JpOutsourceCategory Name"
                name="jpoutsourcecategoryName"
                value={jpoutsourcecategoryName}
                onChange={(e) => setJpOutsourceCategoryName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default JpOutsourceCategoryEditScreen;
