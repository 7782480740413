import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getSlDeveloperById,
  updateSlDeveloper,
} from "../../redux/actions/slDeveloperActons";
import { SL_DEVELOPER_UPDATE_RESET } from "../../redux/constants/slDeveloperConstants";
import { useParams } from "react-router-dom";
import { SetRoute } from "../../redux/actions/routeActions";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function SlDeveloperEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slDeveloperId = useParams();

  const [id, setId] = useState("");
  const [sldeveloperName, setSlDeveloperName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.slDeveloperById);
  const { loading, error, slDeveloper } = Details;

  const update = useSelector((state) => state.sldeveloperbyIdUpdate);
  const {
    loading: sldeveloperUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update || {};

  useEffect(() => {
    dispatch(SetRoute("Sl Developer Edit"));
    if (successUpdate) {
      dispatch({ type: SL_DEVELOPER_UPDATE_RESET });
    } else {
      if (
        !slDeveloper.slDeveloperName ||
        slDeveloper._id !== slDeveloperId.id
      ) {
        dispatch(getSlDeveloperById(slDeveloperId.id));
      } else {
        setId(slDeveloper._id);
        setSlDeveloperName(slDeveloper.slDeveloperName);
      }
    }
  }, [dispatch, slDeveloper, successUpdate, slDeveloperId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (sldeveloperName === "") {
      setMessage("statusName is empty");
    } else {
      dispatch(
        updateSlDeveloper(
          {
            _id: id,
            name: sldeveloperName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {sldeveloperUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="SLDeveloperName"
                name="sldeveloperName"
                value={sldeveloperName}
                onChange={(e) => setSlDeveloperName(e.target.value)}
              />

              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default SlDeveloperEditScreen;
