import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { statusRegister } from "../../redux/actions/statusAction";
import { SetRoute } from "../../redux/actions/routeActions";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function StatusAddScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [statusName, setStatusName] = useState("");
  const [message, setMessage] = useState(null);

  const Register = useSelector((state) => state.statusRegister);
  const { loading: regLoading, error: regError } = Register;

  const submitHandler = (e) => {
    e.preventDefault();
    if (statusName === "") {
      setMessage("OtherCategoryName Is Empty");
    } else {
      dispatch(statusRegister({ name: statusName }, navigate));
    }
  };

  useEffect(() => {
    dispatch(SetRoute("New Status"));
  }, [dispatch]);

  return (
    <>
      <Container maxWidth="sm">
        {message && (
          <Message variant="outlined" severity="error">
            {message}
          </Message>
        )}
        {regError && <Message variant="danger">{regError}</Message>}

        <form
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 5 },
          }}
          onSubmit={submitHandler}
        >
          <FormGroup>
            <TextField
              margin="normal"
              size="small"
              label="StatusName"
              name="statusName"
              value={statusName}
              onChange={(e) => setStatusName(e.target.value)}
            />
            {regLoading && <Loader />}
            <Button type="submit" size="small" variant="outlined">
              Submit
            </Button>
          </FormGroup>
        </form>
      </Container>
    </>
  );
}

export default StatusAddScreen;
