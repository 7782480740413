import {
    JP_OUT_SOURCE_LIST_REQUEST,
    JP_OUT_SOURCE_LIST_SUCCESS,
    JP_OUT_SOURCE_LIST_FAIL,
    JP_OUT_SOURCE_DETAILS_REQUEST,
    JP_OUT_SOURCE_DETAILS_SUCCESS,
    JP_OUT_SOURCE_DETAILS_FAIL,
    JP_OUT_SOURCE_REGISTER_REQUEST,
    JP_OUT_SOURCE_REGISTER_SUCCESS,
    JP_OUT_SOURCE_REGISTER_FAIL,
    JP_OUT_SOURCE_DELETE_REQUEST,
    JP_OUT_SOURCE_DELETE_SUCCESS,
    JP_OUT_SOURCE_DELETE_FAIL,
    JP_OUT_SOURCE_UPDATE_REQUEST,
    JP_OUT_SOURCE_UPDATE_SUCCESS,
    JP_OUT_SOURCE_UPDATE_FAIL,
    JP_OUT_SOURCE_UPDATE_RESET,
  } from "../constants/jpOutsourceConstants";
  
  export const jpOutSourceListReducer = (state = { jpOutSources: [] }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_LIST_REQUEST:
        return { loading: true, jpOutSources: [] };
      case JP_OUT_SOURCE_LIST_SUCCESS:
        return { loading: false, jpOutSources: action.payload };
      case JP_OUT_SOURCE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceByIdReducer = (state = { jpOutSource: [] }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_DETAILS_REQUEST:
        return { loading: true, ...state };
      case JP_OUT_SOURCE_DETAILS_SUCCESS:
        return { loading: false, jpOutSource: action.payload };
      case JP_OUT_SOURCE_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceRegisterReducer = (state = { jpOutSource: [] }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_REGISTER_REQUEST:
        return { loading: true, jpOutSource: [] };
      case JP_OUT_SOURCE_REGISTER_SUCCESS:
        return { loading: false, jpOutSource: action.payload };
      case JP_OUT_SOURCE_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_DELETE_REQUEST:
        return { loading: true, jpOutSource: [] };
      case JP_OUT_SOURCE_DELETE_SUCCESS:
        return { loading: false, success: true };
      case JP_OUT_SOURCE_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceUpdateReducer = (state = { jpOutSource: {} }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_UPDATE_REQUEST:
        return { loading: true };
      case JP_OUT_SOURCE_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case JP_OUT_SOURCE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case JP_OUT_SOURCE_UPDATE_RESET:
        return { jpOutSource: {} };
      default:
        return state;
    }
  };
  