export const SALES_STAFF_LIST_REQUEST = "SALES_STAFF_LIST_REQUEST";
export const SALES_STAFF_LIST_SUCCESS = "SALES_STAFF_LIST_SUCCESS";
export const SALES_STAFF_LIST_FAIL = "SALES_STAFF_LIST_FAIL";

export const SALES_STAFF_DETAILS_REQUEST = "SALES_STAFF_DETAILS_REQUEST";
export const SALES_STAFF_DETAILS_SUCCESS = "SALES_STAFF_DETAILS_SUCCESS";
export const SALES_STAFF_DETAILS_FAIL = "SALES_STAFF_DETAILS_FAIL";

export const SALES_STAFF_REGISTER_REQUEST = "SALES_STAFF_REGISTER_REQUEST";
export const SALES_STAFF_REGISTER_SUCCESS = "SALES_STAFF_REGISTER_SUCCESS";
export const SALES_STAFF_REGISTER_FAIL = "SALES_STAFF_REGISTER_FAIL";

export const SALES_STAFF_DELETE_REQUEST = "SALES_STAFF_DELETE_REQUEST";
export const SALES_STAFF_DELETE_SUCCESS = "SALES_STAFF_DELETE_SUCCESS";
export const SALES_STAFF_DELETE_FAIL = "SALES_STAFF_DELETE_FAIL";

export const SALES_STAFF_UPDATE_REQUEST = "SALES_STAFF_UPDATE_REQUEST";
export const SALES_STAFF_UPDATE_SUCCESS = "SALES_STAFF_UPDATE_SUCCESS";
export const SALES_STAFF_UPDATE_FAIL = "SALES_STAFF_UPDATE_FAIL";
export const SALES_STAFF_UPDATE_RESET = "SALES_STAFF_UPDATE_RESET";
