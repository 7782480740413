import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetRoute } from "../redux/actions/routeActions";
import { listStatus } from "../redux/actions/statusAction";
import { listSlDeveloper } from "../redux/actions/slDeveloperActons";
import { listJpOutSources } from "../redux/actions/jpOutsourceActions";
import { listJpDevelopment } from "../redux/actions/jpDevelopmentActons";
import { listSalesStaff } from "../redux/actions/salesStaffActions";
import { listProjectCategory } from "../redux/actions/projectCategoryAction";
import {
  projectsRegister,
  listProject,
  getProjectById,
} from "../redux/actions/projectActions";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import MuiGrid from "@mui/material/Grid";
import Message from "../Components/Message";
import Loader from "../Components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";

const Project = () => {
  const dispatch = useDispatch();

  const [projectname, setProjectname] = useState("");
  const [jiraID, setJiraID] = useState("");
  const [category, setCategory] = useState("");
  const [salesstaff, setSalesstaff] = useState([]);
  const [jpdevelopment, setJpdevelopment] = useState([]);
  const [jpoutsource, setJpoutsource] = useState([]);
  const [sldevelopers, setSldevelopers] = useState([]);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [finishDate, setValue] = useState(new Date(Date.now()));
  const [message, setMessage] = useState(null);

  //--------------------STATUS----------------------------------------
  const statusList = useSelector((state) => state.statusList);
  const { statuses } = statusList;

  //--------------------SL_DEVELOPERS_LIST----------------------------
  const slDeveloperList = useSelector((state) => state.slDeveloperList);
  const { slDevelopers } = slDeveloperList;

  //--------------------JP_OUTSOURCE_LIST------------------------------
  const jpOutSourceList = useSelector((state) => state.jpOutSourceList);
  const { jpOutSources } = jpOutSourceList;

  //--------------------JP_DEVELOPMENT_LIST-----------------------------
  const jpDevelopmentList = useSelector((state) => state.jpDevelopmentList);
  const { jpDevelopments } = jpDevelopmentList;

  //----------------------SALES_STAFF_LIST-------------------------------
  const salesStaffList = useSelector((state) => state.salesStaffList);
  const { salesStaffs } = salesStaffList;

  //----------------------PROJECT_AUTO_NUMBER-------------------------------
  const projectlist = useSelector((state) => state.projectsList);
  const { loading, error, projects } = projectlist;

  //---------------------PROJECT_CATEGORY---------------------------------------
  const projectCategorysList = useSelector(
    (state) => state.projectCategorysList
  );
  const { projectCategorys } = projectCategorysList;

  const Details = useSelector((state) => state.projectRegister);
  const { loading: listLoading, error: listError, project } = Details;

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeFinishDate = (newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChangesldevelopers = (event) => {
    setSldevelopers(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangejpoutsource = (event) => {
    setJpoutsource(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangejpdevelopment = (event) => {
    setJpdevelopment(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangesalesstaff = (event) => {
    setSalesstaff(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangecategory = (event) => {
    setCategory(event.target.value);
  };

  const updateHandler = (id) => {
    dispatch(getProjectById(id));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (projectname === "") {
      setMessage("Project Name Is Empty");
    } else if (jiraID === "") {
      setMessage("JiraID Is Empty");
    } else if (category === "") {
      setMessage("Category Is Empty");
    } else if (salesstaff.length === 0) {
      setMessage("Sales Staff Is Empty");
    } else if (jpdevelopment.length === 0) {
      setMessage("Jp Development Is Empty");
    } else if (jpoutsource.length === 0) {
      setMessage("Jp Outsource Is Empty");
    } else if (sldevelopers.length === 0) {
      setMessage("Sl Developer Is Empty");
    } else if (status === "") {
      setMessage("Status Is Empty");
    } else {
      setMessage("");
      dispatch(
        projectsRegister({
          projectname,
          jiraID,
          category,
          salesstaff,
          jpdevelopment,
          jpoutsource,
          sldevelopers,
          startDate,
          finishDate,
          status,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(listStatus());
    dispatch(listSlDeveloper());
    dispatch(listJpOutSources());
    dispatch(listJpDevelopment());
    dispatch(listSalesStaff());
    dispatch(listProjectCategory());
    dispatch(listProject());
    dispatch(SetRoute("Manage Projects"));
  }, [dispatch, project]);

  return (
    <>
      <Box sx={{ width: "99%", typography: "body1", m: 2 }}>
        {(listLoading || loading) && <Loader />}
        {(listError || error) && (
          <Message variant="danger">{error || listError}</Message>
        )}
        {message && (
          <Message variant="outlined" severity="error">
            {message}
          </Message>
        )}

        <form onSubmit={submitHandler}>
          <MuiGrid container spacing={1} sx={{ p: 1 }}>
            <MuiGrid item xs={2}>
              <FormGroup>
                <TextField
                  sx={{ m: 1 }}
                  label="Project No"
                  disabled
                  name="projectno"
                  size="small"
                />
                <TextField
                  sx={{ m: 1 }}
                  label="Project Name"
                  name="projectname"
                  value={projectname}
                  size="small"
                  onChange={(e) => setProjectname(e.target.value)}
                />
              </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={2}>
              <FormGroup>
                <TextField
                  sx={{ m: 1 }}
                  label="Jira ID"
                  name="jiraID"
                  value={jiraID}
                  size="small"
                  onChange={(e) => setJiraID(e.target.value)}
                />
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Project Category
                  </InputLabel>
                  <Select
                    sx={{ m: 1 }}
                    value={category}
                    onChange={handleChangecategory}
                    size="small"
                    label="Jp Development"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {projectCategorys.map((projectCategory) => (
                      <MenuItem
                        value={projectCategory._id}
                        key={projectCategory._id}
                      >
                        {projectCategory.ProjectCategoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={2}>
              <FormGroup>
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Sales staff
                  </InputLabel>
                  <Select
                    sx={{ m: 1 }}
                    multiple
                    value={salesstaff}
                    onChange={handleChangesalesstaff}
                    size="small"
                    label="Jp Development"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {salesStaffs.map((salesStaff) => (
                      <MenuItem value={salesStaff._id} key={salesStaff._id}>
                        {salesStaff.SalesStaffName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Jp Development
                  </InputLabel>
                  <Select
                    sx={{ m: 1 }}
                    multiple
                    value={jpdevelopment}
                    onChange={handleChangejpdevelopment}
                    size="small"
                    label="Jp Development"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {jpDevelopments?.map((jpDevelopment) => (
                      <MenuItem
                        value={jpDevelopment._id}
                        key={jpDevelopment._id}
                      >
                        {" "}
                        {jpDevelopment.JpDevelopmentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={2}>
              <FormGroup>
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Jp outsource
                  </InputLabel>
                  <Select
                    sx={{ m: 1 }}
                    multiple
                    value={jpoutsource}
                    onChange={handleChangejpoutsource}
                    size="small"
                    label="Jp outsource"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {jpOutSources?.map((jpOutSource) => (
                      <MenuItem value={jpOutSource._id} key={jpOutSource._id}>
                        {" "}
                        {jpOutSource.JpOutsourceName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Sl Developers
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    sx={{ m: 1 }}
                    value={sldevelopers}
                    onChange={handleChangesldevelopers}
                    size="small"
                    label="Sl Developers"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {slDevelopers?.map((slDeveloper) => (
                      <MenuItem key={slDeveloper._id} value={slDeveloper._id}>
                        {" "}
                        {slDeveloper.SlDeveloperName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={2}>
              <FormGroup>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      renderInput={(props) => (
                        <TextField
                          margin="normal"
                          size="small"
                          {...props}
                          sx={{ m: 1 }}
                        />
                      )}
                      label="Start"
                      value={startDate}
                      onChange={(startDate) => handleChangeStartDate(startDate)}
                      size="small"
                      inputFormat="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Status
                  </InputLabel>
                  <Select
                    sx={{ m: 1 }}
                    value={status}
                    onChange={handleChange}
                    size="small"
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {statuses?.map((status) => (
                      <MenuItem value={status.StatusName} key={status._id}>
                        {status.StatusName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </MuiGrid>
            <MuiGrid item xs={2}>
              <FormGroup>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        margin="normal"
                        size="small"
                        {...props}
                        sx={{ m: 1 }}
                      />
                    )}
                    label="Finish"
                    value={finishDate}
                    onChange={(finishDate) =>
                      handleChangeFinishDate(finishDate)
                    }
                    inputFormat="DD/MM/YYYY"
                  />
                </LocalizationProvider>
                <Button variant="outlined" sx={{ m: 1, p: 1 }} type="submit">
                  Submit
                </Button>
              </FormGroup>
            </MuiGrid>
          </MuiGrid>
        </form>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Project No</b>
              </TableCell>
              <TableCell>
                <b>Jira ID</b>
              </TableCell>
              <TableCell>
                <b>Project Name</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow
                key={project._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {project.ProjectNo}
                </TableCell>
                <TableCell component="th" scope="row">
                  {project.JiraID}
                </TableCell>
                <TableCell component="th" scope="row">
                  {project.ProjectName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {project.Status}
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={`/costs/${project._id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      endIcon={<EditIcon />}
                      onClick={(e) => updateHandler(project._id)}
                      sx={{ mr: 1 }}
                    >
                      Edit
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Project;
