import {
  SALES_STAFF_LIST_REQUEST,
  SALES_STAFF_LIST_SUCCESS,
  SALES_STAFF_LIST_FAIL,
  SALES_STAFF_DETAILS_REQUEST,
  SALES_STAFF_DETAILS_SUCCESS,
  SALES_STAFF_DETAILS_FAIL,
  SALES_STAFF_REGISTER_REQUEST,
  SALES_STAFF_REGISTER_SUCCESS,
  SALES_STAFF_REGISTER_FAIL,
  SALES_STAFF_DELETE_REQUEST,
  SALES_STAFF_DELETE_SUCCESS,
  SALES_STAFF_DELETE_FAIL,
  SALES_STAFF_UPDATE_REQUEST,
  SALES_STAFF_UPDATE_SUCCESS,
  SALES_STAFF_UPDATE_FAIL,
} from "../constants/salesStaffConstants";
import * as api from "../api/index";

export const listSalesStaff = () => async (dispatch) => {
  try {
    dispatch({ type: SALES_STAFF_LIST_REQUEST });

    const { data } = await api.listSalesStaffAPI();

    dispatch({
      type: SALES_STAFF_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SALES_STAFF_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSalesStaffById = (id) => async (dispatch) => {
  try {
    dispatch({ type: SALES_STAFF_DETAILS_REQUEST });

    const { data } = await api.SalesStaffByIdAPI(id);
    dispatch({
      type: SALES_STAFF_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SALES_STAFF_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const salesStaffRegister =
  (salesStaff, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SALES_STAFF_REGISTER_REQUEST,
      });
      const { data } = await api.SalesStaffRegisterAPI(salesStaff);

      dispatch({
        type: SALES_STAFF_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SALES_STAFF_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/salesstaff");
    } catch (error) {
      dispatch({
        type: SALES_STAFF_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSalesStaff = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SALES_STAFF_DELETE_REQUEST,
    });

    await api.SalesStaffDeleteAPI(id);

    dispatch({
      type: SALES_STAFF_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SALES_STAFF_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSalesStaff = (salesStaff, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: SALES_STAFF_UPDATE_REQUEST,
    });

    const { data } = await api.SalesStaffUpdateAPI(salesStaff);

    dispatch({
      type: SALES_STAFF_UPDATE_SUCCESS,
    });

    dispatch({
      type: SALES_STAFF_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/salesstaff");
  } catch (error) {
    dispatch({
      type: SALES_STAFF_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchSalesStaff = (salesStaffName) => async (dispatch) => {
  try {
    dispatch({ type: SALES_STAFF_LIST_REQUEST });

    const { data } = await api.SalesStaffSearchAPI(salesStaffName);

    dispatch({
      type: SALES_STAFF_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SALES_STAFF_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
