export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";

export const PAYMENT_DETAILS_REQUEST = "PAYMENT_DETAILS_REQUEST";
export const PAYMENT_DETAILS_SUCCESS = "PAYMENT_DETAILS_SUCCESS";
export const PAYMENT_DETAILS_FAIL = "PAYMENT_DETAILS_FAIL";

export const PAYMENT_REGISTER_REQUEST = "PAYMENT_REGISTER_REQUEST";
export const PAYMENT_REGISTER_SUCCESS = "PAYMENT_REGISTER_SUCCESS";
export const PAYMENT_REGISTER_FAIL = "PAYMENT_REGISTER_FAIL";

export const PAYMENT_DELETE_REQUEST = "PAYMENT_DELETE_REQUEST";
export const PAYMENT_DELETE_SUCCESS = "PAYMENT_DELETE_SUCCESS";
export const PAYMENT_DELETE_FAIL = "PAYMENT_DELETE_FAIL";

export const PAYMENT_UPDATE_REQUEST = "PAYMENT_UPDATE_REQUEST";
export const PAYMENT_UPDATE_SUCCESS = "PAYMENT_UPDATE_SUCCESS";
export const PAYMENT_UPDATE_FAIL = "PAYMENT_UPDATE_FAIL";
export const PAYMENT_UPDATE_RESET = "PAYMENT_UPDATE_RESET";