import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getProjectCategoryById,
  updateProjectCategory,
} from "../../redux/actions/projectCategoryActions";
import { PROJECT_CATEGORY_UPDATE_RESET } from "../../redux/constants/projectCategoryConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function ProjectCategoryEditScreen() {
  const projectCategoryId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [projectCategoryName, setProjectCategoryName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.projectCategoryById);
  const { loading, error, projectCategory } = Details;

  const update = useSelector((state) => state.projectCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Project Category Edit"));
    if (successUpdate) {
      dispatch({ type: PROJECT_CATEGORY_UPDATE_RESET });
    } else {
      if (
        !projectCategory.ProjectCategoryName ||
        projectCategory._id !== projectCategoryId.id
      ) {
        dispatch(getProjectCategoryById(projectCategoryId.id));
      } else {
        setId(projectCategory._id);
        setProjectCategoryName(projectCategory.ProjectCategoryName);
      }
    }
  }, [dispatch, successUpdate, projectCategory, projectCategoryId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (projectCategoryName === "") {
      setMessage("Cost Category Name Is Empty");
    } else {
      dispatch(
        updateProjectCategory(
          {
            _id: id,
            name: projectCategoryName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="ProjectCategoryName"
                name="Project Category Name"
                value={projectCategoryName}
                onChange={(e) => setProjectCategoryName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default ProjectCategoryEditScreen;
