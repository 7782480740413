import { ROUTE_REQUEST, ROUTE_REMOVE } from "../constants/routeConstants";

export const RouteReducer = (state = {}, action) => {
  switch (action.type) {
    case ROUTE_REQUEST:
      localStorage.setItem("routeInfo", JSON.stringify({ ...action?.payload }));
      return { ...state, routeInfo: action.payload };
    case ROUTE_REMOVE:
      return { ...state, routeInfo: null };
    default:
      return state;
  }
};
