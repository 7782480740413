import {
    COST_CATEGORY_LIST_REQUEST,
    COST_CATEGORY_LIST_SUCCESS,
    COST_CATEGORY_LIST_FAIL,
    COST_CATEGORY_DETAILS_REQUEST,
    COST_CATEGORY_DETAILS_SUCCESS,
    COST_CATEGORY_DETAILS_FAIL,
    COST_CATEGORY_REGISTER_REQUEST,
    COST_CATEGORY_REGISTER_SUCCESS,
    COST_CATEGORY_REGISTER_FAIL,
    COST_CATEGORY_DELETE_REQUEST,
    COST_CATEGORY_DELETE_SUCCESS,
    COST_CATEGORY_DELETE_FAIL,
    COST_CATEGORY_UPDATE_REQUEST,
    COST_CATEGORY_UPDATE_SUCCESS,
    COST_CATEGORY_UPDATE_FAIL,
    COST_CATEGORY_UPDATE_RESET,
  } from "../constants/costCategoryConstants";
  
  export const costCategorysListReducer = (state = { costCategorys: [] }, action) => {
    switch (action.type) {
      case COST_CATEGORY_LIST_REQUEST:
        return { loading: true, costCategorys: [] };
      case COST_CATEGORY_LIST_SUCCESS:
        return { loading: false, costCategorys: action.payload };
      case COST_CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const costCategoryByIdReducer = (state = { costCategory: [] }, action) => {
    switch (action.type) {
      case COST_CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case COST_CATEGORY_DETAILS_SUCCESS:
        return { loading: false, costCategory: action.payload };
      case COST_CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const costCategoryRegisterReducer = (state = { costCategory: [] }, action) => {
    switch (action.type) {
      case COST_CATEGORY_REGISTER_REQUEST:
        return { loading: true, costCategory: [] };
      case COST_CATEGORY_REGISTER_SUCCESS:
        return { loading: false, costCategoryInfo: action.payload };
      case COST_CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const costCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case COST_CATEGORY_DELETE_REQUEST:
        return { loading: true, costCategory: [] };
      case COST_CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case COST_CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const costCategoryUpdateReducer = (state = { costCategory: {} }, action) => {
    switch (action.type) {
      case COST_CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case COST_CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case COST_CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case COST_CATEGORY_UPDATE_RESET:
        return { costCategory: {} };
      default:
        return state;
    }
  };
  