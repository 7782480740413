import {
    JP_DEVELOPMENT_LIST_REQUEST,
    JP_DEVELOPMENT_LIST_SUCCESS,
    JP_DEVELOPMENT_LIST_FAIL,
    JP_DEVELOPMENT_DETAILS_REQUEST,
    JP_DEVELOPMENT_DETAILS_SUCCESS,
    JP_DEVELOPMENT_DETAILS_FAIL,
    JP_DEVELOPMENT_REGISTER_REQUEST,
    JP_DEVELOPMENT_REGISTER_SUCCESS,
    JP_DEVELOPMENT_REGISTER_FAIL,
    JP_DEVELOPMENT_DELETE_REQUEST,
    JP_DEVELOPMENT_DELETE_SUCCESS,
    JP_DEVELOPMENT_DELETE_FAIL,
    JP_DEVELOPMENT_UPDATE_REQUEST,
    JP_DEVELOPMENT_UPDATE_SUCCESS,
    JP_DEVELOPMENT_UPDATE_FAIL,
  } from "../constants/jpDevelopmentConstants";
  import * as api from "../api/index";
  
  export const listJpDevelopment = () => async (dispatch) => {
    try {
      dispatch({ type: JP_DEVELOPMENT_LIST_REQUEST });
  
      const { data } = await api.listJpDevelopmentAPI();
  
      dispatch({
        type: JP_DEVELOPMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JP_DEVELOPMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getJpDevelopmentById = (id) => async (dispatch) => {   
    try {
      dispatch({ type: JP_DEVELOPMENT_DETAILS_REQUEST });
  
      const { data } = await api.JpDevelopmentByIdAPI(id);
      dispatch({
        type: JP_DEVELOPMENT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JP_DEVELOPMENT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const jpDevelopmentRegister = (jpDevelopmentName,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: JP_DEVELOPMENT_REGISTER_REQUEST,
      });
      const { data } = await api.JpDevelopmentRegisterAPI(jpDevelopmentName);
  
      dispatch({
        type: JP_DEVELOPMENT_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: JP_DEVELOPMENT_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/jpdevelopments");
    } catch (error) {
      dispatch({
        type: JP_DEVELOPMENT_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deleteJpDevelopment = (id) => async (dispatch) => {
    try {
      dispatch({
        type: JP_DEVELOPMENT_DELETE_REQUEST,
      });
  
      await api.JpDevelopmentDeleteAPI(id);
  
      dispatch({
        type: JP_DEVELOPMENT_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: JP_DEVELOPMENT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateJpDevelopment = (jpDevelopment,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: JP_DEVELOPMENT_UPDATE_REQUEST,
      });

      const { data } = await api.JpDevelopmentUpdateAPI(jpDevelopment);

      dispatch({
        type: JP_DEVELOPMENT_UPDATE_SUCCESS,
      });

      dispatch({
        type: JP_DEVELOPMENT_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/jpdevelopments");
    } catch (error) {
      dispatch({
        type: JP_DEVELOPMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchJpDevelopment = (jpDevelopmentName) => async (dispatch) => {
  try {
    dispatch({ type: JP_DEVELOPMENT_LIST_REQUEST });

    const { data } = await api.JpDevelopmentSearchAPI(jpDevelopmentName);

    dispatch({
      type: JP_DEVELOPMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JP_DEVELOPMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
