export const JP_DEVELOPMENT_LIST_REQUEST = "JP_DEVELOPMENT_LIST_REQUEST";
export const JP_DEVELOPMENT_LIST_SUCCESS = "JP_DEVELOPMENT_LIST_SUCCESS";
export const JP_DEVELOPMENT_LIST_FAIL = "JP_DEVELOPMENT_LIST_FAIL";

export const JP_DEVELOPMENT_DETAILS_REQUEST = "JP_DEVELOPMENT_DETAILS_REQUEST";
export const JP_DEVELOPMENT_DETAILS_SUCCESS = "JP_DEVELOPMENT_DETAILS_SUCCESS";
export const JP_DEVELOPMENT_DETAILS_FAIL = "JP_DEVELOPMENT_DETAILS_FAIL";

export const JP_DEVELOPMENT_REGISTER_REQUEST = "JP_DEVELOPMENT_REGISTER_REQUEST";
export const JP_DEVELOPMENT_REGISTER_SUCCESS = "JP_DEVELOPMENT_REGISTER_SUCCESS";
export const JP_DEVELOPMENT_REGISTER_FAIL = "JP_DEVELOPMENT_REGISTER_FAIL";

export const JP_DEVELOPMENT_DELETE_REQUEST = "JP_DEVELOPMENT_DELETE_REQUEST";
export const JP_DEVELOPMENT_DELETE_SUCCESS = "JP_DEVELOPMENT_DELETE_SUCCESS";
export const JP_DEVELOPMENT_DELETE_FAIL = "JP_DEVELOPMENT_DELETE_FAIL";

export const JP_DEVELOPMENT_UPDATE_REQUEST = "JP_DEVELOPMENT_UPDATE_REQUEST";
export const JP_DEVELOPMENT_UPDATE_SUCCESS = "JP_DEVELOPMENT_UPDATE_SUCCESS";
export const JP_DEVELOPMENT_UPDATE_FAIL = "JP_DEVELOPMENT_UPDATE_FAIL";
export const JP_DEVELOPMENT_UPDATE_RESET = "JP_DEVELOPMENT_UPDATE_RESET";
