import {
  LABOUR_CATEGORY_LIST_REQUEST,
  LABOUR_CATEGORY_LIST_SUCCESS,
  LABOUR_CATEGORY_LIST_FAIL,
  LABOUR_CATEGORY_DETAILS_REQUEST,
  LABOUR_CATEGORY_DETAILS_SUCCESS,
  LABOUR_CATEGORY_DETAILS_FAIL,
  LABOUR_CATEGORY_REGISTER_REQUEST,
  LABOUR_CATEGORY_REGISTER_SUCCESS,
  LABOUR_CATEGORY_REGISTER_FAIL,
  LABOUR_CATEGORY_DELETE_REQUEST,
  LABOUR_CATEGORY_DELETE_SUCCESS,
  LABOUR_CATEGORY_DELETE_FAIL,
  LABOUR_CATEGORY_UPDATE_REQUEST,
  LABOUR_CATEGORY_UPDATE_SUCCESS,
  LABOUR_CATEGORY_UPDATE_FAIL,
} from "../constants/labourCategoryConstants";
import * as api from "../api/index";

export const listLabourCategory = () => async (dispatch) => {
  try {
    dispatch({ type: LABOUR_CATEGORY_LIST_REQUEST });

    const { data } = await api.listLabourCategoryAPI();

    dispatch({
      type: LABOUR_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getLabourCategoryById = (id) => async (dispatch) => {
  try {
    dispatch({ type: LABOUR_CATEGORY_DETAILS_REQUEST });

    const { data } = await api.LabourCategoryByIdAPI(id);
    dispatch({
      type: LABOUR_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const LabourCategoryRegister = (labourcategory,navigate) => async (dispatch) => {
  try {
    dispatch({
      type: LABOUR_CATEGORY_REGISTER_REQUEST,
    });
    const { data } = await api.LabourCategoryRegisterAPI(labourcategory);

    dispatch({
      type: LABOUR_CATEGORY_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LABOUR_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/labourcategory")
  } catch (error) {
    dispatch({
      type: LABOUR_CATEGORY_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteLabourCategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LABOUR_CATEGORY_DELETE_REQUEST,
    });

    await api.LabourCategoryDeleteAPI(id);

    dispatch({
      type: LABOUR_CATEGORY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LABOUR_CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateLabourCategory = (labourcategory,navigate) => async (dispatch) => {
  try {
    dispatch({
      type: LABOUR_CATEGORY_UPDATE_REQUEST,
    });

    const { data } = await api.LabourCategoryUpdateAPI(labourcategory);

    dispatch({
      type: LABOUR_CATEGORY_UPDATE_SUCCESS,
    });

    dispatch({
      type: LABOUR_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/labourcategory")
  } catch (error) {
    dispatch({
      type: LABOUR_CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchLabourCategory =
  (labourCategoryName) => async (dispatch) => {
    try {
      dispatch({ type: LABOUR_CATEGORY_LIST_REQUEST });

      const { data } = await api.LabourCategorySearchAPI(labourCategoryName);

      dispatch({
        type: LABOUR_CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LABOUR_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
