import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RevomeRoute } from "../redux/actions/routeActions";
import MFGrid from "../Components/MFGrid";

function HomeScreen() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RevomeRoute());
  }, [dispatch]);
  return (
    <div className="specificElement">
      <MFGrid />
    </div>
  );
}

export default HomeScreen;
