import {
    OTHER_CATEGORY_LIST_REQUEST,
    OTHER_CATEGORY_LIST_SUCCESS,
    OTHER_CATEGORY_LIST_FAIL,
    OTHER_CATEGORY_DETAILS_REQUEST,
    OTHER_CATEGORY_DETAILS_SUCCESS,
    OTHER_CATEGORY_DETAILS_FAIL,
    OTHER_CATEGORY_REGISTER_REQUEST,
    OTHER_CATEGORY_REGISTER_SUCCESS,
    OTHER_CATEGORY_REGISTER_FAIL,
    OTHER_CATEGORY_DELETE_REQUEST,
    OTHER_CATEGORY_DELETE_SUCCESS,
    OTHER_CATEGORY_DELETE_FAIL,
    OTHER_CATEGORY_UPDATE_REQUEST,
    OTHER_CATEGORY_UPDATE_SUCCESS,
    OTHER_CATEGORY_UPDATE_FAIL,
    OTHER_CATEGORY_UPDATE_RESET,
  } from "../constants/otherCategoryConstants";
  
  export const otherCategorysListReducer = (state = { otherCategorys: [] }, action) => {
    switch (action.type) {
      case OTHER_CATEGORY_LIST_REQUEST:
        return { loading: true, otherCategorys: [] };
      case OTHER_CATEGORY_LIST_SUCCESS:
        return { loading: false, otherCategorys: action.payload };
      case OTHER_CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCategoryByIdReducer = (state = { otherCategory: [] }, action) => {
    switch (action.type) {
      case OTHER_CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case OTHER_CATEGORY_DETAILS_SUCCESS:
        return { loading: false, otherCategory: action.payload };
      case OTHER_CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCategoryRegisterReducer = (state = { otherCategory: [] }, action) => {
    switch (action.type) {
      case OTHER_CATEGORY_REGISTER_REQUEST:
        return { loading: true, otherCategory: [] };
      case OTHER_CATEGORY_REGISTER_SUCCESS:
        return { loading: false, otherCategoryInfo: action.payload };
      case OTHER_CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case OTHER_CATEGORY_DELETE_REQUEST:
        return { loading: true, otherCategory: [] };
      case OTHER_CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case OTHER_CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCategoryUpdateReducer = (state = { otherCategory: {} }, action) => {
    switch (action.type) {
      case OTHER_CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case OTHER_CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case OTHER_CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case OTHER_CATEGORY_UPDATE_RESET:
        return { otherCategory: {} };
      default:
        return state;
    }
  };
  