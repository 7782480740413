import {
    STATUS_LIST_REQUEST,
    STATUS_LIST_SUCCESS,
    STATUS_LIST_FAIL,
    STATUS_DETAILS_REQUEST,
    STATUS_DETAILS_SUCCESS,
    STATUS_DETAILS_FAIL,
    STATUS_REGISTER_REQUEST,
    STATUS_REGISTER_SUCCESS,
    STATUS_REGISTER_FAIL,
    STATUS_DELETE_REQUEST,
    STATUS_DELETE_SUCCESS,
    STATUS_DELETE_FAIL,
    STATUS_UPDATE_REQUEST,
    STATUS_UPDATE_SUCCESS,
    STATUS_UPDATE_FAIL,
  } from "../constants/statusConstants";
  import * as api from "../api/index";
  
  export const listStatus = () => async (dispatch) => {
    try {
      dispatch({ type: STATUS_LIST_REQUEST });
  
      const { data } = await api.listStatusAPI();
  
      dispatch({
        type: STATUS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STATUS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getStatusById = (id) => async (dispatch) => {
    try {
      dispatch({ type: STATUS_DETAILS_REQUEST });
  
      const { data } = await api.StatusByIdAPI(id);
      dispatch({
        type: STATUS_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STATUS_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const statusRegister = (status,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: STATUS_REGISTER_REQUEST,
      });
      const { data } = await api.StatusRegisterAPI(status);
  
      dispatch({
        type: STATUS_REGISTER_SUCCESS,
        payload: data,
      });
  
      dispatch({
        type: STATUS_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/status")
    } catch (error) {
      dispatch({
        type: STATUS_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deleteStatus = (id) => async (dispatch) => {
    try {
      dispatch({
        type: STATUS_DELETE_REQUEST,
      });
  
      await api.StatusDeleteAPI(id);
  
      dispatch({
        type: STATUS_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: STATUS_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateStatus = (status,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: STATUS_UPDATE_REQUEST,
      });
  
      const { data } = await api.StatusUpdateAPI(status);
  
      dispatch({
        type: STATUS_UPDATE_SUCCESS,
      });
  
      dispatch({
        type: STATUS_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/status")
    } catch (error) {
      dispatch({
        type: STATUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const searchStatus = (statusName) => async (dispatch) => {
    try {
      dispatch({ type: STATUS_LIST_REQUEST });
  
      const { data } = await api.StatusSearchAPI(statusName);
  
      dispatch({
        type: STATUS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STATUS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  