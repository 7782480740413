import {
    LABOUR_CATEGORY_LIST_REQUEST,
    LABOUR_CATEGORY_LIST_SUCCESS,
    LABOUR_CATEGORY_LIST_FAIL,
    LABOUR_CATEGORY_DETAILS_REQUEST,
    LABOUR_CATEGORY_DETAILS_SUCCESS,
    LABOUR_CATEGORY_DETAILS_FAIL,
    LABOUR_CATEGORY_REGISTER_REQUEST,
    LABOUR_CATEGORY_REGISTER_SUCCESS,
    LABOUR_CATEGORY_REGISTER_FAIL,
    LABOUR_CATEGORY_DELETE_REQUEST,
    LABOUR_CATEGORY_DELETE_SUCCESS,
    LABOUR_CATEGORY_DELETE_FAIL,
    LABOUR_CATEGORY_UPDATE_REQUEST,
    LABOUR_CATEGORY_UPDATE_SUCCESS,
    LABOUR_CATEGORY_UPDATE_FAIL,
    LABOUR_CATEGORY_UPDATE_RESET,
  } from "../constants/labourCategoryConstants";
  
  export const labourCategorysListReducer = (state = { labourCategorys: [] }, action) => {
    switch (action.type) {
      case LABOUR_CATEGORY_LIST_REQUEST:
        return { loading: true, labourCategorys: [] };
      case LABOUR_CATEGORY_LIST_SUCCESS:
        return { loading: false, labourCategorys: action.payload };
      case LABOUR_CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    } 
  };
  
  export const labourCategoryByIdReducer = (state = { labourCategoryById: [] }, action) => {
    switch (action.type) {
      case LABOUR_CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case LABOUR_CATEGORY_DETAILS_SUCCESS:
        return { loading: false, labourCategoryById: action.payload };
      case LABOUR_CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCategoryRegisterReducer = (state = { labourCategory: [] }, action) => {
    switch (action.type) {
      case LABOUR_CATEGORY_REGISTER_REQUEST:
        return { loading: true, labourCategory: [] };
      case LABOUR_CATEGORY_REGISTER_SUCCESS:
        return { loading: false, labourCategoryInfo: action.payload };
      case LABOUR_CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LABOUR_CATEGORY_DELETE_REQUEST:
        return { loading: true, labourCategory: [] };
      case LABOUR_CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case LABOUR_CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const labourCategoryUpdateReducer = (state = { labourCategory: {} }, action) => {
    switch (action.type) {
      case LABOUR_CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case LABOUR_CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case LABOUR_CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case LABOUR_CATEGORY_UPDATE_RESET:
        return { labourCategory: {} };
      default:
        return state;
    }
  };
  