import {
    STATUS_LIST_REQUEST,
    STATUS_LIST_SUCCESS,
    STATUS_LIST_FAIL,
    STATUS_DETAILS_REQUEST,
    STATUS_DETAILS_SUCCESS,
    STATUS_DETAILS_FAIL,
    STATUS_REGISTER_REQUEST,
    STATUS_REGISTER_SUCCESS,
    STATUS_REGISTER_FAIL,
    STATUS_DELETE_REQUEST,
    STATUS_DELETE_SUCCESS,
    STATUS_DELETE_FAIL,
    STATUS_UPDATE_REQUEST,
    STATUS_UPDATE_SUCCESS,
    STATUS_UPDATE_FAIL,
    STATUS_UPDATE_RESET,
  } from "../constants/statusConstants";
  
  export const statusListReducer = (state = { statuses: [] }, action) => {
    switch (action.type) {
      case STATUS_LIST_REQUEST:
        return { loading: true, status: [] };
      case STATUS_LIST_SUCCESS:
        return { loading: false, statuses: action.payload };
      case STATUS_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const statusByIdReducer = (state = { statusById: [] }, action) => {
    switch (action.type) {
      case STATUS_DETAILS_REQUEST:
        return { loading: true, ...state };
      case STATUS_DETAILS_SUCCESS:
        return { loading: false, statusById: action.payload };
      case STATUS_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const statusRegisterReducer = (state = { status: [] }, action) => {
    switch (action.type) {
      case STATUS_REGISTER_REQUEST:
        return { loading: true, status: [] };
      case STATUS_REGISTER_SUCCESS:
        return { loading: false, statusInfo: action.payload };
      case STATUS_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const statusDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case STATUS_DELETE_REQUEST:
        return { loading: true, status: [] };
      case STATUS_DELETE_SUCCESS:
        return { loading: false, success: true };
      case STATUS_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const statusUpdateReducer = (state = { status: {} }, action) => {
    switch (action.type) {
      case STATUS_UPDATE_REQUEST:
        return { loading: true };
      case STATUS_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case STATUS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case STATUS_UPDATE_RESET:
        return { status: {} };
      default:
        return state;
    }
  };
  