import {
    OTHER_COST_LIST_REQUEST,
    OTHER_COST_LIST_SUCCESS,
    OTHER_COST_LIST_FAIL,
    OTHER_COST_DETAILS_REQUEST,
    OTHER_COST_DETAILS_SUCCESS,
    OTHER_COST_DETAILS_FAIL,
    OTHER_COST_REGISTER_REQUEST,
    OTHER_COST_REGISTER_SUCCESS,
    OTHER_COST_REGISTER_FAIL,
    OTHER_COST_DELETE_REQUEST,
    OTHER_COST_DELETE_SUCCESS,
    OTHER_COST_DELETE_FAIL,
    OTHER_COST_UPDATE_REQUEST,
    OTHER_COST_UPDATE_SUCCESS,
    OTHER_COST_UPDATE_FAIL,
    OTHER_COST_UPDATE_RESET,
  } from "../constants/otherCostConstants";
  
  export const otherCostListReducer = (state = { otherCosts: [] }, action) => {
    switch (action.type) {
      case OTHER_COST_LIST_REQUEST:
        return { loading: true, otherCosts: [] };
      case OTHER_COST_LIST_SUCCESS:
        return { loading: false, otherCosts: action.payload };
      case OTHER_COST_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCostByIdReducer = (state = { otherCost: [] }, action) => {
    switch (action.type) {
      case OTHER_COST_DETAILS_REQUEST:
        return { loading: true, ...state };
      case OTHER_COST_DETAILS_SUCCESS:
        return { loading: false, otherCost: action.payload };
      case OTHER_COST_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCostRegisterReducer = (state = { otherCost: [] }, action) => {
    switch (action.type) {
      case OTHER_COST_REGISTER_REQUEST:
        return { loading: true, otherCost: [] };
      case OTHER_COST_REGISTER_SUCCESS:
        return { loading: false, otherCost: action.payload };
      case OTHER_COST_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCostDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case OTHER_COST_DELETE_REQUEST:
        return { loading: true, otherCost: [] };
      case OTHER_COST_DELETE_SUCCESS:
        return { loading: false, success: true };
      case OTHER_COST_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const otherCostUpdateReducer = (state = { otherCost: {} }, action) => {
    switch (action.type) {
      case OTHER_COST_UPDATE_REQUEST:
        return { loading: true };
      case OTHER_COST_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case OTHER_COST_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case OTHER_COST_UPDATE_RESET:
        return { otherCost: {} };
      default:
        return state;
    }
  };
  