import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getJpDevelopmentById,
  updateJpDevelopment,
} from "../../redux/actions/jpDevelopmentActons";
import { JP_DEVELOPMENT_UPDATE_RESET } from "../../redux/constants/jpDevelopmentConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function JpDevelopmentEditScreen({ history }) {
  const jpDevelopmentId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [jpDevelopmentName, setJpDevelopmentName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.jpDevelopmentById);
  const { loading, error, jpDevelopment } = Details;

  const update = useSelector((state) => state.userUpdateProfile);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Jp Development Edit"));
    if (successUpdate) {
      dispatch({ type: JP_DEVELOPMENT_UPDATE_RESET });
    } else {
      if (
        !jpDevelopment.JpDevelopmentName ||
        jpDevelopment._id !== jpDevelopmentId.id
      ) {
        dispatch(getJpDevelopmentById(jpDevelopmentId.id, navigate));
      } else {
        setId(jpDevelopment._id);
        setJpDevelopmentName(jpDevelopment.JpDevelopmentName);
      }
    }
  }, [dispatch, successUpdate, jpDevelopmentId.id, jpDevelopment, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (jpDevelopmentName === "") {
      setMessage("Jp Development Name Is Empty");
    } else {
      dispatch(
        updateJpDevelopment(
          {
            _id: id,
            name: jpDevelopmentName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="JpDevelopmentName"
                name="jpDevelopmentName"
                value={jpDevelopmentName}
                onChange={(e) => setJpDevelopmentName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default JpDevelopmentEditScreen;
