export const STATUS_LIST_REQUEST = "STATUS_LIST_REQUEST";
export const STATUS_LIST_SUCCESS = "STATUS_LIST_SUCCESS";
export const STATUS_LIST_FAIL = "STATUS_LIST_FAIL";

export const STATUS_DETAILS_REQUEST = "STATUS_DETAILS_REQUEST";
export const STATUS_DETAILS_SUCCESS = "STATUS_DETAILS_SUCCESS";
export const STATUS_DETAILS_FAIL = "STATUS_DETAILS_FAIL";

export const STATUS_REGISTER_REQUEST = "STATUS_REGISTER_REQUEST";
export const STATUS_REGISTER_SUCCESS = "STATUS_REGISTER_SUCCESS";
export const STATUS_REGISTER_FAIL = "STATUS_REGISTER_FAIL";

export const STATUS_DELETE_REQUEST = "STATUS_DELETE_REQUEST";
export const STATUS_DELETE_SUCCESS = "STATUS_DELETE_SUCCESS";
export const STATUS_DELETE_FAIL = "STATUS_DELETE_FAIL";

export const STATUS_UPDATE_REQUEST = "STATUS_UPDATE_REQUEST";
export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_FAIL = "STATUS_UPDATE_FAIL";
export const STATUS_UPDATE_RESET = "STATUS_UPDATE_RESET";
