import {
    SL_OUTSOURCE_CATEGORY_LIST_REQUEST,
    SL_OUTSOURCE_CATEGORY_LIST_SUCCESS,
    SL_OUTSOURCE_CATEGORY_LIST_FAIL,
    SL_OUTSOURCE_CATEGORY_DETAILS_REQUEST,
    SL_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
    SL_OUTSOURCE_CATEGORY_DETAILS_FAIL,
    SL_OUTSOURCE_CATEGORY_REGISTER_REQUEST,
    SL_OUTSOURCE_CATEGORY_REGISTER_SUCCESS,
    SL_OUTSOURCE_CATEGORY_REGISTER_FAIL,
    SL_OUTSOURCE_CATEGORY_DELETE_REQUEST,
    SL_OUTSOURCE_CATEGORY_DELETE_SUCCESS,
    SL_OUTSOURCE_CATEGORY_DELETE_FAIL,
    SL_OUTSOURCE_CATEGORY_UPDATE_REQUEST,
    SL_OUTSOURCE_CATEGORY_UPDATE_SUCCESS,
    SL_OUTSOURCE_CATEGORY_UPDATE_FAIL,
    SL_OUTSOURCE_CATEGORY_UPDATE_RESET,
} from "../constants/slOutSourceCategoryConstants";
  
  export const slOutSourceCategoryListReducer = (state = { slOutSourceCategorys: [] }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_CATEGORY_LIST_REQUEST:
        return { loading: true, slOutSourceCategorys: [] };
      case SL_OUTSOURCE_CATEGORY_LIST_SUCCESS:
        return { loading: false, slOutSourceCategorys: action.payload };
      case SL_OUTSOURCE_CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    } 
  };
  
  export const slOutSourceCategoryByIdReducer = (state = { slOutSourceCategoryById: [] }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case SL_OUTSOURCE_CATEGORY_DETAILS_SUCCESS:
        return { loading: false, slOutSourceCategoryById: action.payload };
      case SL_OUTSOURCE_CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slOutSourceCategoryRegisterReducer = (state = { slOutSourceCategory: [] }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_CATEGORY_REGISTER_REQUEST:
        return { loading: true, slOutSourceCategory: [] };
      case SL_OUTSOURCE_CATEGORY_REGISTER_SUCCESS:
        return { loading: false, slOutSourceCategoryInfo: action.payload };
      case SL_OUTSOURCE_CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slOutSourceCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_CATEGORY_DELETE_REQUEST:
        return { loading: true, slOutSourceCategory: [] };
      case SL_OUTSOURCE_CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SL_OUTSOURCE_CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const slOutSourceCategoryUpdateReducer = (state = { slOutSourceCategory: {} }, action) => {
    switch (action.type) {
      case SL_OUTSOURCE_CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case SL_OUTSOURCE_CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case SL_OUTSOURCE_CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case SL_OUTSOURCE_CATEGORY_UPDATE_RESET:
        return { slOutSourceCategory: {} };
      default:
        return state;
    }
  };
  