import {
  SL_OUTSOURCE_CATEGORY_LIST_REQUEST,
  SL_OUTSOURCE_CATEGORY_LIST_SUCCESS,
  SL_OUTSOURCE_CATEGORY_LIST_FAIL,
  SL_OUTSOURCE_CATEGORY_DETAILS_REQUEST,
  SL_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
  SL_OUTSOURCE_CATEGORY_DETAILS_FAIL,
  SL_OUTSOURCE_CATEGORY_REGISTER_REQUEST,
  SL_OUTSOURCE_CATEGORY_REGISTER_SUCCESS,
  SL_OUTSOURCE_CATEGORY_REGISTER_FAIL,
  SL_OUTSOURCE_CATEGORY_DELETE_REQUEST,
  SL_OUTSOURCE_CATEGORY_DELETE_SUCCESS,
  SL_OUTSOURCE_CATEGORY_DELETE_FAIL,
  SL_OUTSOURCE_CATEGORY_UPDATE_REQUEST,
  SL_OUTSOURCE_CATEGORY_UPDATE_SUCCESS,
  SL_OUTSOURCE_CATEGORY_UPDATE_FAIL,
} from "../constants/slOutSourceCategoryConstants";
import * as api from "../api/index";

export const listSlOutSourceCategory = () => async (dispatch) => {
  try {
    dispatch({ type: SL_OUTSOURCE_CATEGORY_LIST_REQUEST });

    const { data } = await api.listSlOutSourceCategoryAPI();

    dispatch({
      type: SL_OUTSOURCE_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_OUTSOURCE_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSlOutSourceCategoryById = (id) => async (dispatch) => {
  try {
    dispatch({ type: SL_OUTSOURCE_CATEGORY_DETAILS_REQUEST });

    const { data } = await api.SlOutSourceCategoryByIdsAPI(id);
    dispatch({
      type: SL_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_OUTSOURCE_CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const SlOutSourceCategoryRegister =
  (sloutsourcecategory, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SL_OUTSOURCE_CATEGORY_REGISTER_REQUEST,
      });
      const { data } = await api.SlOutSourceCategoryRegisterAPI(
        sloutsourcecategory
      );

      dispatch({
        type: SL_OUTSOURCE_CATEGORY_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SL_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/sloutsourcecategory");
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_CATEGORY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSlOutSourcCategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SL_OUTSOURCE_CATEGORY_DELETE_REQUEST,
    });

    await api.SlOutSourceCategoryDeleteAPI(id);

    dispatch({
      type: SL_OUTSOURCE_CATEGORY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SL_OUTSOURCE_CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSlOutSourceCategory =
  (sloutsourcecategory, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SL_OUTSOURCE_CATEGORY_UPDATE_REQUEST,
      });

      const { data } = await api.SlOutSourceCategoryUpdateAPI(
        sloutsourcecategory
      );

      dispatch({
        type: SL_OUTSOURCE_CATEGORY_UPDATE_SUCCESS,
      });

      dispatch({
        type: SL_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/sloutsourcecategory");
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_CATEGORY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchSlOutSourceCategory =
  (sloutsourcecategoryName) => async (dispatch) => {
    try {
      dispatch({ type: SL_OUTSOURCE_CATEGORY_LIST_REQUEST });

      const { data } = await api.SlOutSourceCategorySearchAPI(
        sloutsourcecategoryName
      );

      dispatch({
        type: SL_OUTSOURCE_CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
