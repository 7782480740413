import {
    JP_DEVELOPMENT_LIST_REQUEST,
    JP_DEVELOPMENT_LIST_SUCCESS,
    JP_DEVELOPMENT_LIST_FAIL,
    JP_DEVELOPMENT_DETAILS_REQUEST,
    JP_DEVELOPMENT_DETAILS_SUCCESS,
    JP_DEVELOPMENT_DETAILS_FAIL,
    JP_DEVELOPMENT_REGISTER_REQUEST,
    JP_DEVELOPMENT_REGISTER_SUCCESS,
    JP_DEVELOPMENT_REGISTER_FAIL,
    JP_DEVELOPMENT_DELETE_REQUEST,
    JP_DEVELOPMENT_DELETE_SUCCESS,
    JP_DEVELOPMENT_DELETE_FAIL,
    JP_DEVELOPMENT_UPDATE_REQUEST,
    JP_DEVELOPMENT_UPDATE_SUCCESS,
    JP_DEVELOPMENT_UPDATE_FAIL,
    JP_DEVELOPMENT_UPDATE_RESET,
  } from "../constants/jpDevelopmentConstants";
  
  export const jpDevelopmentListReducer = (state = { jpDevelopments: [] }, action) => {
    switch (action.type) {
      case JP_DEVELOPMENT_LIST_REQUEST:
        return { loading: true, jpDevelopments: [] };
      case JP_DEVELOPMENT_LIST_SUCCESS:
        return { loading: false, jpDevelopments: action.payload };
      case JP_DEVELOPMENT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpDevelopmentByIdReducer = (state = { jpDevelopment: [] }, action) => {
    switch (action.type) {
      case JP_DEVELOPMENT_DETAILS_REQUEST:
        return { loading: true, ...state };
      case JP_DEVELOPMENT_DETAILS_SUCCESS:
        return { loading: false, jpDevelopment: action.payload };
      case JP_DEVELOPMENT_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpDevelopmentRegisterReducer = (state = { jpDevelopment: [] }, action) => {
    switch (action.type) {
      case JP_DEVELOPMENT_REGISTER_REQUEST:
        return { loading: true, jpDevelopment: [] };
      case JP_DEVELOPMENT_REGISTER_SUCCESS:
        return { loading: false, jpDevelopmentInfo: action.payload };
      case JP_DEVELOPMENT_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpDevelopmentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case JP_DEVELOPMENT_DELETE_REQUEST:
        return { loading: true, jpDevelopment: [] };
      case JP_DEVELOPMENT_DELETE_SUCCESS:
        return { loading: false, success: true };
      case JP_DEVELOPMENT_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpDevelopmentUpdateReducer = (state = { jpDevelopment: {} }, action) => {
    switch (action.type) {
      case JP_DEVELOPMENT_UPDATE_REQUEST:
        return { loading: true };
      case JP_DEVELOPMENT_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case JP_DEVELOPMENT_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case JP_DEVELOPMENT_UPDATE_RESET:
        return { jpDevelopment: {} };
      default:
        return state;
    }
  };
  