import {
  SL_OUTSOURCE_COMPANY_LIST_REQUEST,
  SL_OUTSOURCE_COMPANY_LIST_SUCCESS,
  SL_OUTSOURCE_COMPANY_LIST_FAIL,
  SL_OUTSOURCE_COMPANY_DETAILS_REQUEST,
  SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS,
  SL_OUTSOURCE_COMPANY_DETAILS_FAIL,
  SL_OUTSOURCE_COMPANY_REGISTER_REQUEST,
  SL_OUTSOURCE_COMPANY_REGISTER_SUCCESS,
  SL_OUTSOURCE_COMPANY_REGISTER_FAIL,
  SL_OUTSOURCE_COMPANY_DELETE_REQUEST,
  SL_OUTSOURCE_COMPANY_DELETE_SUCCESS,
  SL_OUTSOURCE_COMPANY_DELETE_FAIL,
  SL_OUTSOURCE_COMPANY_UPDATE_REQUEST,
  SL_OUTSOURCE_COMPANY_UPDATE_SUCCESS,
  SL_OUTSOURCE_COMPANY_UPDATE_FAIL,
  SL_OUTSOURCE_COMPANY_UPDATE_RESET,
} from "../constants/slOutSourceCompanyConstants";

export const slOutSourceCompanyListReducer = (
  state = { slOutSourceCompanys: [] },
  action
) => {
  switch (action.type) {
    case SL_OUTSOURCE_COMPANY_LIST_REQUEST:
      return { loading: true, slOutSourceCompanys: [] };
    case SL_OUTSOURCE_COMPANY_LIST_SUCCESS:
      return { loading: false, slOutSourceCompanys: action.payload };
    case SL_OUTSOURCE_COMPANY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const slOutSourceCompanyByIdReducer = (
  state = { slOutSourceCompanyById: [] },
  action
) => {
  switch (action.type) {
    case SL_OUTSOURCE_COMPANY_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS:
      return { loading: false, slOutSourceCompanyById: action.payload };
    case SL_OUTSOURCE_COMPANY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const slOutSourceCompanyRegisterReducer = (
  state = { slOutSourceCompany: [] },
  action
) => {
  switch (action.type) {
    case SL_OUTSOURCE_COMPANY_REGISTER_REQUEST:
      return { loading: true, slOutSourceCompany: [] };
    case SL_OUTSOURCE_COMPANY_REGISTER_SUCCESS:
      return { loading: false, slOutSourceCompanyInfo: action.payload };
    case SL_OUTSOURCE_COMPANY_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const slOutSourceCompanyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SL_OUTSOURCE_COMPANY_DELETE_REQUEST:
      return { loading: true, slOutSourceCompany: [] };
    case SL_OUTSOURCE_COMPANY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SL_OUTSOURCE_COMPANY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const slOutSourceCompanyUpdateReducer = (
  state = { slOutSourceCompany: {} },
  action
) => {
  switch (action.type) {
    case SL_OUTSOURCE_COMPANY_UPDATE_REQUEST:
      return { loading: true };
    case SL_OUTSOURCE_COMPANY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SL_OUTSOURCE_COMPANY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SL_OUTSOURCE_COMPANY_UPDATE_RESET:
      return { slOutSourceCompany: {} };
    default:
      return state;
  }
};
