import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProject } from "../redux/actions/projectActions";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "./MFGrid.css";

export default function MFGrid() {
  const dispatch = useDispatch();
  const projectsList = useSelector((state) => state.projectsList);
  const { projects } = projectsList;

  useEffect(() => {
    dispatch(listProject());
  }, [dispatch]);

  const baseColumnOptions = {
    sortable: false,
    pinnable: false,
    hideable: false,
  };

  const [sortModel, setSortModel] = React.useState([
    {
      field: "ProjectNo",
      sort: "desc",
    },
  ]);

  const columns = [
    { field: "ProjectNo", headerName: "ProjectNo", width: 80, sort: "desc" },
    {
      field: "ProjectName",
      headerName: "ProjectName",
      width: 150,
      renderCell: (params) => (
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={`/costs/${params.id}`}
        >
          {params.value}
        </Link>
      ),
    },
    { field: "JiraID", headerName: "JiraID", width: 120 },
    {
      field: "ProjectCategoryDetails",
      headerName: "ProjectCategory",
      width: 150,
      valueFormatter: (params) => params.value[0]?.ProjectCategoryName,
    },
    {
      field: "SalesStaffs",
      headerName: "SalesStaff",
      width: 150,
      valueFormatter: (params) =>
        params.value[0]?.SalesStaffName +
        " & " +
        params.value[1]?.SalesStaffName,
    },
    {
      field: "jpdevelopmentsDetails",
      headerName: "JpDevelopment",
      width: 150,
      valueFormatter: (params) =>
        params.value[0]?.JpDevelopmentName +
        " & " +
        params.value[1]?.JpDevelopmentName,
    },
    {
      field: "jpoutsourcesDetails",
      headerName: "JpOutsource",
      width: 300,
      valueFormatter: (params) =>
        params.value[0]?.JpOutsourceName +
        " & " +
        params.value[1]?.JpOutsourceName,
    },
    {
      field: "SlDevelopers",
      headerName: "SlDevelopers",
      width: 150,
      valueFormatter: (params) =>
        params.value[0]?.SlDeveloperName +
        " & " +
        params.value[1]?.SlDeveloperName,
    },
    {
      field: "StartDate",
      headerName: "StartDate",
      type: "date",
      width: 120,
      valueFormatter: (params) => params.value.split("T")[0],
    },
    {
      field: "FinishDate",
      headerName: "FinishDate",
      width: 120,
      valueFormatter: (params) => params.value.split("T")[0],
      type: "date",
    },
    {
      field: "othercosts",
      id: "othercosts",
      headerName: "Other Costs",
      width: 100,
      valueFormatter: (params) => params.value[0]?.Total,
    },
    {
      field: "jpoutsourcecosts",
      headerName: "JP Outsource Costs",
      width: 100,
      valueFormatter: (params) => params.value[0]?.Total,
    },
    {
      field: "sloutsourcecosts",
      headerName: "SL Outsource Costs",
      width: 100,
      hide: true,
      valueFormatter: (params) => params.value[0]?.Total,
    },
    {
      field: "labourcosts",
      headerName: "Labour Costs",
      width: 100,
      hide: true,
      valueFormatter: (params) => params.value[0]?.Total,
    },
    {
      field: "GRANDTOTAL",
      headerName: "Grand Total",
      width: 100,
      baseColumnOptions,
      valueGetter: ({ row }) => {
        if (row.id !== "GRANDTOTAL") {
          var jpcost = 0;
          var slcost = 0;
          var othercost = 0;
          var labourcost = 0;
          if (row.jpoutsourcecosts[0]?.Total >= 0) {
            jpcost = row.jpoutsourcecosts[0].Total;
          }
          if (row.othercosts[0]?.Total >= 0) {
            othercost = row.othercosts[0].Total;
          }
          if (row.labourcosts[0]?.Total >= 0) {
            labourcost = row.labourcosts[0].Total;
          }
          if (row.sloutsourcecosts[0]?.Total >= 0) {
            slcost = row.sloutsourcecosts[0].Total;
          }

          return jpcost + othercost + labourcost + slcost;
        } else {
          return (
            row.jpoutsourcecosts[0]?.Total +
            row.othercosts[0]?.Total +
            row.labourcosts[0]?.Total +
            row.sloutsourcecosts[0]?.Total
          );
        }
      },
    },
  ];

  const getCellClassName = ({ row }) => {
    if (row.id === "GRANDTOTAL") {
    }
  };

  return (
    <>
      <div className="bigsquare" id="_id">
        <DataGrid
          sx={{
            mt: 7,
            mr: 3,
            ml: 3,
            boxShadow: 15,
            border: 2,
            bgcolor: "#Ffffff",
            borderColor: "#Cbd2d2",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          id={projects._id}
          getRowId={(projects) => projects._id}
          rows={projects}
          getCellClassName={getCellClassName}
          columns={columns}
          localeText={{
            toolbarColumns: "列",
            columnsPanelTextFieldLabel: "列を検索",
            columnsPanelTextFieldPlaceholder: "列を検索",
            toolbarFilters: "フィルタ",
            toolbarDensity: "密度",
            toolbarDensityCompact: "コンパクト",
            toolbarDensityStandard: "スタンダー",
            toolbarDensityComfortable: "快適",
            toolbarExport: "書き出す",
            toolbarExportCSV: "ドキュメントをダウンロード",
            toolbarExportPrint: "快適...",
            columnsPanelShowAllButton: "すべて表示する",
            columnsPanelHideAllButton:"すべて非表示",
            filterPanelOperators:"オペレーター",
            filterPanelColumns:"桁",
            filterOperatorContains:"含む",
            filterOperatorEquals:"等しい",
            filterOperatorStartsWith:"皮切りに",
            filterOperatorEndsWith:"で終わる",
            filterOperatorIsEmpty:"空です",
            filterOperatorIsNotEmpty:"空ではありません",
            filterOperatorIsAnyOf:"のいずれかです",
            columnMenuShowColumns:"列を表示",
            columnMenuHideColumn:"列を非表示にする",
            columnMenuFilter:"フィルター",
            columnMenuUnsort:"並べ替えを解除",
            columnMenuSortAsc:"ソート昇順",
            columnMenuSortDesc:"降順で並べ替え",
            filterPanelInputLabel:"価値",
            filterPanelInputPlaceholder:"フィルタ値",
          }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
    </>
  );
}
