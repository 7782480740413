import {
  MASTER_LIST_REQUEST,
  MASTER_LIST_SUCCESS,
  MASTER_LIST_FAIL,
  TAB_LIST_REQUEST,
  TAB_LIST_SUCCESS,
  TAB_LIST_FAIL,
} from "../constants/masterConstants";
import * as api from "../api/index";

export const listMaster = () => async (dispatch) => {
  try {
    dispatch({ type: MASTER_LIST_REQUEST });

    const { data } = await api.listMasterAPI();

    dispatch({
      type: MASTER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MASTER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listTabs = () => async (dispatch) => {
  try {
    dispatch({ type: TAB_LIST_REQUEST });

    const { data } = await api.listTabAPI();

    dispatch({
      type: TAB_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TAB_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
