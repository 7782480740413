import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  listProjectCategory,
  deleteProjectCategory,
  getProjectCategoryById,
} from "../../redux/actions/projectCategoryActions";
import { SetRoute } from "../../redux/actions/routeActions";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Loader from "../../Components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function ProjectCategoryScreen() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const projectCategorysList = useSelector(
    (state) => state.projectCategorysList
  );
  const { loading, error, projectCategorys } = projectCategorysList;

  const projectDelete = useSelector((state) => state.projectCategoryDelete);
  const { success: successDelete } = projectDelete;

  const updateHandler = (id) => {
    dispatch(getProjectCategoryById(id));
  };

  const deleteHandler = () => {
    dispatch(deleteProjectCategory(deleteId));
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setId = (id) => {
    setDeleteId(id);
    handleOpen();
  };

  useEffect(() => {
    dispatch(listProjectCategory());
    dispatch(SetRoute("Project Category"));
  }, [dispatch, successDelete]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        <Container maxWidth="xl">
          {loading ? (
            <Loader />
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow></TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Project Category Name</b>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ margin: 10 }}
                      >
                        <Link
                          to="/projectcategory/Add"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Add New Project Category
                        </Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectCategorys.map((projectCategory) => (
                    <TableRow
                      key={projectCategory._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {projectCategory.ProjectCategoryName}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          size="small"
                          endIcon={<EditIcon />}
                          onClick={() => updateHandler(projectCategory._id)}
                          sx={{ mr: 1 }}
                        >
                          <Link
                            to={`${projectCategory._id}/edit`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            Edit
                          </Link>
                        </Button>
                        <Button
                          variant="outlined"
                          endIcon={<DeleteIcon />}
                          sx={{ mr: 1 }}
                          size="small"
                          onClick={() => setId(projectCategory._id)}
                        >
                          Delete
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="parent-modal-title"
                          aria-describedby="parent-modal-description"
                        >
                          <Box sx={{ ...style, width: 400 }}>
                            <h2 id="parent-modal-title">Confirm Delete</h2>
                            <p id="parent-modal-description">Are You Sure?</p>

                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              size="small"
                              onClick={deleteHandler}
                            >
                              Yes
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              size="small"
                              onClick={(e) => handleClose()}
                            >
                              No
                            </Button>
                          </Box>
                        </Modal>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
