import React from "react";
import Alert from "@mui/material/Alert";

const Message = ({ variant, severity, children }) => {
  return (
    <Alert
      sx={{ width: "94%" }}
      spacing={2}
      variant={variant}
      severity={severity}
    >
      {children}
    </Alert>
  );
};

Message.defaultProps = {
  variant: "outlined",
  severity: "error",
};

export default Message;
