import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getOtherCategoryById,
  updateOtherCategory,
} from "../../redux/actions/otherCategoryActions";
import { SetRoute } from "../../redux/actions/routeActions";
import { OTHER_CATEGORY_UPDATE_RESET } from "../../redux/constants/otherCategoryConstants";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function OtherCategoryEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otherCategoryId = useParams();

  const [id, setId] = useState("");
  const [otherCategoryName, setOtherCategoryName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.otherCategoryById);
  const { loading, error, otherCategory } = Details;

  const update = useSelector((state) => state.otherCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Other Category Edit"));
    if (successUpdate) {
      dispatch({ type: OTHER_CATEGORY_UPDATE_RESET });
      navigate("/otherCategory");
    } else {
      if (
        !otherCategory.OtherCategoryName ||
        otherCategory._id !== otherCategoryId.id
      ) {
        dispatch(getOtherCategoryById(otherCategoryId.id));
      } else {
        setId(otherCategory._id);
        setOtherCategoryName(otherCategory.OtherCategoryName);
      }
    }
  }, [dispatch, successUpdate, otherCategory, otherCategoryId, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (otherCategoryName === "") {
      setMessage("Other Category Name Is Empty");
    } else {
      dispatch(
        updateOtherCategory(
          {
            _id: id,
            name: otherCategoryName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="Other Category Name"
                name="OtherCategoryName"
                value={otherCategoryName}
                onChange={(e) => setOtherCategoryName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default OtherCategoryEditScreen;
