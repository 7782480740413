import {
    JP_OUTSOURCE_CATEGORY_LIST_REQUEST,
    JP_OUTSOURCE_CATEGORY_LIST_SUCCESS,
    JP_OUTSOURCE_CATEGORY_LIST_FAIL,
    JP_OUTSOURCE_CATEGORY_DETAILS_REQUEST,
    JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
    JP_OUTSOURCE_CATEGORY_DETAILS_FAIL,
    JP_OUTSOURCE_CATEGORY_REGISTER_REQUEST,
    JP_OUTSOURCE_CATEGORY_REGISTER_SUCCESS,
    JP_OUTSOURCE_CATEGORY_REGISTER_FAIL,
    JP_OUTSOURCE_CATEGORY_DELETE_REQUEST,
    JP_OUTSOURCE_CATEGORY_DELETE_SUCCESS,
    JP_OUTSOURCE_CATEGORY_DELETE_FAIL,
    JP_OUTSOURCE_CATEGORY_UPDATE_REQUEST,
    JP_OUTSOURCE_CATEGORY_UPDATE_SUCCESS,
    JP_OUTSOURCE_CATEGORY_UPDATE_FAIL,
    JP_OUTSOURCE_CATEGORY_UPDATE_RESET,
  } from "../constants/jpOutsourceCategoryConstants";
  
  export const jpOutSourceCategoryListReducer = (state = { jpOutSourcesCategory: [] }, action) => {
    switch (action.type) {
      case JP_OUTSOURCE_CATEGORY_LIST_REQUEST:
        return { loading: true, jpOutSourcesCategory: [] };
      case JP_OUTSOURCE_CATEGORY_LIST_SUCCESS:
        return { loading: false, jpOutSourcesCategories: action.payload };
      case JP_OUTSOURCE_CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCategoryByIdReducer = (state = { jpOutSource: [] }, action) => {
    switch (action.type) {
      case JP_OUTSOURCE_CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS:
        return { loading: false, jpOutSource: action.payload };
      case JP_OUTSOURCE_CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCategoryRegisterReducer = (state = { jpOutSource: [] }, action) => {
    switch (action.type) {
      case JP_OUTSOURCE_CATEGORY_REGISTER_REQUEST:
        return { loading: true, jpOutSource: [] };
      case JP_OUTSOURCE_CATEGORY_REGISTER_SUCCESS:
        return { loading: false, jpOutSource: action.payload };
      case JP_OUTSOURCE_CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case JP_OUTSOURCE_CATEGORY_DELETE_REQUEST:
        return { loading: true, jpOutSource: [] };
      case JP_OUTSOURCE_CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case JP_OUTSOURCE_CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCategoryUpdateReducer = (state = { jpOutSource: {} }, action) => {
    switch (action.type) {
      case JP_OUTSOURCE_CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case JP_OUTSOURCE_CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case JP_OUTSOURCE_CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case JP_OUTSOURCE_CATEGORY_UPDATE_RESET:
        return { jpOutSource: {} };
      default:
        return state;
    }
  };
  