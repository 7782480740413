import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getLabourCategoryById,
  updateLabourCategory,
} from "../../redux/actions/labourCategoryActions";
import { LABOUR_CATEGORY_UPDATE_RESET } from "../../redux/constants/labourCategoryConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function LabourCategoryEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const labourCategoryId = useParams();

  const [id, setId] = useState("");
  const [labourCategoryName, setLabourCategoryName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.labourCategoryById);
  const { loading, error, labourCategoryById } = Details;

  const update = useSelector((state) => state.labourCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Labour-Category Edit"));
    if (successUpdate) {
      console.log("successUpdate");
      dispatch({ type: LABOUR_CATEGORY_UPDATE_RESET });
    } else {
      if (
        !labourCategoryById.LabourCategoryName ||
        labourCategoryById._id !== labourCategoryId.id
      ) {
        dispatch(getLabourCategoryById(labourCategoryId.id));
      } else {
        setId(labourCategoryById._id);
        setLabourCategoryName(labourCategoryById.LabourCategoryName);
      }
    }
  }, [dispatch, successUpdate, labourCategoryById, labourCategoryId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (labourCategoryName === "") {
      setMessage("labour Category Name Is Empty");
    } else {
      dispatch(
        updateLabourCategory(
          {
            _id: id,
            name: labourCategoryName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                size="small"
                margin="normal"
                label="labourCategoryName"
                name="labour Category Name"
                value={labourCategoryName}
                onChange={(e) => setLabourCategoryName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default LabourCategoryEditScreen;
