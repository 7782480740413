import {
  SL_OUTSOURCE_COMPANY_LIST_REQUEST,
  SL_OUTSOURCE_COMPANY_LIST_SUCCESS,
  SL_OUTSOURCE_COMPANY_LIST_FAIL,
  SL_OUTSOURCE_COMPANY_DETAILS_REQUEST,
  SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS,
  SL_OUTSOURCE_COMPANY_DETAILS_FAIL,
  SL_OUTSOURCE_COMPANY_REGISTER_REQUEST,
  SL_OUTSOURCE_COMPANY_REGISTER_SUCCESS,
  SL_OUTSOURCE_COMPANY_REGISTER_FAIL,
  SL_OUTSOURCE_COMPANY_DELETE_REQUEST,
  SL_OUTSOURCE_COMPANY_DELETE_SUCCESS,
  SL_OUTSOURCE_COMPANY_DELETE_FAIL,
  SL_OUTSOURCE_COMPANY_UPDATE_REQUEST,
  SL_OUTSOURCE_COMPANY_UPDATE_SUCCESS,
  SL_OUTSOURCE_COMPANY_UPDATE_FAIL,
} from "../constants/slOutSourceCompanyConstants";
import * as api from "../api/index";

export const listSlOutSourceCompany = () => async (dispatch) => {
  try {
    dispatch({ type: SL_OUTSOURCE_COMPANY_LIST_REQUEST });

    const { data } = await api.listSlOutSourceCompanyAPI();

    dispatch({
      type: SL_OUTSOURCE_COMPANY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_OUTSOURCE_COMPANY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSlOutSourceCompanyById = (id) => async (dispatch) => {
  try {
    dispatch({ type: SL_OUTSOURCE_COMPANY_DETAILS_REQUEST });

    const { data } = await api.SlOutSourceCompanyByIdAPI(id);
    dispatch({
      type: SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_OUTSOURCE_COMPANY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const SlOutSourceCompanyRegister =
  (sloutsourcecompany, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SL_OUTSOURCE_COMPANY_REGISTER_REQUEST,
      });
      const { data } = await api.SlOutSourceCompanyRegisterAPI(
        sloutsourcecompany
      );

      dispatch({
        type: SL_OUTSOURCE_COMPANY_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/sloutsourcecompany");
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COMPANY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSlOutSourceCompany = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SL_OUTSOURCE_COMPANY_DELETE_REQUEST,
    });

    await api.SlOutSourceCompanyDeleteAPI(id);

    dispatch({
      type: SL_OUTSOURCE_COMPANY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SL_OUTSOURCE_COMPANY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSlOutSourceCompany =
  (sloutsourcecompany,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SL_OUTSOURCE_COMPANY_UPDATE_REQUEST,
      });

      const { data } = await api.SlOutSourceCompanyUpdateAPI(
        sloutsourcecompany
      );

      dispatch({
        type: SL_OUTSOURCE_COMPANY_UPDATE_SUCCESS,
      });

      dispatch({
        type: SL_OUTSOURCE_COMPANY_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/sloutsourcecompany")
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COMPANY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchSlOutSourceCompany =
  (sloutsourcecompanyName) => async (dispatch) => {
    try {
      dispatch({ type: SL_OUTSOURCE_COMPANY_LIST_REQUEST });

      const { data } = await api.SlOutSourceCompanySearchAPI(
        sloutsourcecompanyName
      );

      dispatch({
        type: SL_OUTSOURCE_COMPANY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SL_OUTSOURCE_COMPANY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
