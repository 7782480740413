import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserLevelById, updateUserLevel } from "../../redux/actions/userLevelActions";
import { USER_LEVEL_UPDATE_RESET } from "../../redux/constants/userLevelConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function UserLevelEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [id, setId] = useState("");
  const [userLevel, setUserLevel] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.userLevelById);
  const { loading, error, userlevelById } = Details;

  const update = useSelector((state) => state.userLevelUpdate);
  const {
    loading: userlevelUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update || {};

  useEffect(() => {
    dispatch(SetRoute("User Level Edit"));
    if (successUpdate) {
      dispatch({ type: USER_LEVEL_UPDATE_RESET });
      navigate("/userlevel");
    } else {
      if (!userlevelById.userLevel || userlevelById._id !== params.id) {
        dispatch(getUserLevelById(params.id));
      } else {
        setId(userlevelById._id);
        setUserLevel(userlevelById.userLevel);
      }
    }
  }, [dispatch, userlevelById, successUpdate, params, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (userLevel === "") {
      setMessage("User Level is empty");
    } else {
      dispatch(
        updateUserLevel(
          {
            _id: id,
            UserLevel: userLevel,
          },
          navigate
        )
      );
    }
  };
  return (
    <>
      <Container maxWidth="sm">
        {userlevelUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="User Level Name"
                name="userLevel"
                value={userLevel}
                onChange={(e) => setUserLevel(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default UserLevelEditScreen;
