import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listLabourCategory } from "../redux/actions/labourCategoryActions";
import { listJpStaff } from "../redux/actions/jpStaffActions";
import { LABOUR_CATEGORY_UPDATE_RESET } from "../redux/constants/labourCategoryConstants.js";
import { SetRoute } from "../redux/actions/routeActions";
import {
  LabourCostRegister,
  updateLabourCost,
  listLabourCosts,
} from "../redux/actions/labourCostActions";
import Message from "./Message";
import Loader from "./Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export default function LabourTable({ project }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);

  //-----------------------LABOUR CATEGORY LIST------------------------------------
  const labourCategoryList = useSelector((state) => state.labourCategorysList);
  const { labourCategorys } = labourCategoryList;
  

  //-----------------------PAYMENT LIST------------------------------------
  const List = useSelector((state) => state.jpStaffList);
  const { jpStaffs } = List;

  //-----------------------LABOPUR LIST------------------------------------
  const labourList = useSelector((state) => state.labourCostList);
  const { loading, error, labourCosts } = labourList;


  const [id, setId] = useState("");
  const [labourCategory, setLabourCategory] = useState("");
  const [jpStaff, setJpStaff] = useState([]);
  const [description, setDescription] = useState("");
  const [total, setTotal] = useState("");
  const [days, setDays] = useState("");

  const reset = () => {
    setId("");
    setLabourCategory("");
    setDays("");
    setJpStaff([]);
    setDescription("");
    setTotal("");
  };

  const handleItemClick = (labourCategory) => {
    setId(labourCategory._id);
    setLabourCategory(labourCategory.LabourCategory);
    setDays(labourCategory.Days);
    setJpStaff(labourCategory.JpStaffName);
    setDescription(labourCategory.Description);
    setTotal(labourCategory.Total);
  };

  const handleChangeLabourCategory = (event) => {
    setLabourCategory(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangejpOutsourcepaidStaff = (event) => {
    setJpStaff(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const update = useSelector((state) => state.costCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  const submitUpdate = () => {
    dispatch(
      updateLabourCost({
        _id: id,
        labourCategory: labourCategory,
        days: days,
        description: description,
        jpStaff: jpStaff,
        total: total,
      })
    );
  };

  const register = () => {
    dispatch(
      LabourCostRegister({
        project,
        labourCategory,
        days,
        description,
        jpStaff,
        total,
      })
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (labourCategory === "") {
      setMessage("Labour Category Is Empty");
    } else if (days === "") {
      setMessage("Days Is Empty");
    } else if (description === "") {
      setMessage("Description Is Empty");
    } else if (jpStaff.length === 0) {
      setMessage("Select at least One Jp Staff");
    } else if (total === "") {
      setMessage("Pleace Enter Total Value");
    } else if (id === "") {
      register();
      reset();
    } else {
      submitUpdate();
      reset();
    }
    dispatch(listLabourCosts(project));
  };

  useEffect(() => {
    dispatch(listLabourCategory());
    dispatch(listJpStaff());
    dispatch(listLabourCosts(project));
    dispatch(SetRoute("Labour Costs"));
    if (successUpdate) {
      dispatch({ type: LABOUR_CATEGORY_UPDATE_RESET });
    }
  }, [project, dispatch, successUpdate]);

  return (
    <>
      <form onSubmit={submitHandler}>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}
        <MuiGrid container sx={{ p: 1, bgcolor: "#Ffffff" }}>
          <MuiGrid item xs={4}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Labour Category
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  value={labourCategory}
                  size="small"
                  onChange={handleChangeLabourCategory}
                  label="Payment Method"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {labourCategorys?.map((labourCategory) => (
                    <MenuItem
                      value={labourCategory._id}
                      key={labourCategory._id}
                    >
                      {" "}
                      {labourCategory.LabourCategoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                sx={{ m: 1 }}
                name="days"
                size="small"
                label="Days"
                type="number"
                value={days}
                onChange={(e) => setDays(e.target.value)}
              />
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={4}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="description"
                size="small"
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Jp Staff
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={jpStaff}
                  onChange={handleChangejpOutsourcepaidStaff}
                  label=" jp Staff"
                >
                  <MenuItem value="" key={1}>
                    <em>None</em>
                  </MenuItem>
                  {jpStaffs.map((jpStaff) => (
                    <MenuItem value={jpStaff._id} key={jpStaff._id}>
                      {" "}
                      {jpStaff.JpStaffName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={4}>
            <FormGroup>
              <FormControl>
                <TextField
                  sx={{ m: 1 }}
                  name="total"
                  size="small"
                  label="Total"
                  value={total}
                  onChange={(e) => setTotal(e.target.value)}
                />
                <Button
                  variant="outlined"
                  endIcon={<AddIcon />}
                  sx={{ m: 1 }}
                  type="submit"
                >
                  Save Record
                </Button>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <Button variant="outlined" sx={{ m: 1 }} onClick={reset}>
              Clear
            </Button>
          </MuiGrid>
        </MuiGrid>
      </form>
      {loading ? (
        <Loader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table
            sx={{ minWidth: 650, mt: 1 }}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ m: 1 }}>
                <TableCell>Project Name</TableCell>
                <TableCell align="left">Labour Category </TableCell>
                <TableCell align="left">JP Staff Name</TableCell>
                <TableCell align="left">Days</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Memo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {labourCosts?.map((labourCost) => (
                <TableRow
                  onClick={() => handleItemClick(labourCost)}
                  key={labourCost._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {" "}
                    {labourCost.Project[0].ProjectName}
                  </TableCell>
                  <TableCell align="left">
                    {labourCost.LabourCategoryList[0].LabourCategoryName}
                  </TableCell>
                  <TableCell align="left">{labourCost.JpStaffList[0].JpStaffName}</TableCell>
                  <TableCell align="left">{labourCost.Days}</TableCell>
                  <TableCell align="left">{labourCost.Total}</TableCell>
                  <TableCell align="left">{labourCost.Description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
