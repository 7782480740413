import {
    USER_LEVEL_LIST_REQUEST,
    USER_LEVEL_LIST_SUCCESS,
    USER_LEVEL_LIST_FAIL,
    USER_LEVEL_DETAILS_REQUEST,
    USER_LEVEL_DETAILS_SUCCESS,
    USER_LEVEL_DETAILS_FAIL,
    USER_LEVEL_REGISTER_REQUEST,
    USER_LEVEL_REGISTER_SUCCESS,
    USER_LEVEL_REGISTER_FAIL,
    USER_LEVEL_DELETE_REQUEST,
    USER_LEVEL_DELETE_SUCCESS,
    USER_LEVEL_DELETE_FAIL,
    USER_LEVEL_UPDATE_REQUEST,
    USER_LEVEL_UPDATE_SUCCESS,
    USER_LEVEL_UPDATE_FAIL,
    USER_LEVEL_UPDATE_RESET,
  } from "../constants/userLevelConstants";
  
  export const userlevelListReducer = (state = { userlevels: [] }, action) => {
    switch (action.type) {
      case USER_LEVEL_LIST_REQUEST:
        return { loading: true, userlevels: [] };
      case USER_LEVEL_LIST_SUCCESS:
        return { loading: false, userlevels: action.payload };
      case USER_LEVEL_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const userlevelByIdReducer = (state = { userlevelById: [] }, action) => {
    switch (action.type) {
      case USER_LEVEL_DETAILS_REQUEST:
        return { loading: true, ...state };
      case USER_LEVEL_DETAILS_SUCCESS:
        return { loading: false, userlevelById: action.payload };
      case USER_LEVEL_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const userlevelRegisterReducer = (state = { userlevel: [] }, action) => {
    switch (action.type) {
      case USER_LEVEL_REGISTER_REQUEST:
        return { loading: true, userlevel: [] };
      case USER_LEVEL_REGISTER_SUCCESS:
        return { loading: false, userlevelInfo: action.payload };
      case USER_LEVEL_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const userlevelDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LEVEL_DELETE_REQUEST:
        return { loading: true, userlevel: [] };
      case USER_LEVEL_DELETE_SUCCESS:
        return { loading: false, success: true };
      case USER_LEVEL_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const userlevelUpdateReducer = (state = { userlevel: {} }, action) => {
    switch (action.type) {
      case USER_LEVEL_UPDATE_REQUEST:
        return { loading: true };
      case USER_LEVEL_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case USER_LEVEL_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case USER_LEVEL_UPDATE_RESET:
        return { userlevel: {} };
      default:
        return state;
    }
  };
  