import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getJpOutSourceById,
  updateJpOutSource,
} from "../../redux/actions/jpOutsourceActions";
import { JP_OUT_SOURCE_UPDATE_RESET } from "../../redux/constants/jpOutsourceConstants";
import { useParams } from "react-router-dom";
import { SetRoute } from "../../redux/actions/routeActions";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function JpOutsourceEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jpOutsourceId = useParams();

  const [id, setId] = useState("");
  const [jpOutsourceName, setJpOutsourceName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.jpOutSourceById);
  const { loading, error, jpOutSource } = Details;

  const update = useSelector((state) => state.userUpdateProfile);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Jp Out Source Edit"));
    if (successUpdate) {
      console.log("successUpdate");
      dispatch({ type: JP_OUT_SOURCE_UPDATE_RESET });
    } else {
      if (
        !jpOutSource.JpOutsourceName ||
        jpOutSource._id !== jpOutsourceId.id
      ) {
        dispatch(getJpOutSourceById(jpOutsourceId.id));
      } else {
        setId(jpOutSource._id);
        setJpOutsourceName(jpOutSource.JpOutsourceName);
      }
    }
  }, [dispatch, successUpdate, jpOutsourceId, jpOutSource]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (jpOutsourceName === "") {
      setMessage("Jp Outsource Name Is Empty");
    } else {
      dispatch(
        updateJpOutSource(
          {
            _id: id,
            name: jpOutsourceName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="JpOutsourceName"
                name="jpOutsourceName"
                value={jpOutsourceName}
                onChange={(e) => setJpOutsourceName(e.target.value)}
              />

              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default JpOutsourceEditScreen;
