export const JP_OUTSOURCE_CATEGORY_LIST_REQUEST = "JP_OUTSOURCE_CATEGORY_LIST_REQUEST";
export const JP_OUTSOURCE_CATEGORY_LIST_SUCCESS = "JP_OUTSOURCE_CATEGORY_LIST_SUCCESS";
export const JP_OUTSOURCE_CATEGORY_LIST_FAIL = "JP_OUTSOURCE_CATEGORY_LIST_FAIL";

export const JP_OUTSOURCE_CATEGORY_DETAILS_REQUEST = "JP_OUTSOURCE_CATEGORY_DETAILS_REQUEST";
export const JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS = "JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS";
export const JP_OUTSOURCE_CATEGORY_DETAILS_FAIL = "JP_OUTSOURCE_CATEGORY_DETAILS_FAIL";

export const JP_OUTSOURCE_CATEGORY_REGISTER_REQUEST = "JP_OUTSOURCE_CATEGORY_REGISTER_REQUEST";
export const JP_OUTSOURCE_CATEGORY_REGISTER_SUCCESS = "JP_OUTSOURCE_CATEGORY_REGISTER_SUCCESS";
export const JP_OUTSOURCE_CATEGORY_REGISTER_FAIL = "JP_OUTSOURCE_CATEGORY_REGISTER_FAIL";

export const JP_OUTSOURCE_CATEGORY_DELETE_REQUEST = "JP_OUTSOURCE_CATEGORY_DELETE_REQUEST";
export const JP_OUTSOURCE_CATEGORY_DELETE_SUCCESS = "JP_OUTSOURCE_CATEGORY_DELETE_SUCCESS";
export const JP_OUTSOURCE_CATEGORY_DELETE_FAIL = "JP_OUTSOURCE_CATEGORY_DELETE_FAIL";

export const JP_OUTSOURCE_CATEGORY_UPDATE_REQUEST = "JP_OUTSOURCE_CATEGORY_UPDATE_REQUEST";
export const JP_OUTSOURCE_CATEGORY_UPDATE_SUCCESS = "JP_OUTSOURCE_CATEGORY_UPDATE_SUCCESS";
export const JP_OUTSOURCE_CATEGORY_UPDATE_FAIL = "JP_OUTSOURCE_CATEGORY_UPDATE_FAIL";
export const JP_OUTSOURCE_CATEGORY_UPDATE_RESET = "JP_OUTSOURCE_CATEGORY_UPDATE_RESET";
