import {
    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,
    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,
    PAYMENT_REGISTER_REQUEST,
    PAYMENT_REGISTER_SUCCESS,
    PAYMENT_REGISTER_FAIL,
    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_DELETE_FAIL,
    PAYMENT_UPDATE_REQUEST,
    PAYMENT_UPDATE_SUCCESS,
    PAYMENT_UPDATE_FAIL,
    PAYMENT_UPDATE_RESET,
  } from "../constants/paymentConstants";
  
  export const paymentListReducer = (state = { payments: [] }, action) => {
    switch (action.type) {
      case PAYMENT_LIST_REQUEST:
        return { loading: true, payments: [] };
      case PAYMENT_LIST_SUCCESS:
        return { loading: false, payments: action.payload };
      case PAYMENT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const paymentByIdReducer = (state = { paymentById: [] }, action) => {
    switch (action.type) {
      case PAYMENT_DETAILS_REQUEST:
        return { loading: true, ...state };
      case PAYMENT_DETAILS_SUCCESS:
        return { loading: false, paymentById: action.payload };
      case PAYMENT_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const paymentRegisterReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
      case PAYMENT_REGISTER_REQUEST:
        return { loading: true, payment: [] };
      case PAYMENT_REGISTER_SUCCESS:
        return { loading: false, paymentInfo: action.payload };
      case PAYMENT_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const paymentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case PAYMENT_DELETE_REQUEST:
        return { loading: true, payment: [] };
      case PAYMENT_DELETE_SUCCESS:
        return { loading: false, success: true };
      case PAYMENT_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const paymentUpdateReducer = (state = { payment: {} }, action) => {
    switch (action.type) {
      case PAYMENT_UPDATE_REQUEST:
        return { loading: true };
      case PAYMENT_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case PAYMENT_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case PAYMENT_UPDATE_RESET:
        return { payment: {} };
      default:
        return state;
    }
  };
  