export const JP_STAFF_LIST_REQUEST = "JP_STAFF_LIST_REQUEST";
export const JP_STAFF_LIST_SUCCESS = "JP_STAFF_LIST_SUCCESS";
export const JP_STAFF_LIST_FAIL = "JP_STAFF_LIST_FAIL";

export const JP_STAFF_DETAILS_REQUEST = "JP_STAFF_DETAILS_REQUEST";
export const JP_STAFF_DETAILS_SUCCESS = "JP_STAFF_DETAILS_SUCCESS";
export const JP_STAFF_DETAILS_FAIL = "JP_STAFF_DETAILS_FAIL";

export const JP_STAFF_REGISTER_REQUEST = "JP_STAFF_REGISTER_REQUEST";
export const JP_STAFF_REGISTER_SUCCESS = "JP_STAFF_REGISTER_SUCCESS";
export const JP_STAFF_REGISTER_FAIL = "JP_STAFF_REGISTER_FAIL";

export const JP_STAFF_DELETE_REQUEST = "JP_STAFF_DELETE_REQUEST";
export const JP_STAFF_DELETE_SUCCESS = "JP_STAFF_DELETE_SUCCESS";
export const JP_STAFF_DELETE_FAIL = "JP_STAFF_DELETE_FAIL";

export const JP_STAFF_UPDATE_REQUEST = "JP_STAFF_UPDATE_REQUEST";
export const JP_STAFF_UPDATE_SUCCESS = "JP_STAFF_UPDATE_SUCCESS";
export const JP_STAFF_UPDATE_FAIL = "JP_STAFF_UPDATE_FAIL";
export const JP_STAFF_UPDATE_RESET = "JP_STAFF_UPDATE_RESET";
