import {
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CATEGORY_REGISTER_REQUEST,
    CATEGORY_REGISTER_SUCCESS,
    CATEGORY_REGISTER_FAIL,
    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_FAIL,
    CATEGORY_UPDATE_REQUEST,
    CATEGORY_UPDATE_SUCCESS,
    CATEGORY_UPDATE_FAIL,
    CATEGORY_UPDATE_RESET,
  } from "../constants/categoryConstants";
  
  export const categorysListReducer = (state = { categorys: [] }, action) => {
    switch (action.type) {
      case CATEGORY_LIST_REQUEST:
        return { loading: true, categorys: [] };
      case CATEGORY_LIST_SUCCESS:
        return { loading: false, categorys: action.payload };
      case CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    } 
  };
  
  export const categoryByIdReducer = (state = { categoryById: [] }, action) => {
    switch (action.type) {
      case CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case CATEGORY_DETAILS_SUCCESS:
        return { loading: false, categoryById: action.payload };
      case CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const categoryRegisterReducer = (state = { category: [] }, action) => {
    switch (action.type) {
      case CATEGORY_REGISTER_REQUEST:
        return { loading: true, category: [] };
      case CATEGORY_REGISTER_SUCCESS:
        return { loading: false, categoryInfo: action.payload };
      case CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const categoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case CATEGORY_DELETE_REQUEST:
        return { loading: true, category: [] };
      case CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const categoryUpdateReducer = (state = { category: {} }, action) => {
    switch (action.type) {
      case CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case CATEGORY_UPDATE_RESET:
        return { category: {} };
      default:
        return state;
    }
  };
  