export const USER_ROLE_LIST_REQUEST = "USER_ROLE_LIST_REQUEST";
export const USER_ROLE_LIST_SUCCESS = "USER_ROLE_LIST_SUCCESS";
export const USER_ROLE_LIST_FAIL = "USER_ROLE_LIST_FAIL";

export const USER_ROLE_DETAILS_REQUEST = "USER_ROLE_DETAILS_REQUEST";
export const USER_ROLE_DETAILS_SUCCESS = "USER_ROLE_DETAILS_SUCCESS";
export const USER_ROLE_DETAILS_FAIL = "USER_ROLE_DETAILS_FAIL";

export const USER_ROLE_REGISTER_REQUEST = "USER_ROLE_REGISTER_REQUEST";
export const USER_ROLE_REGISTER_SUCCESS = "USER_ROLE_REGISTER_SUCCESS";
export const USER_ROLE_REGISTER_FAIL = "USER_ROLE_REGISTER_FAIL";

export const USER_ROLE_DELETE_REQUEST = "USER_ROLE_DELETE_REQUEST";
export const USER_ROLE_DELETE_SUCCESS = "USER_ROLE_DELETE_SUCCESS";
export const USER_ROLE_DELETE_FAIL = "USER_ROLE_DELETE_FAIL";

export const USER_ROLE_UPDATE_REQUEST = "USER_ROLE_UPDATE_REQUEST";
export const USER_ROLE_UPDATE_SUCCESS = "USER_ROLE_UPDATE_SUCCESS";
export const USER_ROLE_UPDATE_FAIL = "USER_ROLE_UPDATE_FAIL";
export const USER_ROLE_UPDATE_RESET = "USER_ROLE_UPDATE_RESET";
