import {
  USER_LEVEL_LIST_REQUEST,
  USER_LEVEL_LIST_SUCCESS,
  USER_LEVEL_LIST_FAIL,
  USER_LEVEL_DETAILS_REQUEST,
  USER_LEVEL_DETAILS_SUCCESS,
  USER_LEVEL_DETAILS_FAIL,
  USER_LEVEL_REGISTER_REQUEST,
  USER_LEVEL_REGISTER_SUCCESS,
  USER_LEVEL_REGISTER_FAIL,
  USER_LEVEL_DELETE_REQUEST,
  USER_LEVEL_DELETE_SUCCESS,
  USER_LEVEL_DELETE_FAIL,
  USER_LEVEL_UPDATE_REQUEST,
  USER_LEVEL_UPDATE_SUCCESS,
  USER_LEVEL_UPDATE_FAIL,
} from "../constants/userLevelConstants";
import * as api from "../api/index";

export const listUserLevel = () => async (dispatch) => {
  try {
    dispatch({ type: USER_LEVEL_LIST_REQUEST });

    const { data } = await api.listUserLevelAPI();

    dispatch({
      type: USER_LEVEL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LEVEL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserLevelById = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_LEVEL_DETAILS_REQUEST });

    const { data } = await api.UserLevelByIdAPI(id);
    dispatch({
      type: USER_LEVEL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LEVEL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userLevelRegister = (userlevel, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LEVEL_REGISTER_REQUEST,
    });
    const { data } = await api.UserLevelRegisterAPI(userlevel);

    dispatch({
      type: USER_LEVEL_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LEVEL_DETAILS_SUCCESS,
      payload: data,
    });

    navigate("/userlevel");
  } catch (error) {
    dispatch({
      type: USER_LEVEL_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUserLevel = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LEVEL_DELETE_REQUEST,
    });

    await api.UserLevelDeleteAPI(id);

    dispatch({
      type: USER_LEVEL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_LEVEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserLevel = (userlevel, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LEVEL_UPDATE_REQUEST,
    });

    const { data } = await api.UserLevelUpdateAPI(userlevel);

    dispatch({
      type: USER_LEVEL_UPDATE_SUCCESS,
    });

    dispatch({
      type: USER_LEVEL_DETAILS_SUCCESS,
      payload: data,
    });
    navigate("/userlevel");
  } catch (error) {
    dispatch({
      type: USER_LEVEL_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const searchUserLevel = (UserLevelName) => async (dispatch) => {
  try {
    dispatch({ type: USER_LEVEL_LIST_REQUEST });

    const { data } = await api.UserLevelSearchAPI(UserLevelName);

    dispatch({
      type: USER_LEVEL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LEVEL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
