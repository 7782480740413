import {
    JP_STAFF_LIST_REQUEST,
    JP_STAFF_LIST_SUCCESS,
    JP_STAFF_LIST_FAIL,
    JP_STAFF_DETAILS_REQUEST,
    JP_STAFF_DETAILS_SUCCESS,
    JP_STAFF_DETAILS_FAIL,
    JP_STAFF_REGISTER_REQUEST,
    JP_STAFF_REGISTER_SUCCESS,
    JP_STAFF_REGISTER_FAIL,
    JP_STAFF_DELETE_REQUEST,
    JP_STAFF_DELETE_SUCCESS,
    JP_STAFF_DELETE_FAIL,
    JP_STAFF_UPDATE_REQUEST,
    JP_STAFF_UPDATE_SUCCESS,
    JP_STAFF_UPDATE_FAIL,
    JP_STAFF_UPDATE_RESET,
  } from "../constants/jpStaffConstants";
  
  export const jpStaffListReducer = (state = { jpStaffs: [] }, action) => {
    switch (action.type) {
      case JP_STAFF_LIST_REQUEST:
        return { loading: true, jpStaffs: [] };
      case JP_STAFF_LIST_SUCCESS:
        return { loading: false, jpStaffs: action.payload };
      case JP_STAFF_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    } 
  };
  
  export const jpStaffByIdReducer = (state = { jpStaffById: [] }, action) => {
    switch (action.type) {
      case JP_STAFF_DETAILS_REQUEST:
        return { loading: true, ...state };
      case JP_STAFF_DETAILS_SUCCESS:
        return { loading: false, jpStaffById: action.payload };
      case JP_STAFF_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpStaffRegisterReducer = (state = { jpStaff: [] }, action) => {
    switch (action.type) {
      case JP_STAFF_REGISTER_REQUEST:
        return { loading: true, jpStaff: [] };
      case JP_STAFF_REGISTER_SUCCESS:
        return { loading: false, jpStaffInfo: action.payload };
      case JP_STAFF_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpStaffDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case JP_STAFF_DELETE_REQUEST:
        return { loading: true, jpStaff: [] };
      case JP_STAFF_DELETE_SUCCESS:
        return { loading: false, success: true };
      case JP_STAFF_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpStaffUpdateReducer = (state = { jpStaff: {} }, action) => {
    switch (action.type) {
      case JP_STAFF_UPDATE_REQUEST:
        return { loading: true };
      case JP_STAFF_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case JP_STAFF_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case JP_STAFF_UPDATE_RESET:
        return { jpStaff: {} };
      default:
        return state;
    }
  };
  