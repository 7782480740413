import {
    JP_OUT_SOURCE_COST_LIST_REQUEST,
    JP_OUT_SOURCE_COST_LIST_SUCCESS,
    JP_OUT_SOURCE_COST_LIST_FAIL,
    JP_OUT_SOURCE_COST_DETAILS_REQUEST,
    JP_OUT_SOURCE_COST_DETAILS_SUCCESS,
    JP_OUT_SOURCE_COST_DETAILS_FAIL,
    JP_OUT_SOURCE_COST_REGISTER_REQUEST,
    JP_OUT_SOURCE_COST_REGISTER_SUCCESS,
    JP_OUT_SOURCE_COST_REGISTER_FAIL,
    JP_OUT_SOURCE_COST_DELETE_REQUEST,
    JP_OUT_SOURCE_COST_DELETE_SUCCESS,
    JP_OUT_SOURCE_COST_DELETE_FAIL,
    JP_OUT_SOURCE_COST_UPDATE_REQUEST,
    JP_OUT_SOURCE_COST_UPDATE_SUCCESS,
    JP_OUT_SOURCE_COST_UPDATE_FAIL,
    JP_OUT_SOURCE_COST_UPDATE_RESET,
  } from "../constants/jpOutsourceCostConstants";
  
  export const jpOutSourceCostListReducer = (state = { jpOutSourceCosts: [] }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_COST_LIST_REQUEST:
        return { loading: true, jpOutSourceCosts: [] };
      case JP_OUT_SOURCE_COST_LIST_SUCCESS:
        return { loading: false, jpOutSourceCosts: action.payload };
      case JP_OUT_SOURCE_COST_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCostByIdReducer = (state = { jpOutSourceCost: [] }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_COST_DETAILS_REQUEST:
        return { loading: true, ...state };
      case JP_OUT_SOURCE_COST_DETAILS_SUCCESS:
        return { loading: false, jpOutSourceCost: action.payload };
      case JP_OUT_SOURCE_COST_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCostRegisterReducer = (state = { jpOutSourceCost: [] }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_COST_REGISTER_REQUEST:
        return { loading: true, jpOutSourceCost: [] };
      case JP_OUT_SOURCE_COST_REGISTER_SUCCESS:
        return { loading: false, jpOutSourceCost: action.payload };
      case JP_OUT_SOURCE_COST_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCostDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_COST_DELETE_REQUEST:
        return { loading: true, jpOutSourceCost: [] };
      case JP_OUT_SOURCE_COST_DELETE_SUCCESS:
        return { loading: false, success: true };
      case JP_OUT_SOURCE_COST_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const jpOutSourceCostUpdateReducer = (state = { jpOutSourceCost: {} }, action) => {
    switch (action.type) {
      case JP_OUT_SOURCE_COST_UPDATE_REQUEST:
        return { loading: true };
      case JP_OUT_SOURCE_COST_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case JP_OUT_SOURCE_COST_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case JP_OUT_SOURCE_COST_UPDATE_RESET:
        return { jpOutSourceCost: {} };
      default:
        return state;
    }
  };
  