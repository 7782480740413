import {
    PROJECT_CATEGORY_LIST_REQUEST,
    PROJECT_CATEGORY_LIST_SUCCESS,
    PROJECT_CATEGORY_LIST_FAIL,
    PROJECT_CATEGORY_DETAILS_REQUEST,
    PROJECT_CATEGORY_DETAILS_SUCCESS,
    PROJECT_CATEGORY_DETAILS_FAIL,
    PROJECT_CATEGORY_REGISTER_REQUEST,
    PROJECT_CATEGORY_REGISTER_SUCCESS,
    PROJECT_CATEGORY_REGISTER_FAIL,
    PROJECT_CATEGORY_DELETE_REQUEST,
    PROJECT_CATEGORY_DELETE_SUCCESS,
    PROJECT_CATEGORY_DELETE_FAIL,
    PROJECT_CATEGORY_UPDATE_REQUEST,
    PROJECT_CATEGORY_UPDATE_SUCCESS,
    PROJECT_CATEGORY_UPDATE_FAIL,
    PROJECT_CATEGORY_UPDATE_RESET,
  } from "../constants/projectCategoryConstants";
  
  export const projectCategorysListReducer = (state = { projectCategorys: [] }, action) => {
    switch (action.type) {
      case PROJECT_CATEGORY_LIST_REQUEST:
        return { loading: true, projectCategorys: [] };
      case PROJECT_CATEGORY_LIST_SUCCESS:
        return { loading: false, projectCategorys: action.payload };
      case PROJECT_CATEGORY_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const projectCategoryByIdReducer = (state = { projectCategory: [] }, action) => {
    switch (action.type) {
      case PROJECT_CATEGORY_DETAILS_REQUEST:
        return { loading: true, ...state };
      case PROJECT_CATEGORY_DETAILS_SUCCESS:
        return { loading: false, projectCategory: action.payload };
      case PROJECT_CATEGORY_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const projectCategoryRegisterReducer = (state = { projectCategory: [] }, action) => {
    switch (action.type) {
      case PROJECT_CATEGORY_REGISTER_REQUEST:
        return { loading: true, projectCategory: [] };
      case PROJECT_CATEGORY_REGISTER_SUCCESS:
        return { loading: false, projectCategoryInfo: action.payload };
      case PROJECT_CATEGORY_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const projectCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case PROJECT_CATEGORY_DELETE_REQUEST:
        return { loading: true, projectCategory: [] };
      case PROJECT_CATEGORY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case PROJECT_CATEGORY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const projectCategoryUpdateReducer = (state = { projectCategory: {} }, action) => {
    switch (action.type) {
      case PROJECT_CATEGORY_UPDATE_REQUEST:
        return { loading: true };
      case PROJECT_CATEGORY_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case PROJECT_CATEGORY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case PROJECT_CATEGORY_UPDATE_RESET:
        return { projectCategory: {} };
      default:
        return state;
    }
  };
  