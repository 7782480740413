import {
    JP_OUTSOURCE_CATEGORY_LIST_REQUEST,
    JP_OUTSOURCE_CATEGORY_LIST_SUCCESS,
    JP_OUTSOURCE_CATEGORY_LIST_FAIL,
    JP_OUTSOURCE_CATEGORY_DETAILS_REQUEST,
    JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
    JP_OUTSOURCE_CATEGORY_DETAILS_FAIL,
    JP_OUTSOURCE_CATEGORY_REGISTER_REQUEST,
    JP_OUTSOURCE_CATEGORY_REGISTER_SUCCESS,
    JP_OUTSOURCE_CATEGORY_REGISTER_FAIL,
    JP_OUTSOURCE_CATEGORY_DELETE_REQUEST,
    JP_OUTSOURCE_CATEGORY_DELETE_SUCCESS,
    JP_OUTSOURCE_CATEGORY_DELETE_FAIL,
    JP_OUTSOURCE_CATEGORY_UPDATE_REQUEST,
    JP_OUTSOURCE_CATEGORY_UPDATE_SUCCESS,
    JP_OUTSOURCE_CATEGORY_UPDATE_FAIL,
  } from "../constants/jpOutsourceCategoryConstants";
  import * as api from "../api/index";
  
  export const listJpOutSourceCategories = () => async (dispatch) => {
    try {
      dispatch({ type: JP_OUTSOURCE_CATEGORY_LIST_REQUEST });
  
      const { data } = await api.listJpOutsourceCategoryAPI();
  
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_LIST_SUCCESS,
        payload: data,
      });      
    } catch (error) {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const getJpOutSourceCategoryById = (id) => async (dispatch) => {
    try {
      dispatch({ type: JP_OUTSOURCE_CATEGORY_DETAILS_REQUEST });
  
      const { data } = await api.JpOutsourceCategoryByIdAPI(id);
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const JpOutSourceCategoryRegister = (jpoutsourcecategoryName,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_REGISTER_REQUEST,
      });
      const { data } = await api.JpOutsourceCategoryRegisterAPI(jpoutsourcecategoryName);
  
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_REGISTER_SUCCESS,
        payload: data,
      });
  console.log(jpoutsourcecategoryName)
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });
      navigate("/jpoutsourcescategory");
    } catch (error) {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deleteJpOutSourceCategory = (id) => async (dispatch) => {
    try {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DELETE_REQUEST,
      });
  
      await api.JpOutsourceCategoryDeleteAPI(id);
  
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateJpOutSourceCategory = (jpOutSource,navigate) => async (dispatch) => {
    try {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_UPDATE_REQUEST,
      });
  
      const { data } = await api.JpOutsourceCategoryUpdateAPI(jpOutSource);
  
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_UPDATE_SUCCESS,
      });
  
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/jpoutsourcescategory");
    } catch (error) {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const searchJpOutSourceCategory = (jpOutSourceName) => async (dispatch) => {
    try {
      dispatch({ type: JP_OUTSOURCE_CATEGORY_LIST_REQUEST });
  
      const { data } = await api.JpOutsourceCategorySearchAPI(jpOutSourceName);
  
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JP_OUTSOURCE_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  