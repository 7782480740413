import * as React from 'react';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';

export default function Loader() {
  return (
    <Box sx={{ width: "100%", marginTop:"-4px" }}>
      <LinearProgress />
    </Box>
  );
}