import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "../redux/actions/userActions";
import { listJpStaff } from "../redux/actions/jpStaffActions";
import { listSlDeveloper } from "../redux/actions/slDeveloperActons";
import { listPayment } from "../redux/actions/paymentActions";
import { listOtherCategory } from "../redux/actions/otherCategoryActions";
import { listSlOutSourceCategory } from "../redux/actions/slOutSourceCategoryActions";
import { SlOutsourceCostRegister } from "../redux/actions/slOutsourceCostActions";
import {
  listSlOutsourceCost,
  updateSlOutsourceCost,
} from "../redux/actions/slOutsourceCostActions";
import { listSlOutSourceCompany } from "../redux/actions/slOutSourceCompanyActions";
import { JP_OUT_SOURCE_COST_UPDATE_RESET } from "../redux/constants/jpOutsourceCostConstants";
import { SetRoute } from "../redux/actions/routeActions";
import Message from "./Message";
import Loader from "./Loader";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";

export default function OtherTable({ project }) {
  const dispatch = useDispatch();
  var path = "/uploads/SlOutsourceFiles/";
  const [message, setMessage] = useState(null);

  //--------------------JP_Staff_LIST------------------------------
  const jpStaffList = useSelector((state) => state.jpStaffList);
  const { jpStaffs } = jpStaffList;

  //-----------------------PAYMENT LIST------------------------------------
  const paymentList = useSelector((state) => state.paymentList);
  const { payments } = paymentList;

  //-----------------------SL OUT SOURCE CATEGORY LIST------------------------------------
  const Details = useSelector((state) => state.slOutSourceCategoryList);
  const { slOutSourceCategorys } = Details;

  //-----------------------SL OUT SOURCE CATEGORY LIST------------------------------------
  const sloutsourceCostList = useSelector((state) => state.sloutsourceCostList);
  const { loading, error, sloutsourcecosts } = sloutsourceCostList;
  

  //-----------------------SL DEVELOPER------------------------------------
  const slDeveloperList = useSelector((state) => state.slDeveloperList);
  const { slDevelopers } = slDeveloperList;

  const [slDeveloper, setSlDeveloper] = useState([]);
  const [paymentmethod, setPaymentmethod] = useState([]);
  const [sloutsourcecategory, setSloutsourcecategory] = useState([]);
  const [invoice, setInvoice] = useState("");
  const [invoiceperson, setInvoiceperson] = useState("");
  const [paidstaff, setPaidstaff] = useState([]);
  const [description, setDescription] = useState("");
  const [paymentslip, setPaymentslip] = useState("");
  const [total, setTotal] = useState("");
  const [id, setId] = useState("");
  const [paiddate, setPaiddate] = useState(new Date(Date.now()));
  const [invoicedate, setInvoiveDate] = useState(new Date(Date.now()));

  const reset = () => {
    setId("");
    setSloutsourcecategory([]);
    setInvoice("");
    setInvoiceperson("");
    setInvoiveDate(Date.now());
    setPaiddate(Date.now());
    setPaidstaff([]);
    setSlDeveloper([]);
    setPaymentmethod([]);
    setDescription("");
    setPaymentslip("");
    setTotal("");
  };

  const handleItemClick = (sloutsourcecost) => {
    setId(sloutsourcecost._id);
    setSloutsourcecategory(sloutsourcecost.SLOutsourceCategoryInID);
    setInvoice(sloutsourcecost.Invoice);
    setInvoiceperson(sloutsourcecost.InvoicePreson);
    setInvoiveDate(sloutsourcecost.InvoiceDate);
    setPaiddate(sloutsourcecost.PaidDate);
    setPaidstaff(sloutsourcecost.PaidStaff);
    setSlDeveloper(sloutsourcecost.SlDevelopers);
    setPaymentmethod(sloutsourcecost.PaidMethodInID);
    setDescription(sloutsourcecost.Description);
    // setPaymentslip(sloutsourcecost.PaidSlip);
    setTotal(sloutsourcecost.Total);
  };

  const handleChangejSlDevelopers = (event) => {
    setSlDeveloper(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangesloutsourcecategory = (event) => {
    setSloutsourcecategory(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };
  const handleChangeotherpaidStaff = (event) => {
    setPaidstaff(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangePayment = (event) => {
    setPaymentmethod(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeInvoiceDate = (newValue) => {
    setInvoiveDate(newValue.format("YYYY/MM/DD"));
  };

  const handleChangePaidDate = (newValue) => {
    setPaiddate(newValue.format("YYYY/MM/DD"));
  };

  
  const changeHandler=(e)=>{
    if (e.target.files.length > 0) {
      setInvoice(e.target.files[0])  
     
    }
  }
 
  const paymentSlipHandler=(e)=>{
    if (e.target.files.length > 0) {
      setPaymentslip(e.target.files[0])     
    }
  }

  const update = useSelector((state) => state.costCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  const submitUpdate = (invoicename,paymentslipname) => {
    dispatch(
      updateSlOutsourceCost({
        _id: id,
        slOutSourceCategoryName: sloutsourcecategory,
        invoice: invoicename,
        invoicePreson: invoiceperson,
        invoiceDate: invoicedate,
        paidDate: paiddate,
        paidStaff: paidstaff,
        slDeveloper: slDeveloper,
        paidMethodInID: paymentmethod,
        description: description,
        paidSlip: paymentslipname,
        total: total,
      })
    );
  };

  const register = (invoicename,paymentslipname) => {
    dispatch(
      SlOutsourceCostRegister({
        project,
        sloutsourcecategory,
        invoicename,
        invoiceperson,
        invoicedate,
        paiddate,
        paidstaff,
        slDeveloper,
        paymentmethod,
        paymentslipname,
        description,
        total,
      })
    );
  };

  const submitHandler = (e) => {
    e.preventDefault(); 
    if (sloutsourcecategory.length === 0) {
      setMessage("Select at least One Sl Out Source Category");
    } else if (invoice === "") {
      setMessage("Invoice Is Empty");
    } else if (invoiceperson === "") {
      setMessage("Invoced Person Is Empty");
    } else if (invoicedate === "") {
      setMessage("Jp Development Is Empty");
    } else if (paidstaff.length === 0) {
      setMessage("Select at least One Staff");
    } else if (paymentmethod === "") {
      setMessage("Select a Payment Method");
    } else if (slDeveloper.length === 0) {
      setMessage("Select at least One Jp Out Ousrce");
    } else if (description === "") {
      setMessage("Description is Empty");
    } else if (total === "") {
      setMessage("Pleace Enter Total Value");
    } else if (id === "") {
     
      let url = "/upload.php";
      const path = "uploads/SlOutsourceFiles/";

      const data1 = new FormData();
      const data2 = new FormData();
      data1.append("file", invoice);
      data1.append("filepath", path);
      data2.append("file", paymentslip);
      data2.append("filepath", path);

      axios
        .all([axios.post(url, data1), axios.post(url, data2)])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            register(firstResponse.data, secondResponse.data);
            reset();
          })
        )
        .catch((error) => console.log(error));
    } else {
      let url = "/upload.php";
      const path = "uploads/SlOutsourceFiles/";

      const data1 = new FormData();
      const data2 = new FormData();
      data1.append("file", invoice);
      data1.append("filepath", path);
      data2.append("file", paymentslip);
      data2.append("filepath", path);

      axios
        .all([axios.post(url, data1), axios.post(url, data2)])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            submitUpdate(firstResponse.data, secondResponse.data);
            reset();
          })
        )
        .catch((error) => console.log(error));
    }
    dispatch(listSlOutsourceCost(project));
  };

  useEffect(() => {
    dispatch(listUsers());
    dispatch(listJpStaff());
    dispatch(listPayment());
    dispatch(listSlDeveloper());
    dispatch(listOtherCategory());
    dispatch(listSlOutSourceCategory());
    dispatch(listSlOutSourceCompany());
    dispatch(listSlOutsourceCost(project));
    dispatch(SetRoute("SL Out Source Costs"));
    if (successUpdate) {
      console.log("successUpdate");
      dispatch({ type: JP_OUT_SOURCE_COST_UPDATE_RESET });
    }
  }, [project, dispatch, successUpdate]);

  return (
    <>
      <form onSubmit={submitHandler}>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}
        <MuiGrid container sx={{ p: 1, bgcolor: "#Ffffff" }}>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  SL Outsource Category
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={sloutsourcecategory}
                  onChange={handleChangesloutsourcecategory}
                  label=" SL OutSource Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {slOutSourceCategorys.map((slOutSourceCategory) => (
                    <MenuItem
                      value={slOutSourceCategory._id}
                      key={slOutSourceCategory._id}
                    >
                      {" "}
                      {slOutSourceCategory.SlOutSourceCategoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                style={{
                  maxHeight: "45px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
                sx={{ m: 0.7 }}
                variant="text"
                component="label"
                size="small"
              >
                Invoice File
                <TextField
                  sx={{ m: 1 }}
                  hidden
                  multiple
                  type="file"
                  size="small"
                  value={""}
                  onChange={changeHandler}
                />
              </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" sx={{ m: 1 }} />
                    )}
                    label="Invoice Date"
                    value={invoicedate}
                    onChange={(invoicedate) =>
                      handleChangeInvoiceDate(invoicedate)
                    }
                    inputFormat="YYYY/MM/DD"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" sx={{ m: 1 }} />
                    )}
                    label="Paid Date"
                    value={paiddate}
                    onChange={(paiddate) => handleChangePaidDate(paiddate)}
                    inputFormat="YYYY/MM/DD"
                  />
                </LocalizationProvider>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="invoice"
                size="small"
                label="Invoice Person"
                value={invoiceperson}
                onChange={(e) => setInvoiceperson(e.target.value)}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Paid Staff
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={paidstaff}
                  onChange={handleChangeotherpaidStaff}
                  label=" Other Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {jpStaffs.map((jpStaff) => (
                    <MenuItem value={jpStaff._id} key={jpStaff._id}>
                      {" "}
                      {jpStaff.JpStaffName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  slDevelopers
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  size="small"
                  multiple
                  value={slDeveloper}
                  onChange={handleChangejSlDevelopers}
                  label="slDevelopers"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {slDevelopers.map((slDeveloper) => (
                    <MenuItem value={slDeveloper._id} key={slDeveloper._id}>
                      {" "}
                      {slDeveloper.SlDeveloperName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Payment Method
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  size="small"
                  multiple
                  value={paymentmethod}
                  onChange={handleChangePayment}
                  label="Payment Method"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {payments.map((payment) => (
                    <MenuItem value={payment._id} key={payment._id}>
                      {" "}
                      {payment.PaymentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>

          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                size="small"
                name="description"
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Button
                style={{
                  maxHeight: "45px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
                sx={{ m: 0.7 }}
                variant="text"
                component="label"
                size="small"
              >
                Payment Slip
                <TextField
                  accept="image/* "
                  hidden
                  multiple
                  type="file"
                  size="small"
                  value={""}
                  onChange={paymentSlipHandler}
                />
              </Button>
            </FormGroup>
          </MuiGrid>

          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="total"
                label="Total"
                size="small"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ m: 1 }}
                type="submit"
              >
                Save Record
              </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <Button variant="outlined" sx={{ m: 1 }} onClick={reset}>
              Clear
            </Button>
          </MuiGrid>
        </MuiGrid>
      </form>
      {loading ? (
        <Loader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table
            sx={{ minWidth: 650, mt: 1 }}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ m: 1 }}>
                <TableCell>Project Name</TableCell>
                <TableCell align="left">SL outsource category </TableCell>
                <TableCell align="left">Invoice</TableCell>
                <TableCell align="left">Invoice person</TableCell>
                <TableCell align="left">Invoice date</TableCell>
                <TableCell align="left">Paid date</TableCell>
                <TableCell align="left">Paid staff</TableCell>
                <TableCell align="left">Sl Developers</TableCell>
                <TableCell align="left">Payment method</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Paid slip</TableCell>
                <TableCell align="left">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sloutsourcecosts?.map((sloutsourcecost) => (
                <TableRow
                  onClick={() => handleItemClick(sloutsourcecost)}
                  key={sloutsourcecost._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {" "}
                    {sloutsourcecost.Project[0].ProjectName}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.Sloutsourcecategories[0].SlOutSourceCategoryName}
                  </TableCell>
                  <TableCell align="left"><Link
                      color="inherit"
                      underline="none"
                      target="_blank"
                      href={
                        window.location.origin + path + sloutsourcecost.PaidSlip
                      }
                    >
                      {sloutsourcecost.Invoice}
                    </Link></TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.InvoicePreson}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.InvoiceDate.split("T")[0]}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.PaidDate.split("T")[0]}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.PaidStaffs[0].JpStaffName}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.SlDevelopersList[0].SlDeveloperName}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.PaidMethodList[0].PaymentName}
                  </TableCell>
                  <TableCell align="left">
                    {sloutsourcecost.Description}
                  </TableCell>
                  <TableCell align="left">
                  <Link
                      color="inherit"
                      underline="none"
                      target="_blank"
                      href={
                        window.location.origin + path + sloutsourcecost.PaidSlip
                      }
                    >
                      {sloutsourcecost.PaidSlip}
                    </Link></TableCell>
                  <TableCell align="left">{sloutsourcecost.Total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
