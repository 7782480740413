import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { listUsers } from "../redux/actions/userActions";
import { listJpOutSources } from "../redux/actions/jpOutsourceActions";
import { listPayment } from "../redux/actions/paymentActions";
import { listJpOutSourceCategories } from "../redux/actions/jpOutsourceCategoryActions";
import { JpOutSourceCostRegister } from "../redux/actions/jpOutsourceCostActions";
import { listJpOutSourceCosts } from "../redux/actions/jpOutsourceCostActions";
import { updateJpOutSourceCost } from "../redux/actions/jpOutsourceCostActions";
import { listJpStaff } from "../redux/actions/jpStaffActions";
import { JP_OUT_SOURCE_COST_UPDATE_RESET } from "../redux/constants/jpOutsourceCostConstants";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Message from "../Components/Message";
import Loader from "../Components/Loader";
import { SetRoute } from "../redux/actions/routeActions";
import Link from "@mui/material/Link";


export default function JpOutsourseTable({ project }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  var path = "/uploads/JpOutSourceFiles/";

  //-----------------------JP OUTSOURCE CATEGORY LIST------------------------------------
  const jpOutSourceCategoryList = useSelector(
    (state) => state.jpOutSourceCategoryList
  );
  const { jpOutSourcesCategories } = jpOutSourceCategoryList;

  //--------------------JP_OUTSOURCE_LIST------------------------------
  const jpOutSourceList = useSelector((state) => state.jpOutSourceList);
  const { jpOutSources } = jpOutSourceList;

  //-----------------------PAYMENT LIST------------------------------------
  const paymentList = useSelector((state) => state.paymentList);
  const { payments } = paymentList;

  // //-----------------------JPOUTSOURCE COST LIST------------------------------------
  const jpOutSourceCostList = useSelector((state) => state.jpOutSourceCostList);
  const { loading, error, jpOutSourceCosts } = jpOutSourceCostList;

  //-----------------------JP STAFF MASTER LIST------------------------------------
  const jpStaffListDetails = useSelector((state) => state.jpStaffList);
  const { jpStaffs } = jpStaffListDetails;

  const [id, setId] = useState("");
  const [jpoutsourcescategory, setJpoutsourcescategory] = useState([]);
  const [invoice, setInvoice] = useState();
  const [invoiceperson, setInvoiceperson] = useState("");
  const [invoicedate, setInvoiveDate] = useState(new Date(Date.now()));
  const [paiddate, setPaiddate] = useState(new Date(Date.now()));
  const [paidstaff, setPaidstaff] = useState([]);
  const [jpoutsource, setJpoutsource] = useState([]);
  const [paymentmethod, setPaymentmethod] = useState([]);
  const [description, setDescription] = useState("");
  const [paymentslip, setPaymentslip] = useState("");
  const [total, setTotal] = useState("");

  const handleItemClick = (jpOutsourceCost) => {
    setId(jpOutsourceCost._id);
    setJpoutsourcescategory(jpOutsourceCost.JpoutsourceCategoryInID);
    //setInvoice(jpOutsourceCost.Invoice);
    setInvoiceperson(jpOutsourceCost.InvoicePreson);
    setInvoiveDate(jpOutsourceCost.InvoiceDate);
    setPaiddate(jpOutsourceCost.PaidDate);
    setPaidstaff(jpOutsourceCost.PaidStaff);
    setJpoutsource(jpOutsourceCost.JpOutsource);
    setPaymentmethod(jpOutsourceCost.PaidMethodInID);
    setDescription(jpOutsourceCost.Description);
    // setPaymentslip(jpOutsourceCost.PaidSlip);
    setTotal(jpOutsourceCost.Total);
  };

  const reset = () => {
    setId("");
    setJpoutsourcescategory([]);
    setInvoice([]);
    setInvoiceperson("");
    setInvoiveDate(Date.now());
    setPaiddate(Date.now());
    setPaidstaff([]);
    setJpoutsource([]);
    setPaymentmethod([]);
    setDescription("");
    setPaymentslip(null);
    setTotal([]);
  };

  const handleChangejpoutsourceInJpoutsourceTable = (event) => {
    setJpoutsource(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeJpOutsourcescategory = (event) => {
    setJpoutsourcescategory(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangejpOutsourcepaidStaff = (event) => {
    setPaidstaff(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeJpoutsourcecostPayment = (event) => {
    setPaymentmethod(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeInvoiceDate = (newValue) => {
    setInvoiveDate(newValue.format("YYYY/MM/DD"));
  };

  const handleChangePaidDate = (newValue) => {
    setPaiddate(newValue.format("YYYY/MM/DD"));
  };

  const changeHandler = (e) => {
    if (e.target.files.length > 0) {
      setInvoice(e.target.files[0]);
    }
  };

  const paymentSlipHandler = (e) => {
    if (e.target.files.length > 0) {
      setPaymentslip(e.target.files[0]);
    }
  };

  const update = useSelector((state) => state.costCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  const submitUpdate = (invoiceURL, paymentURL) => {
    dispatch(
      updateJpOutSourceCost({
        _id: id,
        JpoutsourceCategoryInID: jpoutsourcescategory,
        Invoice: invoiceURL,
        InvoicePreson: invoiceperson,
        InvoiceDate: invoicedate,
        PaidDate: paiddate,
        PaidStaff: paidstaff,
        JpOutsource: jpoutsource,
        PaidMethodInID: paymentmethod,
        Description: description,
        PaidSlip: paymentURL,
        Total: total,
      })
    );
  };

  const register = (invoiceURL, paymentURL) => {
    dispatch(
      JpOutSourceCostRegister({
        project,
        jpoutsourcescategory,
        invoiceURL,
        invoiceperson,
        invoicedate,
        paiddate,
        paidstaff,
        jpoutsource,
        paymentmethod,
        description,
        paymentURL,
        total,
      })
    );
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (jpoutsourcescategory.length === 0) {
      setMessage("Select at least One Jp Out Sousrce Category");
    } else if (invoice === "") {
      setMessage("Invoice Is Empty");
    } else if (invoiceperson === "") {
      setMessage("Invoced Person Is Empty");
    } else if (invoicedate === "") {
      setMessage("Jp Development Is Empty");
    } else if (jpoutsource === "") {
      setMessage("Jp Outsource Is Empty");
    } else if (paidstaff.length === 0) {
      setMessage("Select at least One Staff");
    } else if (paymentmethod === "") {
      setMessage("Select a Payment Method");
    } else if (jpoutsource.length === 0) {
      setMessage("Select at least One Jp Out Ousrce");
    } else if (description === "") {
      setMessage("Description is Empty");
    } else if (total === "") {
      setMessage("Pleace Enter Total Value");
    } else if (id === "") {
      let url = "/upload.php";
      const path = "uploads/JpOutSourceFiles/";

      const data1 = new FormData();
      const data2 = new FormData();
      data1.append("file", invoice);
      data1.append("filepath", path);
      data2.append("file", paymentslip);
      data2.append("filepath", path);

      axios
        .all([axios.post(url, data1), axios.post(url, data2)])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            register(firstResponse.data, secondResponse.data);
            reset();
          })
        )
        .catch((error) => console.log(error));
    } else {
      let url = "/upload.php";
      const path = "uploads/JpOutSourceFiles/";

      const data1 = new FormData();
      const data2 = new FormData();
      data1.append("file", invoice);
      data1.append("filepath", path);
      data2.append("file", paymentslip);
      data2.append("filepath", path);

      axios
        .all([axios.post(url, data1), axios.post(url, data2)])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            submitUpdate(firstResponse.data, secondResponse.data);
            reset();
          })
        )
        .catch((error) => console.log(error));
    }

    dispatch(listJpOutSourceCosts(project));
  };

  useEffect(() => { 
    dispatch(listUsers());
    dispatch(listJpOutSources());
    dispatch(listPayment());
    dispatch(listJpStaff());
    dispatch(listJpOutSourceCategories());
    dispatch(listJpOutSourceCosts(project));
    dispatch(SetRoute("JP Outsource"));
    if (successUpdate) {
      dispatch({ type: JP_OUT_SOURCE_COST_UPDATE_RESET });
    }
  }, [project, dispatch, successUpdate]);

  return (
    <>
      <form onSubmit={SubmitHandler}>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}
        <MuiGrid container sx={{ p: 1, bgcolor: "#Ffffff" }}>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  JpOutsource Category
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  value={jpoutsourcescategory}
                  onChange={handleChangeJpOutsourcescategory}
                  label="Payment Method"
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {jpOutSourcesCategories?.map((jpoutsourcescategory) => (
                    <MenuItem
                      value={jpoutsourcescategory._id}
                      key={jpoutsourcescategory._id}
                    >
                      {" "}
                      {jpoutsourcescategory.JpOutsourceCategoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                style={{
                  maxHeight: "45px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
                sx={{ m: 0.7 }}
                variant="text"
                component="label"
                size="small"
              >
                Invoice File
                <TextField
                  hidden
                  multiple
                  type="file"
                  name="invoice"
                  size="small"
                  onChange={changeHandler}
                />
              </Button>
            </FormGroup>
          </MuiGrid>

          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        margin="normal"
                        size="small"
                        {...props}
                        sx={{ m: 1 }}
                      />
                    )}
                    label="Invoice Date"
                    value={invoicedate}
                    size="small"
                    onChange={(invoicedate) =>
                      handleChangeInvoiceDate(invoicedate)
                    }
                    inputFormat="YYYY/MM/DD"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        margin="normal"
                        size="small"
                        {...props}
                        sx={{ m: 1 }}
                      />
                    )}
                    label="Paid Date"
                    value={paiddate}
                    onChange={(paiddate) => handleChangePaidDate(paiddate)}
                    inputFormat="YYYY/MM/DD"
                  />
                </LocalizationProvider>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="invoiceperson"
                label="Invoice Person"
                size="small"
                value={invoiceperson}
                onChange={(e) => setInvoiceperson(e.target.value)}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Paid Staff
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  value={paidstaff}
                  size="small"
                  onChange={handleChangejpOutsourcepaidStaff}
                  label="jp Staff"
                >
                  <MenuItem value="" key={1}>
                    <em>None</em>
                  </MenuItem>
                  {jpStaffs.map((jpStaff) => (
                    <MenuItem value={jpStaff._id} key={jpStaff._id}>
                      {" "}
                      {jpStaff.JpStaffName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Payment Method
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={paymentmethod}
                  onChange={handleChangeJpoutsourcecostPayment}
                  label="Payment Method"
                >
                  <MenuItem value="" key={1}>
                    <em>None</em>
                  </MenuItem>
                  {payments?.map((payment) => (
                    <MenuItem value={payment._id} key={payment._id}>
                      {" "}
                      {payment.PaymentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Jp outsource
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={jpoutsource}
                  onChange={handleChangejpoutsourceInJpoutsourceTable}
                  label="Jp outsource"
                >
                  <MenuItem value="" key={1}>
                    <em>None</em>
                  </MenuItem>
                  {jpOutSources.map((jpOutSource) => (
                    <MenuItem value={jpOutSource._id} key={jpOutSource._id}>
                      {" "}
                      {jpOutSource.JpOutsourceName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="description"
                size="small"
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Button
                style={{
                  maxHeight: "45px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
                sx={{ m: 0.7 }}
                variant="text"
                component="label"
                size="small"
              >
                Payment Slip
                <TextField
                  accept="image/* "
                  hidden
                  multiple
                  type="file"
                  size="small"
                  onChange={paymentSlipHandler}
                />
              </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="total"
                size="small"
                label="Total"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ m: 1 }}
                type="submit"
              >
                Save Record
              </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <Button variant="outlined" sx={{ m: 1 }} onClick={reset}>
              Clear
            </Button>
          </MuiGrid>
        </MuiGrid>
      </form>
      {loading ? (
        <Loader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table
            sx={{ minWidth: 650, mt: 2 }}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ m: 1 }}>
                <TableCell>Project Name</TableCell>
                <TableCell align="left">Jp Outsource Category </TableCell>
                <TableCell align="left">Invoice</TableCell>
                <TableCell align="left">Invoice Person</TableCell>
                <TableCell align="left">Invoice Date</TableCell>
                <TableCell align="left">Paid Date</TableCell>
                <TableCell align="left">Paid Staff</TableCell>
                <TableCell align="left">Jp Outsource</TableCell>
                <TableCell align="left">Payment Method</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Paid Slip</TableCell>
                <TableCell align="left">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jpOutSourceCosts?.map((jpOutsourceCost) => (
                <TableRow
                  onClick={() => handleItemClick(jpOutsourceCost)}
                  key={jpOutsourceCost._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {" "}
                    {jpOutsourceCost.Project[0].ProjectName}
                  </TableCell>
                  <TableCell align="left">
                    {
                      jpOutsourceCost.jpoutsourcecategoriesList[0]
                        .JpOutsourceCategoryName
                    }
                  </TableCell>
                  <TableCell align="left">
                    <Link
                      color="inherit"
                      underline="none"
                      target="_blank"
                      href={
                        window.location.origin + path + jpOutsourceCost.Invoice
                      }
                    >
                      {jpOutsourceCost.Invoice}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.InvoicePreson}
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.InvoiceDate.split("T")[0]}
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.PaidDate.split("T")[0]}
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.PaidStaffs[0].JpStaffName}
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.JpOutsourceList[0].JpOutsourceName}
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.PaidMethodList[0].PaymentName}
                  </TableCell>
                  <TableCell align="left">
                    {jpOutsourceCost.Description}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Link
                      color="inherit"
                      underline="none"
                      target="_blank"
                      href={
                        window.location.origin + path + jpOutsourceCost.PaidSlip
                      }
                    >
                      {jpOutsourceCost.PaidSlip}
                    </Link>
                  </TableCell>                  
                  <TableCell align="left">{jpOutsourceCost.Total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
