import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "../redux/actions/userActions";
import { listJpOutSources } from "../redux/actions/jpOutsourceActions";
import { listPayment } from "../redux/actions/paymentActions";
import { otherCostRegister } from "../redux/actions/otherCostActions";
import { listOtherCost } from "../redux/actions/otherCostActions";
import { updateOtherCost } from "../redux/actions/otherCostActions";
import { listOtherCategory } from "../redux/actions/otherCategoryActions";
import { JP_OUT_SOURCE_COST_UPDATE_RESET } from "../redux/constants/jpOutsourceCostConstants";
import { SetRoute } from "../redux/actions/routeActions";
import Message from "./Message";
import Loader from "./Loader";
import axios from 'axios';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";

export default function OtherTable({ project }) {
  const dispatch = useDispatch(); 
  var path = "/uploads/OtherCostFiles/";
  const [message, setMessage] = useState(null);

  //----------------------Other CATEGORY LIST------------------------------------
  const otherCategoriesList = useSelector((state) => state.otherCategorysList);
  const { otherCategorys } = otherCategoriesList;

  //--------------------JP_OUTSOURCE_LIST------------------------------
  const jpOutSourceList = useSelector((state) => state.jpOutSourceList);
  const { jpOutSources } = jpOutSourceList;

  //-----------------------PAYMENT LIST------------------------------------
  const paymentList = useSelector((state) => state.paymentList);
  const { payments } = paymentList;

  //-----------------------OTHER COST LIST------------------------------------
  const otherCostList = useSelector((state) => state.otherCostList);
  const { loading, error, otherCosts } = otherCostList;


  //-----------------------JP STAFF MASTER LIST------------------------------------
  const jpStaffListDetails = useSelector((state) => state.jpStaffList);
  const { jpStaffs } = jpStaffListDetails;


  const [id, setId] = useState("");
  const [jpoutsource, setJpoutsource] = useState([]);
  const [paymentmethod, setPaymentmethod] = useState([]);
  const [othercostscategory, setOthercostscategory] = useState([]);
  const [invoice, setInvoice] = useState("");
  const [invoiceperson, setInvoiceperson] = useState("");
  const [paidstaff, setPaidstaff] = useState([]);
  const [description, setDescription] = useState("");
  const [paymentslip, setPaymentslip] = useState("");
  const [total, setTotal] = useState("");
  const [invoicedate, setInvoiveDate] = useState(Date.now());
  const [paiddate, setPaiddate] = useState(Date.now());

  const handleItemClick = (otherCost) => {
    setId(otherCost._id);
    setOthercostscategory(otherCost.OtherCategoryInID);
    setInvoice(otherCost.Invoice);
    setInvoiceperson(otherCost.InvoicePreson);
    setInvoiveDate(otherCost.InvoiceDate);
    setPaiddate(otherCost.PaidDate);
    setPaidstaff(otherCost.PaidStaff);
    setJpoutsource(otherCost.JpOutsource);
    setPaymentmethod(otherCost.PaidMethodInID);
    setDescription(otherCost.Description);
    // setPaymentslip(otherCost.PaidSlip);
    setTotal(otherCost.Total);
  };

  const reset = () => {
    setId("");
    setOthercostscategory([]);
    setInvoice("");
    setInvoiceperson("");
    setInvoiveDate(Date.now());
    setPaiddate(Date.now());
    setPaidstaff([]);
    setJpoutsource([]);
    setPaymentmethod([]);
    setDescription("");
    setPaymentslip("");
    setTotal("");
  };

  const handleChangejpoutsourceInOtherTable = (event) => {
    setJpoutsource(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeothercostscategory = (event) => {
    setOthercostscategory(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeotherpaidStaff = (event) => {
    setPaidstaff(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangePayment = (event) => {
    setPaymentmethod(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleChangeInvoiceDate = (newValue) => {
    setInvoiveDate(newValue.format("YYYY/MM/DD"));
  };
  const handleChangePaidDate = (newValue) => {
    setPaiddate(newValue.format("YYYY/MM/DD"));
  };

  
  const changeHandler=(e)=>{
    if (e.target.files.length > 0) {
      setInvoice(e.target.files[0])  
    
    }
  }
  
  const paymentSlipHandler=(e)=>{
    if (e.target.files.length > 0) {
      setPaymentslip(e.target.files[0])
     
      
    }
  }

  const update = useSelector((state) => state.costCategoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  const submitUpdate = (invoicename,paymentslipname) => {
    dispatch(
      updateOtherCost({
        _id: id,
        othercostscategory: othercostscategory,
        invoice: invoicename,
        invoicePreson: invoiceperson,
        invoiceDate: invoicedate,
        paidDate: paiddate,
        paidStaff: paidstaff,
        jpOutsource: jpoutsource,
        paidMethodInID: paymentmethod,
        description: description,
        paidSlip: paymentslipname,
        total: total,
      })
    );
  };

  const register = (invoicename,paymentslipname) => {
    dispatch(
      otherCostRegister({
        project,
        othercostscategory,
        invoicename,
        invoiceperson,
        invoicedate,
        paiddate,
        paidstaff,
        jpoutsource,
        paymentmethod,
        description,
        paymentslipname,
        total,
      })
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (othercostscategory.length === 0) {
      setMessage("Select at least One Other Category");
    } else if (invoice === "") {
      setMessage("Invoice Is Empty");
    } else if (invoiceperson === "") {
      setMessage("Invoced Person Is Empty");
    } else if (invoicedate === "") {
      setMessage("Jp Development Is Empty");
    } else  if (paidstaff.length === 0) {
      setMessage("Select at least One Staff");
    } else if (paymentmethod === "") {
      setMessage("Select a Payment Method");
    } else if (jpoutsource.length === 0) {
      setMessage("Select at least One Jp Out Ousrce");
    } else if (description === "") {
      setMessage("Description is Empty");
    } else if (total === "") {
      setMessage("Pleace Enter Total Value");
    } else if (id === "") {   
      let url = "/upload.php"; 
      const path = "uploads/OtherCostFiles/";

      const data1 = new FormData();
      const data2 = new FormData();
      data1.append("file", invoice);
      data1.append("filepath", path);
      data2.append("file", paymentslip);
      data2.append("filepath", path);

      axios
        .all([axios.post(url, data1), axios.post(url, data2)])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            register(firstResponse.data, secondResponse.data);
            reset();
          })
        )
        .catch((error) => console.log(error));
    } else {     
      let url = "/upload.php";
      const path = "uploads/OtherCostFiles/";

      const data1 = new FormData();
      const data2 = new FormData();
      data1.append("file", invoice);
      data1.append("filepath", path);
      data2.append("file", paymentslip);
      data2.append("filepath", path);

      axios
        .all([axios.post(url, data1), axios.post(url, data2)])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            submitUpdate(firstResponse.data, secondResponse.data);
            reset();
          })
        )
        .catch((error) => console.log(error));
    }
    dispatch(listOtherCost(project));
  };

  useEffect(() => {
    dispatch(listUsers());
    dispatch(listJpOutSources());
    dispatch(listPayment());
    dispatch(listOtherCategory());
    dispatch(listOtherCost(project));
    dispatch(SetRoute("Other Costs"));
    if (successUpdate) {
      console.log("successUpdate");
      dispatch({ type: JP_OUT_SOURCE_COST_UPDATE_RESET });
    }
  }, [project, dispatch, successUpdate]);

  return (
    <>
      <form onSubmit={submitHandler}>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}
        <MuiGrid container sx={{ p: 1, bgcolor: "#Ffffff" }}>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Other Category
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={othercostscategory}
                  onChange={handleChangeothercostscategory}
                  label=" Other Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {otherCategorys.map((OtherCategory) => (
                    <MenuItem value={OtherCategory._id} key={OtherCategory._id}>
                      {" "}
                      {OtherCategory.OtherCategoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>             
              <Button
                style={{
                  maxHeight: "45px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
                sx={{ m: 0.7 }}
                variant="text"
                component="label"
                size="small"
              >
              Invoice File
              <TextField
                   sx={{ m: 1 }}
                  hidden
                  multiple
                  type="file"
                  size="small"                               
                  onChange={changeHandler}                
                /> 
                </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        margin="normal"
                        size="small"
                        {...props}
                        sx={{ m: 1 }}
                      />
                    )}
                    label="Invoice Date"
                    value={invoicedate}
                    onChange={(invoicedate) =>
                      handleChangeInvoiceDate(invoicedate)
                    }
                    inputFormat="YYYY/MM/DD"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        margin="normal"
                        size="small"
                        {...props}
                        sx={{ m: 1 }}
                      />
                    )}
                    label="Paid Date"
                    value={paiddate}
                    onChange={(paiddate) => handleChangePaidDate(paiddate)}
                    inputFormat="YYYY/MM/DD"
                  />
                </LocalizationProvider>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="invoiceperson"
                label="Invoice Person"
                size="small"
                value={invoiceperson}
                onChange={(e) => setInvoiceperson(e.target.value)}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Paid Staff
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  size="small"
                  value={paidstaff}
                  onChange={handleChangeotherpaidStaff}
                  label=" Other Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {jpStaffs.map((jpStaff) => (
                    <MenuItem value={jpStaff._id} key={jpStaff._id}>
                      {" "}
                      {jpStaff.JpStaffName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Jp outsource
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  value={jpoutsource}
                  size="small"
                  onChange={handleChangejpoutsourceInOtherTable}
                  label="Jp outsource"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {jpOutSources?.map((jpOutSource) => (
                    <MenuItem value={jpOutSource._id} key={jpOutSource._id}>
                      {" "}
                      {jpOutSource.JpOutsourceName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Payment Method
                </InputLabel>
                <Select
                  sx={{ m: 1 }}
                  multiple
                  value={paymentmethod}
                  size="small"
                  onChange={handleChangePayment}
                  label="Payment Method"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {payments.map((payment) => (
                    <MenuItem value={payment._id} key={payment._id}>
                      {" "}
                      {payment.PaymentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                size="small"
                name="description"
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />              
              <Button
                style={{
                  maxHeight: "45px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
                sx={{ m: 0.7 }}
                variant="text"
                component="label"
                size="small"
              >
                Payment Slip
                <TextField
                  accept="image/* "
                  hidden
                  multiple
                  type="file"
                  size="small"                 
                    onChange={paymentSlipHandler}               
                />
              </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <FormGroup>
              <TextField
                sx={{ m: 1 }}
                name="total"
                size="small"
                label="Total"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ m: 1 }}
                type="submit"
              >
                Save Record
              </Button>
            </FormGroup>
          </MuiGrid>
          <MuiGrid item xs={2}>
            <Button variant="outlined" sx={{ m: 1 }} onClick={reset}>
              Clear
            </Button>
          </MuiGrid>
        </MuiGrid>
      </form>
      {loading ? (
        <Loader />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table
            sx={{ minWidth: 650, mt: 1 }}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ m: 1 }}>
                <TableCell>Project Name</TableCell>
                <TableCell align="left">Other Category </TableCell>
                <TableCell align="left">Invoice </TableCell>               
                <TableCell align="left">Invoice Person</TableCell>
                <TableCell align="left">Invoice Date</TableCell>
                <TableCell align="left">Paid Staff</TableCell>
                <TableCell align="left">Paid Date</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Jp Outsource</TableCell>
                <TableCell align="left">Payment Method</TableCell>               
                <TableCell align="left">Paid Slip</TableCell>   
                <TableCell align="left">Total</TableCell>             
              </TableRow>
            </TableHead>
            <TableBody>
              {otherCosts?.map((otherCost) => (
                <TableRow
                  onClick={() => handleItemClick(otherCost)}
                  key={otherCost._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {" "}
                    {otherCost.Project[0].ProjectName}
                  </TableCell>
                  <TableCell align="left">
                    {otherCost.othercategoriesList[0].OtherCategoryName}
                  </TableCell>
                  <TableCell align="left"><Link
                      color="inherit"
                      underline="none"
                      target="_blank"
                      href={
                        window.location.origin + path + otherCost.Invoice
                      }
                    >
                      {otherCost.Invoice}
                    </Link></TableCell>                
                  <TableCell align="left">{otherCost.InvoicePreson}</TableCell>
                  <TableCell align="left">
                    {otherCost.InvoiceDate.split("T")[0]}
                  </TableCell>
                  <TableCell align="left">{otherCost.PaidStaffs[0].JpStaffName}</TableCell>
                  <TableCell align="left">
                    {otherCost.PaidDate.split("T")[0]}
                  </TableCell>
                  <TableCell align="left">{otherCost.Description}</TableCell>
                  <TableCell align="left">{otherCost.JpOutsourceList[0].JpOutsourceName}</TableCell>
                  <TableCell align="left">{otherCost.PaidMethodList[0].PaymentName}</TableCell>
                  <TableCell align="left"><Link
                      color="inherit"
                      underline="none"
                      target="_blank"
                      href={
                        window.location.origin + path + otherCost.PaidSlip
                      }
                    >
                      {otherCost.PaidSlip}
                    </Link></TableCell>
                  <TableCell align="left">{otherCost.Total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
