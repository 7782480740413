import {
    SALES_STAFF_LIST_REQUEST,
    SALES_STAFF_LIST_SUCCESS,
    SALES_STAFF_LIST_FAIL,
    SALES_STAFF_DETAILS_REQUEST,
    SALES_STAFF_DETAILS_SUCCESS,
    SALES_STAFF_DETAILS_FAIL,
    SALES_STAFF_REGISTER_REQUEST,
    SALES_STAFF_REGISTER_SUCCESS,
    SALES_STAFF_REGISTER_FAIL,
    SALES_STAFF_DELETE_REQUEST,
    SALES_STAFF_DELETE_SUCCESS,
    SALES_STAFF_DELETE_FAIL,
    SALES_STAFF_UPDATE_REQUEST,
    SALES_STAFF_UPDATE_SUCCESS,
    SALES_STAFF_UPDATE_FAIL,
    SALES_STAFF_UPDATE_RESET,
  } from "../constants/salesStaffConstants";
  
  export const salesStaffListReducer = (state = { salesStaffs: [] }, action) => {
    switch (action.type) {
      case SALES_STAFF_LIST_REQUEST:
        return { loading: true, salesStaffs: [] };
      case SALES_STAFF_LIST_SUCCESS:
        return { loading: false, salesStaffs: action.payload };
      case SALES_STAFF_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const salesStaffByIdReducer = (state = { salesStaff: [] }, action) => {
    switch (action.type) {
      case SALES_STAFF_DETAILS_REQUEST:
        return { loading: true, ...state };
      case SALES_STAFF_DETAILS_SUCCESS:
        return { loading: false, salesStaff: action.payload };
      case SALES_STAFF_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const salesStaffRegisterReducer = (state = { salesStaff: [] }, action) => {
    switch (action.type) {
      case SALES_STAFF_REGISTER_REQUEST:
        return { loading: true, salesStaff: [] };
      case SALES_STAFF_REGISTER_SUCCESS:
        return { loading: false, salesStaffInfo: action.payload };
      case SALES_STAFF_REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const salesStaffDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SALES_STAFF_DELETE_REQUEST:
        return { loading: true, salesStaff: [] };
      case SALES_STAFF_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SALES_STAFF_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const salesStaffUpdateReducer = (state = { salesStaff: {} }, action) => {
    switch (action.type) {
      case SALES_STAFF_UPDATE_REQUEST:
        return { loading: true };
      case SALES_STAFF_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case SALES_STAFF_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case SALES_STAFF_UPDATE_RESET:
        return { salesStaff: {} };
      default:
        return state;
    }
  };
  