import {
  USER_ROLE_LIST_REQUEST,
  USER_ROLE_LIST_SUCCESS,
  USER_ROLE_LIST_FAIL,
  USER_ROLE_DETAILS_REQUEST,
  USER_ROLE_DETAILS_SUCCESS,
  USER_ROLE_DETAILS_FAIL,
  USER_ROLE_REGISTER_REQUEST,
  USER_ROLE_REGISTER_SUCCESS,
  USER_ROLE_REGISTER_FAIL,
  USER_ROLE_DELETE_REQUEST,
  USER_ROLE_DELETE_SUCCESS,
  USER_ROLE_DELETE_FAIL,
  USER_ROLE_UPDATE_REQUEST,
  USER_ROLE_UPDATE_SUCCESS,
  USER_ROLE_UPDATE_FAIL,
  USER_ROLE_UPDATE_RESET,
} from "../constants/userRoleConstant";

export const userRoleListReducer = (state = { userRoles: [] }, action) => {
  switch (action.type) {
    case USER_ROLE_LIST_REQUEST:
      return { loading: true, userRoles: [] };
    case USER_ROLE_LIST_SUCCESS:
      return { loading: false, userRoles: action.payload };
    case USER_ROLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRoleByIdReducer = (state = { userRoleById: [] }, action) => {
  switch (action.type) {
    case USER_ROLE_DETAILS_REQUEST:
      return { loading: true, ...state };
    case USER_ROLE_DETAILS_SUCCESS:
      return { loading: false, userRoleById: action.payload };
    case USER_ROLE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRoleRegisterReducer = (state = { userRole: [] }, action) => {
  switch (action.type) {
    case USER_ROLE_REGISTER_REQUEST:
      return { loading: true, userRole: [] };
    case USER_ROLE_REGISTER_SUCCESS:
      return { loading: false, userRoleInfo: action.payload };
    case USER_ROLE_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRoleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ROLE_DELETE_REQUEST:
      return { loading: true, userRole: [] };
    case USER_ROLE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_ROLE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRoleUpdateReducer = (state = { userRole: {} }, action) => {
  switch (action.type) {
    case USER_ROLE_UPDATE_REQUEST:
      return { loading: true };
    case USER_ROLE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_ROLE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_ROLE_UPDATE_RESET:
      return { userRole: {} };
    default:
      return state;
  }
};
