import {
  MASTER_LIST_REQUEST,
  MASTER_LIST_SUCCESS,
  MASTER_LIST_FAIL,
  TAB_LIST_REQUEST,
  TAB_LIST_SUCCESS,
  TAB_LIST_FAIL,
} from "../constants/masterConstants";

export const mastersListReducer = (state = { masters: [] }, action) => {
  switch (action.type) {
    case MASTER_LIST_REQUEST:
      return { loading: true, masters: [] };
    case MASTER_LIST_SUCCESS:
      return { loading: false, masters: action.payload };
    case MASTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tabListReducer = (state = { tabs: [] }, action) => {
  switch (action.type) {
    case TAB_LIST_REQUEST:
      return { loading: true, tabs: [] };
    case TAB_LIST_SUCCESS:
      return { loading: false, tabs: action.payload };
    case TAB_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
