import {
  SL_DEVELOPER_LIST_REQUEST,
  SL_DEVELOPER_LIST_SUCCESS,
  SL_DEVELOPER_LIST_FAIL,
  SL_DEVELOPER_DETAILS_REQUEST,
  SL_DEVELOPER_DETAILS_SUCCESS,
  SL_DEVELOPER_DETAILS_FAIL,
  SL_DEVELOPER_REGISTER_REQUEST,
  SL_DEVELOPER_REGISTER_SUCCESS,
  SL_DEVELOPER_REGISTER_FAIL,
  SL_DEVELOPER_DELETE_REQUEST,
  SL_DEVELOPER_DELETE_SUCCESS,
  SL_DEVELOPER_DELETE_FAIL,
  SL_DEVELOPER_UPDATE_REQUEST,
  SL_DEVELOPER_UPDATE_SUCCESS,
  SL_DEVELOPER_UPDATE_FAIL,
} from "../constants/slDeveloperConstants";
import * as api from "../api/index";

export const listSlDeveloper = () => async (dispatch) => {
  try {
    dispatch({ type: SL_DEVELOPER_LIST_REQUEST });

    const { data } = await api.listSlDeveloperAPI();

    dispatch({
      type: SL_DEVELOPER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_DEVELOPER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSlDeveloperById = (id) => async (dispatch) => {
  try {
    dispatch({ type: SL_DEVELOPER_DETAILS_REQUEST });

    const { data } = await api.SlDeveloperByIdAPI(id);
    dispatch({
      type: SL_DEVELOPER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_DEVELOPER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const slDeveloperRegister =
  (slDeveloperName, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SL_DEVELOPER_REGISTER_REQUEST,
      });
      const { data } = await api.SlDeveloperRegisterAPI(slDeveloperName);

      dispatch({
        type: SL_DEVELOPER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SL_DEVELOPER_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/sldeveloper");
    } catch (error) {
      dispatch({
        type: SL_DEVELOPER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSlDeveloper = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SL_DEVELOPER_DELETE_REQUEST,
    });

    await api.SlDeveloperDeleteAPI(id);

    dispatch({
      type: SL_DEVELOPER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SL_DEVELOPER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSlDeveloper =
  (slDeveloper, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: SL_DEVELOPER_UPDATE_REQUEST,
      });

      const { data } = await api.SlDeveloperUpdateAPI(slDeveloper);

      dispatch({
        type: SL_DEVELOPER_UPDATE_SUCCESS,
      });

      dispatch({
        type: SL_DEVELOPER_DETAILS_SUCCESS,
        payload: data,
      });

      navigate("/sldeveloper");
    } catch (error) {
      dispatch({
        type: SL_DEVELOPER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const searchSlDeveloper = (slDeveloperName) => async (dispatch) => {
  try {
    dispatch({ type: SL_DEVELOPER_LIST_REQUEST });

    const { data } = await api.SlDeveloperSearchAPI(slDeveloperName);

    dispatch({
      type: SL_DEVELOPER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SL_DEVELOPER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
