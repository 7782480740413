import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getJpStaffById,
  updateJpStaff,
} from "../../redux/actions/jpStaffActions";
import { JP_STAFF_UPDATE_RESET } from "../../redux/constants/jpStaffConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function JpStaffEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jpStaffId = useParams();

  const [id, setId] = useState("");
  const [jpStaffName, setjpStaffName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.jpStaffById);
  const { loading, error, jpStaffById } = Details;

  const update = useSelector((state) => state.jpStaffUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update;

  useEffect(() => {
    dispatch(SetRoute("Jp Staff Edit"));
    if (successUpdate) {
      dispatch({ type: JP_STAFF_UPDATE_RESET });
    } else {
      if (!jpStaffById.JpStaffName || jpStaffById._id !== jpStaffId.id) {
        dispatch(getJpStaffById(jpStaffId.id));
      } else {
        setId(jpStaffById._id);
        setjpStaffName(jpStaffById.JpStaffName);
      }
    }
  }, [dispatch, successUpdate, jpStaffById, jpStaffId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (jpStaffName === "") {
      setMessage("Jp Staff Name Is Empty");
    } else {
      dispatch(
        updateJpStaff(
          {
            _id: id,
            name: jpStaffName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                size="small"
                margin="normal"
                label="JpStaffName"
                name="Jp Staff Name"
                value={jpStaffName}
                onChange={(e) => setjpStaffName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default JpStaffEditScreen;
