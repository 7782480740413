import {
  USER_ROLE_LIST_REQUEST,
  USER_ROLE_LIST_SUCCESS,
  USER_ROLE_LIST_FAIL,
  USER_ROLE_DETAILS_REQUEST,
  USER_ROLE_DETAILS_SUCCESS,
  USER_ROLE_DETAILS_FAIL,
  USER_ROLE_REGISTER_REQUEST,
  USER_ROLE_REGISTER_SUCCESS,
  USER_ROLE_REGISTER_FAIL,
  USER_ROLE_DELETE_REQUEST,
  USER_ROLE_DELETE_SUCCESS,
  USER_ROLE_DELETE_FAIL,
  USER_ROLE_UPDATE_REQUEST,
  USER_ROLE_UPDATE_SUCCESS,
  USER_ROLE_UPDATE_FAIL,
} from "../constants/userRoleConstant";
import * as api from "../api/index";

export const listUserRole = () => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_LIST_REQUEST });

    const { data } = await api.listUserRoleAPI();

    dispatch({
      type: USER_ROLE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserRoleById = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_DETAILS_REQUEST });

    const { data } = await api.UserRoleByIdAPI(id);
    dispatch({
      type: USER_ROLE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userRoleRegister = (userRole) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ROLE_REGISTER_REQUEST,
    });
    const { data } = await api.UserRoleRegisterAPI(userRole);

    dispatch({
      type: USER_ROLE_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_ROLE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUserRole = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ROLE_DELETE_REQUEST,
    });

    await api.UserRoleDeleteAPI(id);

    dispatch({
      type: USER_ROLE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserRole = (userRole) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ROLE_UPDATE_REQUEST,
    });

    const { data } = await api.UserRoleUpdateAPI(userRole);

    dispatch({
      type: USER_ROLE_UPDATE_SUCCESS,
    });

    dispatch({
      type: USER_ROLE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
