import axios from "axios";

const API = axios.create({
  baseURL: "https://cost-management-server.herokuapp.com/",
});

 //const API = axios.create({ baseURL: "http://localhost:5000/" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("userInfo")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("userInfo")).token
    }`;
  }
  return req;
});

//USER
// ______________________________________________________________________________________

export const userLoginAPI = (email, password) =>
  API.post(`api/user/login`, { email, password });

export const listUsersAPI = () => API.get("/api/user");

export const listUserDetailsAPI = (id) => API.get(`/api/user/${id}`);

export const RegisterAPI = (data) => API.post("/api/user/", data);

export const DeleteUsersAPI = (id) => API.delete(`/api/user/${id}`);

export const UpdateUsersAPI = (user) => API.put(`/api/user/${user._id}`, user);

export const SearchUserAPI = (userName) =>
  API.post("/api/user/search", { userName });

// USER_ROLE
// ______________________________________________________________________________________

export const listUserRoleAPI = () => API.get("/api/userrole");

export const UserRoleByIdAPI = (id) => API.get(`/api/userrole/${id}`);

export const UserRoleRegisterAPI = (userrole) =>
  API.post("/api/userrole/register", userrole);

export const UserRoleDeleteAPI = (id) => API.delete(`/api/userrole/${id}`);

export const UserRoleUpdateAPI = (userrole) =>
  API.put(`/api/userrole/${userrole._id}`, userrole);

// CATEGORY
// ______________________________________________________________________________________

export const listCategoryAPI = () => API.get("/api/category");

export const CategoryByIdsAPI = (id) => API.get(`/api/category/${id}`);

export const CategoryRegisterAPI = (category) =>
  API.post("/api/category/register", {
    category,
  });

export const CategoryDeleteAPI = (id) => API.delete(`/api/category/${id}`);

export const CategoryUpdateAPI = (category) =>
  API.put(`/api/category/${category._id}`, category);

export const CategorySearchAPI = (categoryName) =>
  API.post("/api/category/search", { categoryName });

// COST CATEGORY
// ______________________________________________________________________________________

export const listCostCategoryAPI = () => API.get("/api/costcategory");

export const CostCategoryByIdAPI = (id) => API.get(`/api/costcategory/${id}`);

export const CostCategoryRegisterAPI = (costCategoryName) =>
  API.post("/api/costcategory/register", {
    costCategoryName,
  });

export const CostCategoryDeleteAPI = (id) =>
  API.delete(`/api/costcategory/${id}`);

export const CostCategoryUpdateAPI = (costCategorys) =>
  API.put(`/api/costcategory/${costCategorys._id}`, costCategorys);

export const CostCategorySearchAPI = (categoryName) =>
  API.post("/api/costcategory/search", { categoryName });

// JP DEVEOLPMENT
// ______________________________________________________________________________________

export const listJpDevelopmentAPI = () => API.get("/api/jpdevelopment");

export const JpDevelopmentByIdAPI = (id) => API.get(`/api/jpdevelopment/${id}`);

export const JpDevelopmentRegisterAPI = (jpDevelopmentName) =>
  API.post("/api/jpdevelopment/register", {
    jpDevelopmentName,
  });

export const JpDevelopmentDeleteAPI = (id) =>
  API.delete(`/api/jpdevelopment/${id}`);

export const JpDevelopmentUpdateAPI = (jpdevelopment) =>
  API.put(`/api/jpdevelopment/${jpdevelopment._id}`, jpdevelopment);

export const JpDevelopmentSearchAPI = (jpDevelopmentName) =>
  API.post("/api/jpdevelopment/search", { jpDevelopmentName });

// JP OUT SOURCE
// ______________________________________________________________________________________

export const listJpOutSourceAPI = () => API.get("/api/jpoutsource");

export const JpOutSourceByIdAPI = (id) => API.get(`/api/jpoutsource/${id}`);

export const JpOutSourceRegisterAPI = (jpoutsource) =>
  API.post("/api/jpoutsource/register", {
    jpoutsource,
  });

export const JpOutSourceDeleteAPI = (id) =>
  API.delete(`/api/jpoutsource/${id}`);

export const JpOutSourceUpdateAPI = (jpoutsource) =>
  API.put(`/api/jpoutsource/${jpoutsource._id}`, jpoutsource);

export const JpOutSourceSearchAPI = (jpOutsourceName) =>
  API.post("/api/jpoutsource/search", { jpOutsourceName });

// SALES STAFF
// ______________________________________________________________________________________

export const listSalesStaffAPI = () => API.get("/api/salesstaff");

export const SalesStaffByIdAPI = (id) => API.get(`/api/salesstaff/${id}`);

export const SalesStaffRegisterAPI = (salesstaff) =>
  API.post("/api/salesstaff/register", {
    salesstaff,
  });

export const SalesStaffDeleteAPI = (id) => API.delete(`/api/salesstaff/${id}`);

export const SalesStaffUpdateAPI = (salesstaff) =>
  API.put(`/api/salesstaff/${salesstaff._id}`, salesstaff);

export const SalesStaffSearchAPI = (salesStaffName) =>
  API.post("/api/salesstaff/search", { salesStaffName });

// SL DEVELOPERS
// ______________________________________________________________________________________

export const listSlDeveloperAPI = () => API.get("/api/sldeveloper");

export const SlDeveloperByIdAPI = (id) => API.get(`/api/sldeveloper/${id}`);

export const SlDeveloperRegisterAPI = (sldeveloper) =>
  API.post("/api/sldeveloper/register", {
    sldeveloper,
  });

export const SlDeveloperDeleteAPI = (id) =>
  API.delete(`/api/sldeveloper/${id}`);

export const SlDeveloperUpdateAPI = (slDevelopment) =>
  API.put(`/api/sldeveloper/${slDevelopment._id}`, slDevelopment);

export const SlDeveloperSearchAPI = (slDeveloperName) =>
  API.post("/api/sldeveloper/search", { slDeveloperName });

// MASTERS
// ______________________________________________________________________________________

export const listMasterAPI = () => API.get("/api/master");
export const listTabAPI = () => API.get("/api/tab");

// STATUS
// ______________________________________________________________________________________

export const listStatusAPI = () => API.get("/api/status");

export const StatusByIdAPI = (id) => API.get(`/api/status/${id}`);

export const StatusRegisterAPI = (status) =>
  API.post("/api/status/register", {
    status,
  });

export const StatusDeleteAPI = (id) => API.delete(`/api/status/${id}`);

export const StatusUpdateAPI = (status) =>
  API.put(`/api/status/${status._id}`, status);

export const StatusSearchAPI = (statusName) =>
  API.post("/api/status/search", { statusName });

// USER LEVEL
// ______________________________________________________________________________________

export const listUserLevelAPI = () => API.get("/api/userlevel");

export const UserLevelByIdAPI = (id) => API.get(`/api/userlevel/${id}`);

export const UserLevelRegisterAPI = (userlevel) =>
  API.post("/api/userlevel/register", {
    userlevel,
  });

export const UserLevelDeleteAPI = (id) => API.delete(`/api/userlevel/${id}`);

export const UserLevelUpdateAPI = (userlevel) =>
  API.put(`/api/userlevel/${userlevel._id}`, userlevel);

export const UserLevelSearchAPI = (UserLevelName) =>
  API.post("/api/userlevel/search", { UserLevelName });

// PROJECT CATEGORY
// ______________________________________________________________________________________

export const listProjectCategoryAPI = () => API.get("/api/projectcategory");

export const ProjectCategoryByIdAPI = (id) =>
  API.get(`/api/projectcategory/${id}`);

export const ProjectCategoryRegisterAPI = (projectcategory) =>
  API.post("/api/projectcategory/register", {
    projectcategory,
  });

export const ProjectCategoryDeleteAPI = (id) =>
  API.delete(`/api/projectcategory/${id}`);

export const ProjectCategoryUpdateAPI = (projectcategory) =>
  API.put(`/api/projectcategory/${projectcategory._id}`, projectcategory);

export const ProjectCategorySearchAPI = (projectcategoryName) =>
  API.post("/api/projectcategory/search", { projectcategoryName });

// OTHER CATEGORY
// ______________________________________________________________________________________

export const listOtherCategoryAPI = () => API.get("/api/othercategory");

export const OtherCategoryByIdAPI = (id) => API.get(`/api/othercategory/${id}`);

export const OtherCategoryRegisterAPI = (othercategory) =>
  API.post("/api/othercategory/register", {
    othercategory,
  });

export const OtherCategoryDeleteAPI = (id) =>
  API.delete(`/api/othercategory/${id}`);

export const OtherCategoryUpdateAPI = (othercategory) =>
  API.put(`/api/othercategory/${othercategory._id}`, othercategory);

export const OtherCategorySearchAPI = (othercategoryName) =>
  API.post("/api/othercategory/search", { othercategoryName });

// PROJECT CATEGORY
// ______________________________________________________________________________________

export const listProjectAPI = () => API.get("/api/project");

export const getAutoNumberProjectAPI = () => API.get("/api/project/autono");

export const ProjectByIdAPI = (id) => API.get(`/api/project/${id}`);

export const ProjectRegisterAPI = (project) =>
  API.post("/api/project/register", { project });

export const ProjectDeleteAPI = (id) => API.delete(`/api/project/${id}`);

export const ProjectUpdateAPI = (project) =>
  API.put(`/api/project/${project._id}`, project);

export const ProjectSearchAPI = (projectName) =>
  API.post("/api/project/search", { projectName });

// JP OUTSOURCE CATEGORY
// ______________________________________________________________________________________

export const listJpOutsourceCategoryAPI = () =>
  API.get("/api/jpOutsourceCategory");

export const JpOutsourceCategoryByIdAPI = (id) =>
  API.get(`/api/jpOutsourceCategory/${id}`);

export const JpOutsourceCategoryRegisterAPI = (jpoutsourcecategoryName) =>
  API.post("/api/jpOutsourceCategory/register", {
    jpoutsourcecategoryName,
  });

export const JpOutsourceCategoryDeleteAPI = (id) =>
  API.delete(`/api/jpOutsourceCategory/${id}`);

export const JpOutsourceCategoryUpdateAPI = (jpOutsourceCategory) =>
  API.put(
    `/api/jpOutsourceCategory/${jpOutsourceCategory._id}`,
    jpOutsourceCategory
  );

export const JpOutsourceCategorySearchAPI = (jpOutsourceCategory) =>
  API.post("/api/jpOutsourceCategory/search", { jpOutsourceCategory });

// PAYMENT CATEGORY
// ______________________________________________________________________________________

export const listPaymentAPI = () => API.get("/api/payment");

export const PaymentByIdAPI = (paymentId) =>
  API.get(`/api/payment/${paymentId}`);

export const PaymentRegisterAPI = (paymentName) =>
  API.post("/api/payment/register", {
    paymentName,
  });

export const PaymentDeleteAPI = (id) => API.delete(`/api/payment/${id}`);

export const PaymentUpdateAPI = (othercategory) =>
  API.put(`/api/payment/${othercategory._id}`, othercategory);

export const PaymentSearchAPI = (othercategoryName) =>
  API.post("/api/payment/search", { othercategoryName });

// JP STAFF
// ______________________________________________________________________________________

export const listJpStaffAPI = () => API.get("/api/jpstaff");

export const JpStaffByIdAPI = (id) => API.get(`/api/jpstaff/${id}`);

export const JpStaffRegisterAPI = (jpstaff) =>
  API.post("/api/jpstaff/register", {
    jpstaff,
  });

export const JpStaffDeleteAPI = (id) => API.delete(`/api/jpstaff/${id}`);

export const JpStaffUpdateAPI = (jpstaff) =>
  API.put(`/api/jpstaff/${jpstaff._id}`, jpstaff);

export const JpStaffSearchAPI = (jpstaffName) =>
  API.post("/api/jpstaff/search", { jpstaffName });

// SL OUT SOUREC CATEGORY
// ______________________________________________________________________________________

export const listSlOutSourceCategoryAPI = () =>
  API.get("/api/sloutsourcecategory");

export const SlOutSourceCategoryByIdsAPI = (id) =>
  API.get(`/api/sloutsourcecategory/${id}`);

export const SlOutSourceCategoryRegisterAPI = (sloutsourcecategory) =>
  API.post("/api/sloutsourcecategory/register", { sloutsourcecategory });

export const SlOutSourceCategoryDeleteAPI = (id) =>
  API.delete(`/api/sloutsourcecategory/${id}`);

export const SlOutSourceCategoryUpdateAPI = (sloutsourcecategory) =>
  API.put(
    `/api/sloutsourcecategory/${sloutsourcecategory._id}`,
    sloutsourcecategory
  );

export const SlOutSourceCategorySearchAPI = (sloutsourcecategoryName) =>
  API.post("/api/jpstaff/search", { sloutsourcecategoryName });

// SL OUT SOUREC COMPANY
// ______________________________________________________________________________________

export const listSlOutSourceCompanyAPI = () =>
  API.get("/api/sloutsourcecompany");

export const SlOutSourceCompanyByIdAPI = (id) =>
  API.get(`/api/sloutsourcecompany/${id}`);

export const SlOutSourceCompanyRegisterAPI = (slOutSourceCompany) =>
  API.post("/api/sloutsourcecompany/register", {
    slOutSourceCompany,
  });

export const SlOutSourceCompanyDeleteAPI = (id) =>
  API.delete(`/api/sloutsourcecompany/${id}`);

export const SlOutSourceCompanyUpdateAPI = (slOutSourceCompany) =>
  API.put(
    `/api/sloutsourcecompany/${slOutSourceCompany._id}`,
    slOutSourceCompany
  );

export const SlOutSourceCompanySearchAPI = (slOutSourceCompanyName) =>
  API.post("/api/sloutsourcecompany/search", { slOutSourceCompanyName });

// LABOUR CATEGORY
// ______________________________________________________________________________________

export const listLabourCategoryAPI = () => API.get("/api/labourcategory");

export const LabourCategoryByIdAPI = (id) =>
  API.get(`/api/labourcategory/${id}`);

export const LabourCategoryRegisterAPI = (labourcategory) =>
  API.post("/api/labourcategory/register", {
    labourcategory,
  });

export const LabourCategoryDeleteAPI = (id) =>
  API.delete(`/api/labourcategory/${id}`);

export const LabourCategoryUpdateAPI = (labourcategory) =>
  API.put(`/api/labourcategory/${labourcategory._id}`, labourcategory);

export const LabourCategorySearchAPI = (labourCategoryName) =>
  API.post("/api/labourcategory/search", { labourCategoryName });

// JPOUTSOURCE COST
// ______________________________________________________________________________________

export const listJpOutsourceCostAPI = () => API.get("/api/jpoutsourcecost");

export const listJpOutsourceCostByProjectAPI = (project) =>
  API.get(`/api/jpoutsourcecost/project/${project}`);

export const JpOutsourceCostByIdAPI = (paymentId) =>
  API.get(`/api/jpoutsourcecost/${paymentId}`);

export const JpOutsourceCostRegisterAPI = (JpOutSourceCost) =>
  API.post("/api/jpoutsourcecost/register", JpOutSourceCost);

export const JpOutsourceCostDeleteAPI = (id) =>
  API.delete(`/api/jpoutsourcecost/${id}`);

export const JpOutsourceCostUpdateAPI = (jpOutSourcecost) =>
  API.put(`/api/jpoutsourcecost/${jpOutSourcecost._id}`, jpOutSourcecost);

export const JpOutsourceCostSearchAPI = (othercategoryName) =>
  API.post("/api/jpoutsourcecost/search", { othercategoryName });

// OTHER COST
// ______________________________________________________________________________________

export const listOtherCostAPI = () => API.get("/api/othercost");
export const listOtherCostByProjectAPI = (project) =>
  API.get(`/api/othercost/project/${project}`);

export const OtherCostByIdAPI = (othercostId) =>
  API.get(`/api/othercost/${othercostId}`);

export const OtherCostRegisterAPI = (othercostscategory) =>
  API.post("/api/othercost/register", {
    othercostscategory,
  });

export const OtherCostDeleteAPI = (id) => API.delete(`/api/payment/${id}`);

export const OtherCostUpdateAPI = (othercost) =>
  API.put(`/api/othercost/${othercost._id}`, othercost);

export const OtherCostSearchAPI = (othercostName) =>
  API.post("/api/othercost/search", { othercostName });

// LABOUR COST
// ______________________________________________________________________________________

export const listLabourCostAPI = () => API.get("/api/labourcost");

export const listLabourCostByProjectAPI = (project) =>
  API.get(`/api/labourcost/project/${project}`);

export const LabourCostByIdAPI = (LabourId) =>
  API.get(`/api/labourcost/${LabourId}`);

export const LabourCostRegisterAPI = (Labour) =>
  API.post("/api/labourcost/register", {
    Labour,
  });

export const LabourCostDeleteAPI = (id) => API.delete(`/api/labourcost/${id}`);

export const LabourCostUpdateAPI = (Labour) =>
  API.put(`/api/labourcost/${Labour._id}`, { Labour });

export const LabourCostSearchAPI = (LabourName) =>
  API.post("/api/labourcost/search", { LabourName });

// SL OUTSOURCE  COST
// ______________________________________________________________________________________

export const listSlOutsourceCostAPI = () => API.get("/api/sloutsourcecost");

export const listSlOutsourceCostByProjectAPI = (project) =>
  API.get(`/api/sloutsourcecost/project/${project}`);

export const SlOutsourceCostByIdAPI = (sloutsourcecost) =>
  API.get(`/api/sloutsourcecost/${sloutsourcecost}`);

export const SlOutsourceCostRegisterAPI = (sloutsourcecategory) =>
  API.post("/api/sloutsourcecost/register", {
    sloutsourcecategory,
  });

export const SlOutsourceCostDeleteAPI = (id) =>
  API.delete(`/api/sloutsourcecost/${id}`);

export const SlOutsourceCostUpdateAPI = (sloutsourcecost) =>
  API.put(`/api/sloutsourcecost/${sloutsourcecost._id}`, sloutsourcecost);

export const SlOutsourceCostSearchAPI = (sloutsourcecostName) =>
  API.post("/api/sloutsourcecost/search", { sloutsourcecostName });
