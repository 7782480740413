import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStatusById, updateStatus } from "../../redux/actions/statusAction";
import { STATUS_UPDATE_RESET } from "../../redux/constants/statusConstants";
import { SetRoute } from "../../redux/actions/routeActions";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function StatusEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusId = useParams();

  const [id, setId] = useState("");
  const [statusName, setStatusName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.statusById);
  const { loading, error, statusById } = Details;

  const update = useSelector((state) => state.statesById);
  const {
    loading: statusesUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update || {};

  useEffect(() => {
    dispatch(SetRoute("Status Edit"));
    if (successUpdate) {
      dispatch({ type: STATUS_UPDATE_RESET });
      navigate("/status");
    } else {
      if (!statusById.statusName || statusById._id !== statusId.id) {
        dispatch(getStatusById(statusId.id));
      } else {
        setId(statusById._id);
        setStatusName(statusById.statusName);
      }
    }
  }, [dispatch, statusById, successUpdate, statusId, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (statusName === "") {
      setMessage("statusName is empty");
    } else {
      dispatch(
        updateStatus(
          {
            _id: id,
            StatusName: statusName,
          },
          navigate
        )
      );
    }
  };
  return (
    <>
      <Container maxWidth="sm">
        {statusesUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="StatusName"
                name="statusName"
                value={statusName}
                onChange={(e) => setStatusName(e.target.value)}
              />
              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default StatusEditScreen;
