import Header from "./Components/Header";
import Container from "@mui/material/Container";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "./app.css";

import UsersScreen from "./Screens/User/UsersScreen";
import UserAddScreen from "./Screens/User/UserAddScreen";
import UserEditScreen from "./Screens/User/UserEditScreen";

import StatusScreen from "./Screens/Status/StatusScreen";
import StatusAddScreen from "./Screens/Status/StatusAddScreen";
import StatusEditScreen from "./Screens/Status/StatusEditScreen";

import SlDeveloperScreen from "./Screens/SlDevelopers/SlDeveloperScreen";
import SlDeveloperAddScreen from "./Screens/SlDevelopers/SlDeveloperAddScreen";
import SlDeveloperEditScreen from "./Screens/SlDevelopers/SlDeveloperEditScreen";

import SalesStaffScreen from "./Screens/SalesStaff/SalesStaffScreen";
import SalesStaffAddScreen from "./Screens/SalesStaff/SalesStaffAddScreen";
import SalesStaffEditScreen from "./Screens/SalesStaff/SalesStaffEditScreen";

import ProjectCategoryScreen from "./Screens/ProjectCategory/ProjectCategoryScreen";
import ProjectCategoryAddScreen from "./Screens/ProjectCategory/ProjectCategoryAddScreen";
import ProjectCateogryEditScreen from "./Screens/ProjectCategory/ProjectCategoryEditScreen";

import OtherCategoryScreen from "./Screens/OtherCategory/OtherCategoryScreen";
import OtherCategoryAddScreen from "./Screens/OtherCategory/OtherCategoryAddScreen";
import OtherCategoryEditScreen from "./Screens/OtherCategory/OtherCategoryEditScreen";

import JpOutsourceScreen from "./Screens/JpOutsource/JpOutsourceScreen";
import JpOutsourceAddScreen from "./Screens/JpOutsource/JpOutsourceAddScreen";
import JpOutsourceEditScreen from "./Screens/JpOutsource/JpOutsourceEditScreen";

import JpDevelopmentScreen from "./Screens/JpDevelopment/JpDevelopmentScreen";
import JpDevelopmentAddScreen from "./Screens/JpDevelopment/JpDevelopmentAddScreen";
import JpDevelopmentEditScreen from "./Screens/JpDevelopment/JpDevelopmentEditScreen";

import CostCategoryScreen from "./Screens/CostCategory/CostCategoryScreen";
import CostCategoryAddScreen from "./Screens/CostCategory/CostCategoryAddScreen";
import CostCategoryEditScreen from "./Screens/CostCategory/CostCategoryEditScreen";

import JpOutsourceCategoryScreen from "./Screens/JpOutSourceCategory/JpOutsourceCategoryScreen";
import JpOutsourceCategoryAddScreen from "./Screens/JpOutSourceCategory/JpOutsourceCategoryAddScreen";
import JpOutsourceCategoryEditScreen from "./Screens/JpOutSourceCategory/JpOutsourceCategoryEditScreen";

import JpStaffScreen from "./Screens/JpStaff/JpStaffScreen";
import JpStaffAddScreen from "./Screens/JpStaff/JpStaffAddScreen";
import JpStaffEditScreen from "./Screens/JpStaff/JpStaffEditScreen";

import SlOutSourceCategoryScreen from "./Screens/SlOutSourceCategory/SlOutSourceCategoryScreen";
import SlOutSourceCategoryAddScreen from "./Screens/SlOutSourceCategory/SlOutSourceCategoryAddScreen";
import SlOutSourceCategoryEditScreen from "./Screens/SlOutSourceCategory/SlOutSourceCategoryEditScreen";

import SlOutSourceCompanyScreen from "./Screens/SlOutSourceCompany/SlOutSourceCompanyScreen";
import SlOutSourceCompanyAddScreen from "./Screens/SlOutSourceCompany/SlOutSourceCompanyAddScreen";
import SlOutSourceCompanyEditScreen from "./Screens/SlOutSourceCompany/SlOutSourceCompanyEditScreen";

import PaymentScreen from "./Screens/Payment/PaymentScreen";
import PaymentAddScreen from "./Screens/Payment/PaymentAddScreen";
import PaymentEditScreen from "./Screens/Payment/PaymentEditScreen";

import LabourCategoryScreen from "./Screens/LabourCategory/LabourCategoryScreen";
import LabourCategoryAddScreen from "./Screens/LabourCategory/LabourCategoryAddScreen";
import LabourCategoryEditScreen from "./Screens/LabourCategory/LabourCategoryEditScreen";

import UserLevelScreen from "./Screens/UserLevel/UserLevelScreen";
import UserLevelAddScreen from "./Screens/UserLevel/UserLevelAddScreen";
import UserLevelEditScreen from "./Screens/UserLevel/UserLevelEditScreen";

import LoginScreen from "./Screens/LoginScreen";
import HomeScreen from "./Screens/HomeScreen";
import CostsScreen from "./Screens/CostsScreen";
import Project from "./Screens/Project";
import Setting from "./Screens/Setting";

import Accordion from "./Components/Accordian";

const App = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <div className="App">
      {userInfo ? (
        <>
          <Header />
          <Container>
            <Routes>            
              <Route exact path="/addproject" element={<Accordion />} />
              <Route exact path="/home" element={<HomeScreen />} />
              <Route exact path="/Project" element={<Project />} />
              <Route exact path="/Settings" element={<Setting />} />
              <Route exact path="/costs/:id" element={<CostsScreen />} />
              {/* List Screens */}
              <Route
                exact
                path="/jpoutsourcescategory"
                element={<JpOutsourceCategoryScreen />}
              />
              <Route exact path="/payments" element={<PaymentScreen />} />
              <Route exact path="/user" element={<UsersScreen />} />
              <Route exact path="/status" element={<StatusScreen />} />
              <Route
                exact
                path="/sldeveloper"
                element={<SlDeveloperScreen />}
              />
              <Route exact path="/salesstaff" element={<SalesStaffScreen />} />
              <Route
                exact
                path="/projectcategory"
                element={<ProjectCategoryScreen />}
              />
              <Route
                exact
                path="/othercategory"
                element={<OtherCategoryScreen />}
              />
              <Route
                exact
                path="/jpoutsourse"
                element={<JpOutsourceScreen />}
              />
              <Route
                exact
                path="/jpdevelopments"
                element={<JpDevelopmentScreen />}
              />
              <Route
                exact
                path="/costcategory"
                element={<CostCategoryScreen />}
              />
              <Route exact path="/jpstaff" element={<JpStaffScreen />} />
              <Route
                exact
                path="/sloutsourcecategory"
                element={<SlOutSourceCategoryScreen />}
              />
              <Route
                exact
                path="/sloutsourcecompany"
                element={<SlOutSourceCompanyScreen />}
              />

              <Route
                exact
                path="/labourcategory"
                element={<LabourCategoryScreen />}
              />
              <Route exact path="/userlevel" element={<UserLevelScreen />} />

              {/* Edit Screens */}

              <Route
                exact
                path="/projectCategory/:id/edit"
                element={<ProjectCateogryEditScreen />}
              />
              <Route
                exact
                path="/otherCategory/:id/edit"
                element={<OtherCategoryEditScreen />}
              />
              <Route
                exact
                path="/jpoutsourse/:id/edit"
                element={<JpOutsourceEditScreen />}
              />
              <Route
                exact
                path="/jpdevelopments/:id/edit"
                element={<JpDevelopmentEditScreen />}
              />
              <Route
                exact
                path="/costCategory/:id/edit"
                element={<CostCategoryEditScreen />}
              />
              <Route
                exact
                path="/sldeveloper/:id/edit"
                element={<SlDeveloperEditScreen />}
              />
              <Route exact path="/user/:id/edit" element={<UserEditScreen />} />
              <Route
                exact
                path="/salesstaff/:id/edit"
                element={<SalesStaffEditScreen />}
              />
              <Route
                exact
                path="/status/:id/edit"
                element={<StatusEditScreen />}
              />
              <Route
                exact
                path="/jpoutsourcescategory/:id/edit"
                element={<JpOutsourceCategoryEditScreen />}
              />
              <Route
                exact
                path="/payments/:id/edit"
                element={<PaymentEditScreen />}
              />

              <Route
                exact
                path="/jpstaff/:id/edit"
                element={<JpStaffEditScreen />}
              />
              <Route
                exact
                path="/sloutsourcecategory/:id/edit"
                element={<SlOutSourceCategoryEditScreen />}
              />
              <Route
                exact
                path="/sloutsourcecompany/:id/edit"
                element={<SlOutSourceCompanyEditScreen />}
              />
              <Route
                exact
                path="/labourcategory/:id/edit"
                element={<LabourCategoryEditScreen />}
              />
              <Route
                exact
                path="/userlevel/:id/edit"
                element={<UserLevelEditScreen />}
              />

              {/* Add Screens */}

              <Route
                exact
                path="/costcategory/add"
                element={<CostCategoryAddScreen />}
              />
              <Route
                exact
                path="/projectcategory/add"
                element={<ProjectCategoryAddScreen />}
              />
              <Route
                exact
                path="/othercategory/add"
                element={<OtherCategoryAddScreen />}
              />
              <Route
                exact
                path="/jpoutsourse/add"
                element={<JpOutsourceAddScreen />}
              />
              <Route
                exact
                path="/jpdevelopment/add"
                element={<JpDevelopmentAddScreen />}
              />
              <Route
                exact
                path="/sldeveloper/add"
                element={<SlDeveloperAddScreen />}
              />
              <Route exact path="/user/add" element={<UserAddScreen />} />
              <Route
                exact
                path="/salesstaff/add"
                element={<SalesStaffAddScreen />}
              />
              <Route
                exact
                path="/jpoutsourcescategory/add"
                element={<JpOutsourceCategoryAddScreen />}
              />
              <Route
                exact
                path="/payments/add"
                element={<PaymentAddScreen />}
              />
              <Route exact path="/status/add" element={<StatusAddScreen />} />
              <Route exact path="/jpstaff/add" element={<JpStaffAddScreen />} />
              <Route
                exact
                path="/sloutsourcecategory/add"
                element={<SlOutSourceCategoryAddScreen />}
              />
              <Route
                exact
                path="/sloutsourcecompany/add"
                element={<SlOutSourceCompanyAddScreen />}
              />
              <Route
                exact
                path="/labourcategory/add"
                element={<LabourCategoryAddScreen />}
              />
              <Route
                exact
                path="/userlevel/add"
                element={<UserLevelAddScreen />}
              />

              <Route exact path="/" element={<LoginScreen />} />
            </Routes>
          </Container>
        </>
      ) : (
        <Container>
          <Routes>
            <Route exact path="/" element={<LoginScreen />} />
          </Routes>
        </Container>
      )}
    </div>
  );
};

export default App;
