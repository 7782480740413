import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPaymentById,
  updatePayment,
} from "../../redux/actions/paymentActions";
import { PAYMENT_UPDATE_RESET } from "../../redux/constants/paymentConstants";
import { useParams } from "react-router-dom";
import { SetRoute } from "../../redux/actions/routeActions";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";

function PaymentEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentId = useParams();

  const [id, setId] = useState("");
  const [paymentName, setPaymentName] = useState("");
  const [message, setMessage] = useState(null);

  const Details = useSelector((state) => state.paymentById);
  const { loading, error, paymentById } = Details;

  const update = useSelector((state) => state.paymentUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = update || {};

  useEffect(() => {
    dispatch(SetRoute("Payment Edit"));
    if (successUpdate) {
      dispatch({ type: PAYMENT_UPDATE_RESET });
      navigate("/payments");
    } else {
      if (!paymentById.paymentName || paymentById._id !== paymentId.id) {
        dispatch(getPaymentById(paymentId.id));
      } else {
        setId(paymentById._id);
        setPaymentName(paymentById.paymentName);
      }
    }
  }, [dispatch, successUpdate, paymentById, paymentId, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (paymentName === "") {
      setMessage("PaymentName is empty");
    } else {
      dispatch(
        updatePayment(
          {
            _id: id,
            PaymentName: paymentName,
          },
          navigate
        )
      );
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {message && <Message variant="danger">{message}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <form
            sx={{
              p: 4,
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 5 },
            }}
            onSubmit={submitHandler}
          >
            <FormGroup>
              <TextField
                margin="normal"
                size="small"
                label="Payment Type"
                name="paymentName"
                value={paymentName}
                onChange={(e) => setPaymentName(e.target.value)}
              />

              <Button type="submit" size="small" variant="outlined">
                Submit
              </Button>
            </FormGroup>
          </form>
        )}
      </Container>
    </>
  );
}

export default PaymentEditScreen;
