import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { RouteReducer } from "./reducer/routereducers";
import { mastersListReducer,tabListReducer } from "./reducer/masterReducer";
import {
  userLoginReducer,
  userListReducer,
  userRegisterReducer,
  userDetailsReducer,
  userDeleteReducer,
  userUpdateProfileReducer,
} from "./reducer/userReducers";
import {
  categorysListReducer,
  categoryByIdReducer,
  categoryRegisterReducer,
  categoryDeleteReducer,
  categoryUpdateReducer,
} from "./reducer/categoryReducers";
import {
  costCategorysListReducer,
  costCategoryByIdReducer,
  costCategoryRegisterReducer,
  costCategoryDeleteReducer,
  costCategoryUpdateReducer,
} from "./reducer/costCategoryReducers";
import {
  jpDevelopmentListReducer,
  jpDevelopmentByIdReducer,
  jpDevelopmentRegisterReducer,
  jpDevelopmentDeleteReducer,
  jpDevelopmentUpdateReducer,
} from "./reducer/jpDevelopmentReducers";
import {
  jpOutSourceListReducer,
  jpOutSourceByIdReducer,
  jpOutSourceRegisterReducer,
  jpOutSourceDeleteReducer,
  jpOutSourceUpdateReducer,
} from "./reducer/jpOutsourceReducers";
import {
  salesStaffListReducer,
  salesStaffByIdReducer,
  salesStaffRegisterReducer,
  salesStaffDeleteReducer,
  salesStaffUpdateReducer,
} from "./reducer/salesStaffReducers";
import {
  slDeveloperListReducer,
  slDeveloperByIdReducer,
  slDeveloperRegisterReducer,
  slDeveloperDeleteReducer,
  slDeveloperUpdateReducer,
} from "./reducer/slDeveloperReducers";
import {
  statusListReducer,
  statusByIdReducer,
  statusRegisterReducer,
  statusDeleteReducer,
  statusUpdateReducer,
} from "./reducer/statusReducers";
import {
  userlevelListReducer,
  userlevelByIdReducer,
  userlevelRegisterReducer,
  userlevelDeleteReducer,
  userlevelUpdateReducer,
} from "./reducer/userLevelReducers";
import {
  userRoleListReducer,
  userRoleByIdReducer,
  userRoleRegisterReducer,
  userRoleDeleteReducer,
  userRoleUpdateReducer,
} from "./reducer/userRoleReducers";
import {
  projectCategorysListReducer,
  projectCategoryByIdReducer,
  projectCategoryRegisterReducer,
  projectCategoryDeleteReducer,
  projectCategoryUpdateReducer,
} from "./reducer/projectCategoryReducers";
import {
  otherCategorysListReducer,
  otherCategoryByIdReducer,
  otherCategoryRegisterReducer,
  otherCategoryDeleteReducer,
  otherCategoryUpdateReducer,
} from "./reducer/otherCategoryReducer";
import {
  projectsListReducer,
  projectByIdReducer,
  projectRegisterReducer,
  projectDeleteReducer,
  projectUpdateReducer,
} from "./reducer/projectReducers";
import {
  jpOutSourceCategoryListReducer,
  jpOutSourceCategoryByIdReducer,
  jpOutSourceCategoryRegisterReducer,
  jpOutSourceCategoryDeleteReducer,
  jpOutSourceCategoryUpdateReducer,
} from "./reducer/jpOutsourceCategoryReducers";
import {
  paymentListReducer,
  paymentByIdReducer,
  paymentRegisterReducer,
  paymentDeleteReducer,
  paymentUpdateReducer,
} from "./reducer/paymentReducers";
import {
  jpOutSourceCostListReducer,
  jpOutSourceCostByIdReducer,
  jpOutSourceCostRegisterReducer,
  jpOutSourceCostDeleteReducer,
  jpOutSourceCostUpdateReducer,
} from "./reducer/jpOutsourceCostReducers";
import {
  otherCostListReducer,
  otherCostByIdReducer,
  otherCostRegisterReducer,
  otherCostDeleteReducer,
  otherCostUpdateReducer,
} from "./reducer/otherCostReducer";
import {
  jpStaffListReducer,
  jpStaffByIdReducer,
  jpStaffRegisterReducer,
  jpStaffDeleteReducer,
  jpStaffUpdateReducer,
} from "./reducer/jpStaffReducers";
import {
  slOutSourceCategoryListReducer,
  slOutSourceCategoryByIdReducer,
  slOutSourceCategoryRegisterReducer,
  slOutSourceCategoryDeleteReducer,
  slOutSourceCategoryUpdateReducer,
} from "./reducer/slOutSourceCategoryReducers";
import {
  slOutSourceCompanyListReducer,
  slOutSourceCompanyByIdReducer,
  slOutSourceCompanyRegisterReducer,
  slOutSourceCompanyDeleteReducer,
  slOutSourceCompanyUpdateReducer,
} from "./reducer/slOutSourceCompanyReducers";
import {
  labourCategorysListReducer,
  labourCategoryByIdReducer,
  labourCategoryRegisterReducer,
  labourCategoryDeleteReducer,
  labourCategoryUpdateReducer,
} from "./reducer/labourCategoryReducers";
import {
  labourCostListReducer,
  labourCostByIdReducer,
  labourCostRegisterReducer,
  labourCostDeleteReducer,
  labourCostUpdateReducer,
} from "./reducer/labourCostReducers";
import {
  sloutsourceCostListReducer,
  sloutsourcecostByIdReducer,
  sloutsourcecostRegisterReducer,
  sloutsourcecostDeleteReducer,
  sloutsourcecostUpdateReducer,
} from "./reducer/slOutsourceCostReducers";

const reducer = combineReducers({
  // Router
  route: RouteReducer,

  ///masters
  masterlist: mastersListReducer,
  tablist: tabListReducer,

  //   users
  userLogin: userLoginReducer,
  userList: userListReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userDelete: userDeleteReducer,
  userUpdateProfile: userUpdateProfileReducer,

  //   userLevel
  userLevelList: userlevelListReducer,
  userLevelById: userlevelByIdReducer,
  userLevelRegister: userlevelRegisterReducer,
  userLevelDelete: userlevelDeleteReducer,
  userLevelUpdate: userlevelUpdateReducer,

  //user roles
  userRoleList: userRoleListReducer,
  userRoleById: userRoleByIdReducer,
  userRoleRegister: userRoleRegisterReducer,
  userRoleDelete: userRoleDeleteReducer,
  userRoleUpdate: userRoleUpdateReducer,

  //   categorys
  categorysList: categorysListReducer,
  categoryById: categoryByIdReducer,
  categoryRegister: categoryRegisterReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,

  //   costcategorys
  costCategorysList: costCategorysListReducer,
  costCategoryById: costCategoryByIdReducer,
  costCategoryRegister: costCategoryRegisterReducer,
  costCategoryDelete: costCategoryDeleteReducer,
  costCategoryUpdate: costCategoryUpdateReducer,

  //   jpDevelopment
  jpDevelopmentList: jpDevelopmentListReducer,
  jpDevelopmentById: jpDevelopmentByIdReducer,
  jpDevelopmentRegister: jpDevelopmentRegisterReducer,
  jpDevelopmentDelete: jpDevelopmentDeleteReducer,
  jpDevelopmentUpdate: jpDevelopmentUpdateReducer,

  //   jpOutSources
  jpOutSourceList: jpOutSourceListReducer,
  jpOutSourceById: jpOutSourceByIdReducer,
  jpOutSourcRegister: jpOutSourceRegisterReducer,
  jpOutSourceDelete: jpOutSourceDeleteReducer,
  jpOutSourceUpdate: jpOutSourceUpdateReducer,

  //   salesStaff
  salesStaffList: salesStaffListReducer,
  salesStaffById: salesStaffByIdReducer,
  salesStaffRegister: salesStaffRegisterReducer,
  salesStaffDelete: salesStaffDeleteReducer,
  salesStaffUpdate: salesStaffUpdateReducer,

  //   slDeveloper
  slDeveloperList: slDeveloperListReducer,
  slDeveloperById: slDeveloperByIdReducer,
  slDeveloperRegister: slDeveloperRegisterReducer,
  slDeveloperDelete: slDeveloperDeleteReducer,
  slDeveloperUpdate: slDeveloperUpdateReducer,

  //   status
  statusList: statusListReducer,
  statusById: statusByIdReducer,
  statusRegister: statusRegisterReducer,
  statusDelete: statusDeleteReducer,
  statusUpdate: statusUpdateReducer,

  //   projectcategorys
  projectCategorysList: projectCategorysListReducer,
  projectCategoryById: projectCategoryByIdReducer,
  projectCategoryRegister: projectCategoryRegisterReducer,
  projectCategoryDelete: projectCategoryDeleteReducer,
  projectCategoryUpdate: projectCategoryUpdateReducer,

  //   othercategorys
  otherCategorysList: otherCategorysListReducer,
  otherCategoryById: otherCategoryByIdReducer,
  otherCategoryRegister: otherCategoryRegisterReducer,
  otherCategoryDelete: otherCategoryDeleteReducer,
  otherCategoryUpdate: otherCategoryUpdateReducer,

  //   projects
  projectsList: projectsListReducer,
  projectById: projectByIdReducer,
  projectRegister: projectRegisterReducer,
  projectDelete: projectDeleteReducer,
  projectUpdate: projectUpdateReducer,

  //   jpOutsourceCategory
  jpOutSourceCategoryList: jpOutSourceCategoryListReducer,
  jpOutSourceCategoryById: jpOutSourceCategoryByIdReducer,
  jpOutSourceCategoryRegister: jpOutSourceCategoryRegisterReducer,
  jpOutSourceCategoryDelete: jpOutSourceCategoryDeleteReducer,
  jpOutSourceCategoryUpdate: jpOutSourceCategoryUpdateReducer,

  //   payment
  paymentList: paymentListReducer,
  paymentById: paymentByIdReducer,
  paymentRegister: paymentRegisterReducer,
  paymentDelete: paymentDeleteReducer,
  paymentUpdate: paymentUpdateReducer,

  //   jpoutsourceCost
  jpOutSourceCostList: jpOutSourceCostListReducer,
  jpOutSourceCostById: jpOutSourceCostByIdReducer,
  jpOutSourceCostRegister: jpOutSourceCostRegisterReducer,
  jpOutSourceCostDelete: jpOutSourceCostDeleteReducer,
  jpOutSourceCostUpdate: jpOutSourceCostUpdateReducer,

  //   other Costs
  otherCostList: otherCostListReducer,
  otherCostById: otherCostByIdReducer,
  otherCostRegister: otherCostRegisterReducer,
  otherCostDelete: otherCostDeleteReducer,
  otherCostUpdate: otherCostUpdateReducer,

  //  Jp Staff
  jpStaffList: jpStaffListReducer,
  jpStaffById: jpStaffByIdReducer,
  jpStaffRegister: jpStaffRegisterReducer,
  jpStaffDelete: jpStaffDeleteReducer,
  jpStaffUpdate: jpStaffUpdateReducer,

  //  sl OutSource Category
  slOutSourceCategoryList: slOutSourceCategoryListReducer,
  slOutSourceCategoryById: slOutSourceCategoryByIdReducer,
  slOutSourceCategoryRegister: slOutSourceCategoryRegisterReducer,
  slOutSourceCategoryDelete: slOutSourceCategoryDeleteReducer,
  slOutSourceCategoryUpdate: slOutSourceCategoryUpdateReducer,

  //  sl OutSource Category
  slOutSourceCompanyList: slOutSourceCompanyListReducer,
  slOutSourceCompanyById: slOutSourceCompanyByIdReducer,
  slOutSourceCompanyRegister: slOutSourceCompanyRegisterReducer,
  slOutSourceCompanyDelete: slOutSourceCompanyDeleteReducer,
  slOutSourceCompanyUpdate: slOutSourceCompanyUpdateReducer,

  //   labour categorys
  labourCategorysList: labourCategorysListReducer,
  labourCategoryById: labourCategoryByIdReducer,
  labourCategoryRegister: labourCategoryRegisterReducer,
  labourCategoryDelete: labourCategoryDeleteReducer,
  labourCategoryUpdate: labourCategoryUpdateReducer,

  //   labour Cost
  labourCostList: labourCostListReducer,
  labourCostById: labourCostByIdReducer,
  labourCostRegister: labourCostRegisterReducer,
  labourCostDelete: labourCostDeleteReducer,
  labourCostUpdate: labourCostUpdateReducer,

  //   sl outsource cost
  sloutsourceCostList: sloutsourceCostListReducer,
  sloutsourceCostById: sloutsourcecostByIdReducer,
  sloutsourceCostRegister: sloutsourcecostRegisterReducer,
  sloutsourceCostDelete: sloutsourcecostDeleteReducer,
  sloutsourceCostUpdate: sloutsourcecostUpdateReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const routeFromStorage = localStorage.getItem("routeInfo")
  ? JSON.parse(localStorage.getItem("routeInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  route: { routeInfo: routeFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
